import React, { Component } from 'react';
import { Formik } from 'formik';
import InputFormItem from 'components/FormItems/items/InputFormItem';
import LegalHoldFields from '../LegalHoldFields.js';
import formActions from 'actions/LegalHold/LegalHoldFormActions.js';
import { connect } from 'react-redux';
import * as Yup from 'yup';

class ExternalCustodiansForm extends Component {
  iniValues() {
    const record = {
      emailAddress: '',
      displayName: '',
      companyName: ''
    };
    return record;
  }

  handleSubmit = (values, actions) => {
    const { dispatch } = this.props;
    const currentValue = values;
    const found = this.props?.selectedExternalCustodians?.find(
      (item) => item.emailAddress === currentValue.emailAddress
    );
    if (!found) {
      let externalArray = [...this.props.selectedExternalCustodians, currentValue];
      dispatch(formActions.doSetSelectedExternalCustodians(externalArray)).then(() => {
        const externalIndex = this.props?.selectedExternalCustodians?.length - 1;
        let formattedCustodian = {
          custodianId: externalIndex,
          displayName: currentValue.displayName,
          companyName: currentValue.companyName,
          smtpAddress: currentValue.emailAddress,
          isExternal: true
        };
        let currentList = [...this.props.selectedCustodiansList, formattedCustodian];
        dispatch(formActions.doSetSelectedCustodians(currentList));
      });
    }
    actions.resetForm();
  };

  FormValidationSchema = Yup.object().shape({
    emailAddress: Yup.string().trim().required('Email Address* is a required field')
  });

  render() {
    return (
      <div>
        <Formik
          onSubmit={this.handleSubmit}
          initialValues={this.iniValues()}
          validationSchema={this.FormValidationSchema}
          render={(form) => {
            return (
              <form onSubmit={form.handleSubmit}>
                <div>
                  <InputFormItem name={'emailAddress'} schema={LegalHoldFields} autoFocus />
                </div>
                <div>
                  <InputFormItem name={'displayName'} schema={LegalHoldFields} autoFocus />
                </div>
                <div>
                  <InputFormItem name={'companyName'} schema={LegalHoldFields} autoFocus />
                </div>
                <div className='d-flex justify-content-center align-items-center mt-4'>
                  <button
                    className='btn me-3 fw-semi-bold w-200'
                    type='button'
                    onClick={form.handleSubmit}
                    style={{ height: '37px' }}
                  >
                    Add
                  </button>
                </div>
              </form>
            );
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(store) {
  return {
    selectedCustodiansList: store.LegalHold.form.selectedCustodiansList,
    selectedExternalCustodians: store.LegalHold.form.selectedExternalCustodians
  };
}
export default connect(mapStateToProps)(ExternalCustodiansForm);
