const initialData = {
  rows: [],
  loading: false,
  count: 0,
  showTableView: false,
  isDiscarded: false,
  nodesList: [],
};

export default (state = initialData, { type, payload }) => {
  if (type === "SEARCH_LIST_FETCH_STARTED") {
    return {
      ...state,
      loading: true,
    };
  }

  if (type === "SEARCH_LIST_FETCH_SUCCESS") {
    return {
      ...state,
      loading: false,
      rows: payload.rows,
      count: payload.count,
    };
  }

  if (type === "SEARCH_LIST_FETCH_ERROR") {
    return {
      ...state,
      loading: false,
      rows: [],
    };
  }

  if (type === "SEARCH_LIST_OPEN_CONFIRM") {
    return {
      ...state,
      loading: false,
      modalOpen: true,
    };
  }

  if (type === "SEARCH_LIST_CLOSE_CONFIRM") {
    return {
      ...state,
      loading: false,
      modalOpen: false,
      isDiscarded: false,
    };
  }
  if (type === "SEARCH_LIST_SHOW_TABLE_CONFIRM") {
    return {
      ...state,
      showTableView: true,
    };
  }
  if (type === "SEARCH_LIST_HIDE_TABLE_CONFIRM") {
    return {
      ...state,
      showTableView: false,
    };
  }
  if (type === "SEARCH_DISCARD_CONFIRM") {
    return {
      ...state,
      isDiscarded: true,
    };
  }
  if (type === "SEARCH_DISCARD_TOGGLE") {
    return {
      ...state,
      isDiscarded: false,
    };
  }
  if (type === "TREE_NODES_FETCH_STARTED") {
    return {
      ...state,
      loading: true,
    };
  }

  if (type === "TREE_NODES_FETCH_SUCCESS") {
    return {
      ...state,
      loading: false,
      nodesList: payload,
    };
  }

  if (type === "TREE_NODES_FETCH_ERROR") {
    return {
      ...state,
      loading: false,
      nodesList: [],
    };
  }
  return state;
};
