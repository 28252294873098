import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "components/CRUD/AuditLogs/Audit.module.scss";
import * as dataFormat from "components/CRUD/LegalHold/list/LegalHoldDataFormatters";
import actions from "actions/AuditLogs/AuditLogsListActions";

class ExpandRowComponent extends Component {
  state = {
    auditDetails: {},
    hasAdditionalInfo: false,
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(actions.doFetchAuditEntry(this.props?.auditEntryId))
      .then(() => {
        this.setState({
          auditDetails: this.props?.auditEntryDetails,
          additionalInfo: JSON.stringify(
            this.props?.auditEntryDetails?.additionalInfoJson
          ),
        });
      })
      .then(() => {
        const isEmpty = this.state.additionalInfo == "{}";
        if (isEmpty) {
          this.setState({
            hasAdditionalInfo: false,
          });
        } else {
          this.setState({
            hasAdditionalInfo: true,
          });
        }
      });
  }
  render() {
    return (
      <>
        <div className={styles.contentContainer}>
          <div className={styles.recordDetails}>
            <div>
              {this.state.auditDetails?.legalHold && (
                <>
                  <div>
                    <span className={styles.details}> Legal Hold Name:</span>
                    <span>
                      {this.state.auditDetails?.legalHold?.legalHoldName}
                    </span>
                  </div>
                  <div>
                    <span className={styles.details}>
                      Legal Hold Description:
                    </span>
                    <span>
                      {this.state.auditDetails?.legalHold?.legalHoldDescription}
                    </span>
                  </div>
                  <div>
                    <span className={styles.details}> From: </span>
                    <span>
                      {dataFormat.dateTimeFormatter(
                        this.state.auditDetails?.legalHold?.dateFrom
                      )}
                    </span>
                  </div>
                  <div>
                    <span className={styles.details}> To: </span>
                    <span>
                      {dataFormat.dateTimeFormatter(
                        this.state.auditDetails?.legalHold?.dateTo
                      )}
                    </span>
                  </div>
                </>
              )}
            </div>
            <div>
              {this.state.auditDetails?.contentSearch && (
                <>
                  <div>
                    <span className={styles.details}>Content Search Name:</span>
                    <span>{this.state.auditDetails?.contentSearch?.name} </span>
                  </div>
                  <div>
                    <span className={styles.details}> Query:</span>
                    <span>
                      {
                        this.state.auditDetails?.contentSearch?.queryString
                          ?.query
                      }
                    </span>
                  </div>
                  <div>
                    <span className={styles.details}> Created:</span>
                    <span>
                      {dataFormat.dateTimeFormatter(
                        this.state.auditDetails?.contentSearch?.creationDate
                      )}
                    </span>
                  </div>
                  <div>
                    <span className={styles.details}> Search in:</span>
                    {this.state.auditDetails?.contentSearch
                      ?.searchInSubject && (
                      <span className={styles.bodyText}>Subject</span>
                    )}
                    {this.state.auditDetails?.contentSearch?.searchInBody && (
                      <span className={styles.bodyText}>Body</span>
                    )}
                    {this.state.auditDetails?.contentSearch
                      ?.searchInAttachment && (
                      <span className={styles.bodyText}>Attachment</span>
                    )}
                  </div>
                  <div>
                    <span className={styles.details}> Fuzziness:</span>
                    <span>
                      {this.state.auditDetails?.contentSearch?.fuzzynessType}
                    </span>
                  </div>
                </>
              )}
            </div>
            <div>
              {this.state.auditDetails?.exportJob && (
                <>
                  <div>
                    <span className={styles.details}> Export Name:</span>
                    <span>{this.state.auditDetails?.exportJob?.name}</span>
                  </div>
                  <div>
                    <span className={styles.details}> Created:</span>
                    <span>
                      {dataFormat.dateTimeFormatter(
                        this.state.auditDetails?.exportJob?.created
                      )}
                    </span>
                  </div>
                  {this.state.auditDetails?.exportJob?.legalHolds && (
                    <div>
                      <span className={styles.details}>
                        Exported Legal Holds:
                      </span>
                      {Object.entries(
                        this.state.auditDetails?.exportJob?.legalHolds
                      ).map((item, index) => {
                        const dataLength = Object.entries(
                          this.state.auditDetails?.exportJob?.legalHolds
                        )?.length;
                        return (
                          <>
                            <span key={item[0]} className={styles.bodyText}>
                              {item[1]}
                            </span>
                            <span>{index < dataLength - 1 ? "," : ""}</span>
                          </>
                        );
                      })}
                    </div>
                  )}
                </>
              )}
              {this.state.auditDetails?.exportJob?.contentSearches && (
                <div>
                  <span className={styles.details}>
                    Exported Content Searches:
                  </span>
                  {Object.entries(
                    this.state.auditDetails?.exportJob?.contentSearches
                  ).map((item, index) => {
                    const dataLength = Object.entries(
                      this.state.auditDetails?.exportJob?.contentSearches
                    )?.length;
                    return (
                      <>
                        <span key={item[0]} className={styles.bodyText}>
                          {item[1]}
                        </span>
                        <span>{index < dataLength - 1 ? "," : ""}</span>
                      </>
                    );
                  })}
                </div>
              )}
              <div>
                {this.state.auditDetails?.legalHold === null &&
                this.state.auditDetails?.contentSearch === null &&
                this.state.auditDetails?.exportJob === null &&
                !this.state.hasAdditionalInfo ? (
                  <p className={styles.expandedRowHeader}>
                    No additional information available!
                  </p>
                ) : null}
              </div>
            </div>
          </div>
          {this.state.hasAdditionalInfo && (
            <div>
              <span className={styles.additionalInfoExpandedHeader}>
                Additional Information:
              </span>
              <span>
                {Object.entries(
                  this.props.auditEntryDetails?.additionalInfoJson
                )?.map((item, index) => {
                  const dataLength = Object.entries(
                    this.props.auditEntryDetails?.additionalInfoJson
                  )?.length;
                  return (
                    <>
                      <span style={{ marginRight: "5px" }}>{item[1]}</span>
                      <span>{index < dataLength - 1 ? "," : ""}</span>
                    </>
                  );
                })}
              </span>
            </div>
          )}
        </div>
      </>
    );
  }
}

function mapStateToProps(store) {
  return {
    auditEntryDetails: store.AuditLogs.list.auditEntryDetails,
  };
}

export default connect(mapStateToProps)(ExpandRowComponent);
