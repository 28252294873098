import React, { Component } from 'react';
import Loader from 'components/Loader';
import { Col, Row } from 'reactstrap';
import * as dataFormat from '../list/SearchDataFormatters';
import { Button } from 'reactstrap';
import actions from '../../../../actions/search/searchFormActions';
import s from './styles.module.scss';
import backIcon from '../../../../images/icons/arrow-left.svg';
import config from '../../../../config';
import cx from 'classnames';
import { fas, faL, faR } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import fontawesome from '@fortawesome/fontawesome';
import ExtendEmailList from './ExtendEmailList';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { store } from '../../../../index';
import { connect } from 'react-redux';
import attachmentIcon from '../../../../images/attachment.svg';
import defaultAttachmentIcon from 'images/attachment-white.svg';
import AttachmentContent from './AttachmentContent';
import FileViewer from 'react-file-viewer';
import './fileStyles.css';
import LegalHoldFormTooltip from 'components/CRUD/LegalHold/form/LegalHoldFormTooltip';
import paperClip from 'images/icons/paperclip.svg';
import bodyText from 'images/icons/bodyText.svg';
import searchIcon from 'images/icons/search.svg';
fontawesome.library.add(fas, faL, faR);

class ItemContentTable extends Component {
  state = {
    searchString: '',
    body: '',
    newBody: '',
    status: false,
    content: {},
    position: {
      top: 0,
      left: 0
    },
    showDetails: false,
    person: {},
    upn: '',
    toEmailList: [],
    ccEmailList: [],
    bccEmailList: [],
    openTags: false,
    view: false,
    anchorEl: null,
    custodianId: null,
    defaultTabsTabId: '1',
    p1Body: '',
    newP1Body: '',
    inlineAttachments: [],
    regularAttachments: [],
    attachmentMessage: {
      value:
        'This is inline attachment but cannot be rendered inside content of email. Please, download the item to preview in your local Outlook.'
    },
    attachmentPosition: {
      top: 0,
      left: 0
    },
    openCard: false,
    currentItemName: '',
    tagGroups: {},
    singleTags: [],
    selectedTagGroup: ''
  };

  colorPalette = [
    { color: '#0B666A', bg: '#35A29F' },
    { color: '#1D5B79', bg: '#468B97' },
    { color: '#4A55A2', bg: '#7895CB' },
    { color: '#2D4356', bg: '#435B66' },
    { color: '#374259', bg: '#545B77' },
    { color: '#E57C23', bg: '#E8AA42' },
    { color: '#ED2B2A', bg: '#F15A59' },
    { color: '#3A1078', bg: '#4E31AA' },
    { color: '#9C254D', bg: '#D23369' },
    { color: '#393646', bg: '#4F4557' },
    { color: '#735F32', bg: '#C69749' },
    { color: '#624F82', bg: '#9F73AB' },
    { color: '#472D2D', bg: '#553939' },
    { color: '#041C32', bg: '#041C32' }
  ];

  changeTab(field, id) {
    this.setState({
      [field]: id
    });
  }
  currentUser = store.getState().auth.currentUser;

  handleSearchBody = (input) => {
    const regex = new RegExp(
      `(${input
        .trim()
        .replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
        .replace(/\s+/g, '\\s*')})`,
      'gi'
    );
    if (input !== '') {
      const highlightedString = this.state.body?.replace(regex, (match) => `<mark>${match}</mark>`);
      this.setState({
        newBody: highlightedString
      });
    }
  };

  handleSearchP1Body = (input) => {
    const regex = new RegExp(input.trim(), 'gi');
    if (input !== '') {
      const highlightedString = this.state.p1Body.map((string) =>
        string.replace(regex, (match) => `<mark>${match}</mark>`)
      );
      this.setState({
        newP1Body: highlightedString
      });
    }
  };

  replaceWordsInText(body, replacements) {
    let replacedText = body;
    for (let i = 0; i < replacements.length; i++) {
      let replacement = replacements[i];
      let searchWord = replacement?.contentId;
      let replaceWord = replacement?.content;
      let regex = new RegExp('\\b' + searchWord + '\\b', 'gi');
      replacedText = replacedText.replace(regex, replaceWord);
    }
    return replacedText;
  }

  getEmailContentBody = () => {
    let emailBody = this.props.record.body.replace(
      /<meta[a-zA-Z0-9!@#\$%\^\&*\)\(+\s\\"><=:._;,-]+\/?>\n/g,
      ''
    );
    let modifiedText = this.replaceWordsInText(emailBody, this.state?.inlineAttachments);
    return modifiedText;
  };

  componentDidMount() {
    this.setState({
      singleTags: this.props?.infoTags,
      tagGroups: this.props?.tagGroups
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.content === this.state.content) {
      this.setState({
        content: {}
      });
    }
    if (prevState.searchString !== this.state.searchString && this.state.searchString === '') {
      this.setState({
        newBody: this.state.body,
        newP1Body: this.state.p1Body
      });
    }
    if (prevProps.record?.body !== this.props.record?.body && this.props.record?.body) {
      this.setState(
        {
          body: this.getEmailContentBody()
        },
        () => {
          this.setState({
            newBody: this.getEmailContentBody()
          });
        }
      );
    }
    if (prevProps.record?.p1Body !== this.props.record?.p1Body && this.props.record?.p1Body) {
      const envelopeData = this.props.record?.p1Body?.split('\r\n');
      this.setState({
        p1Body: envelopeData,
        newP1Body: envelopeData
      });
    }
    if (prevProps.itemId !== this.props?.itemId) {
      this.setState({
        defaultTabsTabId: '1',
        selectedTagGroup: '',
        newBody: ''
      });
    }
    if (prevProps.record !== this.props.record) {
      let inlineAttachments = [];
      let regularAttachments = [];
      this.props.record?.attachments?.map((item) => {
        if (item?.isInlineAttachment && item?.canBeRendered) {
          let fileType = item?.attachmentType?.replace(/\./g, '');
          let base64Image = item?.content;
          let formattedContent = `data:image/${fileType};base64,${base64Image}"`;
          inlineAttachments = [
            ...inlineAttachments,
            { contentId: item.contentId, content: formattedContent }
          ];
        } else {
          regularAttachments.push(item);
        }
        this.setState({
          inlineAttachments,
          regularAttachments
        });
      });
    }
    if (prevState.inlineAttachments !== this.state.inlineAttachments) {
      this.setState({
        body: this.getEmailContentBody()
      });
    }
    if (
      prevProps.infoTags !== this.props.infoTags ||
      prevProps.tagGroups !== this.props.tagGroups
    ) {
      this.setState({
        singleTags: this.props.infoTags,
        tagGroups: this.props?.tagGroups
      });
    }
  }

  contentSearchAction = (contentSearchId, itemId) => {
    this.setState(
      {
        status: !this.props.itemIcon.includes(this.props.itemId)
      },
      async () => {
        this.props.dispatch(actions.doAddIconToItem(itemId, this.state.status));
        if (!this.state.status) {
          await this.props.dispatch(actions.doIncludeItems(contentSearchId, [itemId]));
          setTimeout(
            () =>
              this.props.dispatch(
                actions.doGetExcludedStats(this.props.legalCaseId, this.props.contentSearchId)
              ),
            2000
          );
          return;
        }
        await this.props.dispatch(actions.doExcludeItems(contentSearchId, [itemId]));
        setTimeout(
          () =>
            this.props.dispatch(
              actions.doGetExcludedStats(this.props.legalCaseId, this.props.contentSearchId)
            ),
          2000
        );
      }
    );
  };

  handleInitials = (upn) => {
    let firstLetter = null,
      secondLetter = null;
    if (upn) {
      firstLetter = upn.split('.')[0][0];
      if (upn.split('.').length < 3) {
        secondLetter = null;
      } else {
        secondLetter = upn.split('.')[1][0];
      }
      let initials = '';
      if (firstLetter === null) {
        initials = secondLetter?.toUpperCase();
      } else if (secondLetter === null) {
        initials = firstLetter?.toUpperCase();
      } else {
        initials = firstLetter?.toUpperCase() + secondLetter?.toUpperCase();
      }
      return initials;
    }
  };

  downloadItemPreview = () => {
    const { dispatch } = this.props;
    const url = `${config.externalApi}/legal/api/ContentSearch/${this.props.legalCaseId}/${this.props.itemId}/${this.props.contentSearchId}/GetFile`;
    const currentUser = this.currentUser;
    dispatch(actions.handleDownloadItemPreview(url, currentUser));
  };

  handleView = () => {
    this.setState({
      view: !this.state.view
    });
  };

  fileType = (type) => {
    return type?.replace(/\./g, '');
  };

  content = (type, content) => {
    const fileUrl = `data:${type};base64,${content}`;
    return fileUrl;
  };

  renderView() {
    return (
      <>
        <Row>
          <Col sm={12}>
            <div
              className={'d-flex justify-content-between align-items-center'}
              style={{ marginTop: '5px' }}
            >
              <h5 className={`${s.headerFont} fw-bold`}>{this.props.record.subject}</h5>
              <div className='d-flex align-items-center'>
                <h5 className={s.emailDate}>
                  {dataFormat.dateTimeFormatter(this.props.record.itemDate)}
                </h5>
                {/* <span>
                  <Button
                    color={"default"}
                    onClick={() =>
                      this.contentSearchAction(
                        this.props.contentSearchId,
                        this.props.itemId
                      )
                    }
                    className="ms-3"
                  >
                    {this.props.itemIcon.includes(this.props.itemId)
                      ? "Include"
                      : "Exclude"}
                  </Button>
                </span> */}
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                marginTop: '24px'
              }}
            >
              <div style={{ width: '100%', marginTop: '-15px' }}>
                {this.props.record?.p1Body && this.props.record?.p1Body?.length !== 0 ? (
                  <p
                    style={{
                      marginBottom: '0',
                      display: 'flex',
                      height: '40px'
                    }}
                  >
                    <span className={s.detailsSection}>From:</span>
                    <p className={s.senderContainer}>
                      <span className={`${s.headerFont} me-1`}>
                        {this.props.record?.sender?.smtpAddress}
                      </span>
                      {this.props.record?.sentRepresentingEmailAddress && (
                        <span>
                          {`on behalf of ${this.props.record?.sentRepresentingEmailAddress}`}
                        </span>
                      )}
                    </p>
                  </p>
                ) : null}
                {this.props.record?.recipientListDisplayName &&
                this.props.record?.recipientListDisplayName?.length !== 0 ? (
                  <p style={{ marginBottom: 0 }}>
                    <span className={s.detailsSection}>To:</span>
                    <ExtendEmailList response={this.props.record?.recipientListDisplayName} />
                  </p>
                ) : null}

                {this.props.record?.ccListDisplayName &&
                this.props.record?.ccListDisplayName?.length !== 0 ? (
                  <p style={{ marginBottom: '0px' }}>
                    <span className={s.detailsSection}>Cc:</span>
                    <ExtendEmailList response={this.props.record?.ccListDisplayName} />
                  </p>
                ) : null}
                {this.props.record?.bccListDisplayName &&
                this.props.record?.bccListDisplayName?.length !== 0 ? (
                  <p style={{ marginBottom: '0px' }}>
                    <span className={s.detailsSection}>Bcc:</span>
                    <ExtendEmailList response={this.props.record?.bccListDisplayName} />
                  </p>
                ) : null}
                <div
                  style={{
                    display: 'flex',
                    marginBottom: '15px',
                    marginTop: '20px'
                  }}
                >
                  {this.state.singleTags?.length !== 0 ||
                  Object.entries(this.state.tagGroups)?.length !== 0 ? (
                    <p className={s.detailsSection}>Tags:</p>
                  ) : null}
                  <div className={s.tagsContainer}>
                    {Object.entries(this.state.tagGroups).map((item, index) => (
                      <div
                        style={{
                          height: 'fit-content',
                          marginRight: '10px'
                        }}
                        onClick={() => {
                          this.setState({
                            selectedTagGroup:
                              this.state.selectedTagGroup === '' ||
                              this.state.selectedTagGroup !== item[0]
                                ? item[0]
                                : ''
                          });
                        }}
                      >
                        <div
                          style={{
                            display: 'flex'
                          }}
                        >
                          <p
                            style={{
                              backgroundColor: `${
                                this.colorPalette[index % this.colorPalette?.length]?.color
                              }`,
                              margin: '0',
                              width: '7px',
                              height: '21px',
                              cursor: 'pointer'
                            }}
                          />
                          <p
                            style={{
                              backgroundColor: `${
                                this.colorPalette[index % this.colorPalette?.length]?.bg
                              }`,
                              marginBottom: 0,
                              paddingRight: '5px',
                              paddingLeft: '7px',
                              fontSize: '12px',
                              width: '-webkit-fill-available',
                              textAlign: 'center',
                              display: 'flex',
                              alignItems: 'center',
                              cursor: 'pointer'
                            }}
                          >
                            {item[0]}
                          </p>
                        </div>
                        {this.state.selectedTagGroup === item[0] && (
                          <div
                            style={{
                              margin: '0 0 0 7px'
                            }}
                          >
                            <p
                              style={{
                                backgroundColor: `${
                                  this.colorPalette[index % this.colorPalette?.length]?.bg
                                }`,
                                marginBottom: 0,
                                overflowY: 'auto',
                                maxHeight: '70px'
                              }}
                            >
                              {item[1]?.map((item) => (
                                <div
                                  style={{
                                    display: 'flex',
                                    borderBottom: '1px solid white',
                                    fontSize: '12px',
                                    paddingRight: '5px',
                                    alignItems: 'center'
                                  }}
                                >
                                  <p
                                    style={{
                                      backgroundColor: `${
                                        this.colorPalette[index % this.colorPalette?.length]?.color
                                      }`,
                                      margin: '0px 5px 0px 0px',
                                      width: '5px'
                                    }}
                                  />
                                  <p
                                    style={{
                                      marginBottom: '0px',
                                      display: 'flex',
                                      alignItems: 'center'
                                    }}
                                  >
                                    {item?.body && (
                                      <img
                                        title={'body'}
                                        alt={'body'}
                                        width={12}
                                        height={12}
                                        src={bodyText}
                                        className={'m-1'}
                                      />
                                    )}

                                    {item?.attachment && (
                                      <img
                                        title={'attachment'}
                                        alt={'attachment'}
                                        width={14}
                                        height={14}
                                        src={paperClip}
                                        className={'m-1'}
                                      />
                                    )}
                                    {item.tagKey}
                                  </p>
                                </div>
                              ))}
                            </p>
                          </div>
                        )}
                      </div>
                    ))}
                    {this.state.singleTags.map((item, index) => (
                      <>
                        <p
                          style={{
                            backgroundColor: `${
                              this.colorPalette[index % this.colorPalette?.length]?.color
                            }`
                          }}
                          className={s.singleTag}
                        >
                          {item?.body && (
                            <img
                              title={'body'}
                              alt={'body'}
                              width={12}
                              height={12}
                              src={bodyText}
                              className={'me-1'}
                            />
                          )}

                          {item?.attachment && (
                            <img
                              title={'attachment'}
                              alt={'attachment'}
                              width={14}
                              height={14}
                              src={paperClip}
                              className={'me-1'}
                            />
                          )}

                          {item?.tagKey}
                        </p>
                      </>
                    ))}
                  </div>
                </div>
                <p className={s.sender}>
                  {this.props?.record?.senderDisplayName ? (
                    <span className={s.messageClass}>
                      {this.props?.record?.senderDisplayName} ({this.props?.record?.messageClass})
                    </span>
                  ) : null}
                </p>
              </div>
            </div>
            <div>
              <Nav tabs className='bg-transparent d-flex mt'>
                <NavItem className={this.state.defaultTabsTabId === '1' ? s.navItem : s.linkText}>
                  <NavLink
                    className={this.state.defaultTabsTabId === '1' ? s.navLink : s.linkText}
                    onClick={() => {
                      this.changeTab('defaultTabsTabId', '1');
                    }}
                  >
                    Content
                  </NavLink>
                </NavItem>
                <NavItem className={this.state.defaultTabsTabId === '2' ? s.navItem : s.linkText}>
                  <NavLink
                    className={this.state.defaultTabsTabId === '2' ? s.navLink : s.linkText}
                    onClick={() => {
                      this.changeTab('defaultTabsTabId', '2');
                    }}
                  >
                    Envelope
                  </NavLink>
                </NavItem>
                <NavItem className={this.state.defaultTabsTabId === '3' ? s.navItem : s.linkText}>
                  <NavLink
                    className={this.state.defaultTabsTabId === '3' ? s.navLink : s.linkText}
                    onClick={() => {
                      this.changeTab('defaultTabsTabId', '3');
                      this.setState({
                        view: false
                      });
                    }}
                  >
                    <img
                      title={'Has Attachments'}
                      alt={'Has Attachments'}
                      width={16}
                      height={16}
                      src={attachmentIcon}
                      className={'me-2'}
                    />
                    Attachments ({this.state.regularAttachments?.length || 0})
                  </NavLink>
                </NavItem>
                <NavItem className='ms-auto'>
                  {!this.state.content?.html && (
                    <div
                      style={{
                        justifyContent: 'end'
                      }}
                      className={'d-flex ms-auto'}
                    >
                      <span>
                        <input
                          type='search'
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              if (this.state.defaultTabsTabId === '1') {
                                this.handleSearchBody(this.state.searchString);
                              } else if (this.state.defaultTabsTabId === '2') {
                                this.handleSearchP1Body(this.state.searchString);
                              }
                            }
                          }}
                          placeholder={'Highlight'}
                          style={{
                            border: '0.5px solid #0046b1',
                            borderRadius: '3px'
                          }}
                          className={'form-control search-input me-5 w-200'}
                          value={this.state.searchString}
                          onChange={(e) => {
                            this.setState({
                              searchString: e.target.value
                            });
                          }}
                        />
                      </span>
                      <span>
                        <button
                          className='btn search-button-with-icon me-2 ms-10'
                          type='button'
                          onClick={() => {
                            if (this.state.defaultTabsTabId === '1') {
                              this.handleSearchBody(this.state.searchString);
                            } else if (this.state.defaultTabsTabId === '2') {
                              this.handleSearchP1Body(this.state.searchString);
                            }
                          }}
                          style={{ width: '37px', height: '37px' }}
                        >
                          <img
                            title={'search'}
                            alt={'search'}
                            width={14}
                            height={14}
                            src={searchIcon}
                          />
                        </button>
                      </span>
                      {this.props.permissions['LegalUI.ContentSearches.DownloadItems'] ? (
                        <Button
                          color={'default'}
                          style={{ marginLeft: '15px' }}
                          onClick={this.downloadItemPreview}
                        >
                          Download
                        </Button>
                      ) : null}
                    </div>
                  )}
                </NavItem>
              </Nav>
              <TabContent className='mb-xlg' activeTab={this.state.defaultTabsTabId}>
                <TabPane className={s.tabPane} tabId='1'>
                  {
                    <div>
                      {this.props.record?.body && this.state.newBody?.trim().length !== 0 ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: this.state.newBody
                          }}
                        ></div>
                      ) : (
                        <>
                          <div>
                            Non email items can not be previewed. Please, download the item to
                            preview in your local Outlook
                          </div>
                        </>
                      )}
                    </div>
                  }
                </TabPane>
                <TabPane className={s.tabPane} tabId='2'>
                  {
                    <>
                      <div>
                        {this.props.record.p1Body && this.state.newP1Body?.length !== 0 ? (
                          <div>
                            {this.state.newP1Body.map((item, index) => (
                              <p key={index} dangerouslySetInnerHTML={{ __html: item }} />
                            ))}
                          </div>
                        ) : (
                          <>
                            <div>
                              Non email items can not be previewed. Please, download the item to
                              preview in your local Outlook
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  }
                </TabPane>
                <TabPane className={s.tabPane} tabId='3'>
                  {
                    <div>
                      <div>
                        {this.state.regularAttachments && !this.state.view ? (
                          <div className={'d-flex mt-4 flex-wrap'}>
                            {this.state.regularAttachments?.map((item, index) => {
                              return (
                                <div
                                  key={index}
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column'
                                  }}
                                >
                                  <div
                                    style={{
                                      marginBottom: '20px'
                                    }}
                                  >
                                    {this.state.openCard &&
                                      item.attachmentName === this.state.currentItemName && (
                                        <LegalHoldFormTooltip
                                          currentCustodian={this.state.attachmentMessage}
                                          position={this.state.attachmentPosition}
                                        />
                                      )}
                                  </div>
                                  <div
                                    className={cx(s.attachment__item, {
                                      [s.attachment__item_active]:
                                        this.state.content.itemName === item.attachmentName
                                    })}
                                    style={{
                                      position: 'relative',
                                      zIndex: 'auto',
                                      cursor: 'pointer'
                                    }}
                                    onClick={() => {
                                      this.setState({
                                        view: true,
                                        content: {
                                          html: item.content,
                                          itemName: item.attachmentName,
                                          type: item.attachmentType
                                        },
                                        openCard: false,
                                        currentItemName: ''
                                      });
                                    }}
                                    onMouseOver={(e) => {
                                      if (item?.isInlineAttachment && !item?.canBeRendered) {
                                        this.setState({
                                          openCard: true,
                                          attachmentPosition: {
                                            top: e.pageY,
                                            left: e.pageX
                                          },
                                          currentItemName: item?.attachmentName
                                        });
                                      }
                                    }}
                                    onMouseLeave={() => {
                                      if (item?.isInlineAttachment && !item?.canBeRendered) {
                                        this.setState({
                                          openCard: false,
                                          attachmentPosition: {
                                            top: 0,
                                            left: 0
                                          },
                                          currentItemName: ''
                                        });
                                      }
                                    }}
                                  >
                                    {item?.attachmentIcon ? (
                                      <FontAwesomeIcon
                                        icon={item.attachmentIcon}
                                        className={'me-3'}
                                        size={'2x'}
                                      />
                                    ) : (
                                      <img
                                        title={'Attachment'}
                                        alt={'Attachment'}
                                        width={20}
                                        height={20}
                                        src={defaultAttachmentIcon}
                                        className={'me-2'}
                                      />
                                    )}

                                    <div>
                                      <p className={'mb-0'}>{item.attachmentName}</p>
                                      <small className={'mb-0 text-muted'}>
                                        {item.attachmentSize}
                                      </small>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        ) : null}
                      </div>
                      {this.state.view && (
                        <div>
                          <div className={'d-flex align-items-center mb-2'}>
                            <button
                              className={'btn bg-transparent me-2 ps-0'}
                              onClick={() =>
                                this.setState({
                                  content: '',
                                  view: false
                                })
                              }
                            >
                              <img src={backIcon} alt={'arrow-back'} />
                            </button>
                            <h5 className={'mb-0 fw-bold'}>Back to attachments view</h5>
                          </div>
                          {this.state.content?.type === '.docx' ||
                          this.state.content?.type === '.xlsx' ? (
                            <i className={'d-flex align-items-center mb-4'}>
                              Note: Attachment preview might not display all styles of document
                              correctly (Footer, Headers, etc). For displaying original document,
                              please download the item.
                            </i>
                          ) : null}
                          <div style={{ backgroundColor: 'inherit' }}>
                            {this.state.content?.type === ('.txt' || '.json') ? (
                              <AttachmentContent
                                attachment={this.state.content?.html}
                                attachmentType={this.state.content?.type}
                              />
                            ) : this.state.content?.type === null ? (
                              <div className={s.unsupportedMessage}>
                                <p>File format is not supported.</p>
                              </div>
                            ) : (
                              <FileViewer
                                fileType={this.fileType(this.state.content?.type)}
                                filePath={this.content(
                                  this.state.content?.type,
                                  this.state.content.html
                                )}
                                wrapperProps={{ className: 'custom-wrapper' }}
                              />
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  }
                </TabPane>
              </TabContent>
            </div>
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { record, loading } = this.props;

    if (loading || !record) {
      return <Loader />;
    }

    return this.renderView();
  }
}
function mapStateToProps(store) {
  return {
    permissions: store.auth.permissions
  };
}

export default connect(mapStateToProps)(ItemContentTable);
