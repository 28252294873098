import React, { Component } from 'react';
import Widget from 'components/Widget/Widget';
import * as dataFormat from 'components/CRUD/LegalHold/list/LegalHoldDataFormatters';
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  Col,
  Row,
  Button
} from 'reactstrap';
import { FormattingService } from 'utils/sizeFormatting';
import actions from 'actions/Export/ExportListActions';
import { connect } from 'react-redux';
import formActions from 'actions/Export/ExportFormActions';
import ExportJobFormPage from '../form/ExportJobFormPage';
import plusIcon from 'images/icons/plus.svg';
import Pagination from 'react-js-pagination';
import styles from 'components/CRUD/LegalHold/list/LegalHoldList.module.scss';
import config from 'config';
import syncIcon from '../../../../images/icons/sync.svg';
import closeIcon from '../../../../images/icons/close.svg';
import searchIcon from 'images/icons/search.svg';
import caretDown from 'images/icons/caret-down.svg';
import caretUp from 'images/icons/caret-up.svg';
import { push } from 'connected-react-router';
import viewIcon from 'images/icons/eye.svg';
import { faTable } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import exportStyles from 'components/CRUD/Export/Export.module.scss';

class ExportTilesView extends Component {
  state = {
    totalCount: 0,
    searchString: '',
    pageNumber: 1,
    pageSize: 3,
    paginationModalOpen: false,
    resetModalOpen: false,
    selectedJob: null,
    newRows: [],
    legalCaseId: this.props?.legalCaseId || '',
    expandExport: false,
    selectedExportId: ''
  };

  handleRetryFailedItems(legalCaseId, exportJobId) {
    const { dispatch } = this.props;
    dispatch(actions.doRetryFailedItems(legalCaseId, exportJobId, this.props?.currentUser));
  }

  handleResetQueuedItems() {
    const { dispatch } = this.props;
    dispatch(actions.doResetFailedItems(this.state.selectedJob.id, this.props?.currentUser)).then(
      () => {
        this.setState({
          resetModalOpen: false
        });
      }
    );
  }

  closeResetModal() {
    this.setState({
      resetModalOpen: !this.state.resetModalOpen
    });
  }

  actionFormatter(cell, row) {
    return (
      <Dropdown
        isOpen={this.state.selectedExportId === cell}
        className={`${styles.actionsButton}`}
        toggle={() => this.toggleActions(cell, row)}
      >
        <DropdownToggle className={`${styles.actionsButton} bg-transparent text-white mb-0`}>
          Actions
          {this.state.selectedExportId === cell ? (
            <img src={caretUp} alt={'collapsed'} width='15px' height='15px' className='ms-2' />
          ) : (
            <img src={caretDown} alt={'uncollapsed'} width='15px' height='15px' className='ms-2' />
          )}
        </DropdownToggle>
        <DropdownMenu style={{ width: '190px' }} positionFixed={true}>
          <button
            disabled={!row?.failedItems}
            className='btn bg-transparent text-white fw-semi-bold d-flex align-items-center '
            type='button'
            onClick={() => this.handleRetryFailedItems(row?.legalCaseId, row?.id)}
          >
            <img src={syncIcon} alt={'sync'} width={'16px'} height={'16px'} className={'me-2 '} />
            <p className={'mb-0'}>Retry failed items</p>
          </button>
          <button
            disabled={row?.isExported || row?.totalItems === 0 || row?.itemsRoutedForExport === 0}
            className='btn bg-transparent text-white fw-semi-bold d-flex align-items-center '
            type='button'
            onClick={() => {
              this.setState({
                resetModalOpen: true,
                selectedJob: row
              });
            }}
          >
            <img src={closeIcon} alt={'sync'} width={'16px'} height={'16px'} className={'me-2 '} />
            <p className={'mb-0'}>Reset queued items</p>
          </button>
          <button
            className='btn bg-transparent text-white fw-semi-bold d-flex align-items-center '
            type='button'
            onClick={() => {
              this.props.dispatch(push(`/app/Export/${cell}`));
            }}
          >
            <img
              src={viewIcon}
              alt={'details'}
              width={'16px'}
              height={'16px'}
              className={'me-2 '}
            />
            <p className={'mb-0'}>Details</p>
          </button>
        </DropdownMenu>
      </Dropdown>
    );
  }

  openModal() {
    const { dispatch } = this.props;
    dispatch(formActions.doOpenConfirm());
  }

  closeModal() {
    const { dispatch } = this.props;
    dispatch(formActions.doCloseConfirm());
  }

  handleSearch = () => {
    const { dispatch } = this.props;
    this.setState({
      pageNumber: 1
    });
    dispatch(
      actions.doFetch({
        searchString: this.state.searchString,
        pageNumber: this.state.pageNumber,
        pageSize: this.state.pageSize,
        legalCaseId: this.state.legalCaseId
      })
    ).then(() => {
      this.setState({
        totalCount: this.props.count,
        newRows: this.props.rows
      });
    });
  };

  refreshTable = () => {
    const { dispatch } = this.props;
    const interval = this.props?.refreshInterval.concat('000');
    const refreshInterval = JSON.parse(interval);
    this.intervalID = setInterval(() => {
      dispatch(
        actions.doFetch({
          legalCaseId: this.state.legalCaseId,
          searchString: this.state.searchString,
          pageNumber: this.state.pageNumber,
          pageSize: this.state.pageSize
        })
      ).then(() => {
        this.setState({
          totalCount: this.props.count,
          newRows: this.props.rows
        });
      });
    }, refreshInterval);
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(
      actions.doFetch({
        searchString: this.state.searchString,
        pageNumber: this.state.pageNumber,
        pageSize: this.state.pageSize,
        legalCaseId: this.state.legalCaseId
      })
    ).then(() => {
      this.setState({
        totalCount: this.props.count,
        newRows: this.props.rows
      });
    });
    if (this.props?.refreshInterval && this.props?.refreshInterval !== null) {
      this.refreshTable();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { dispatch } = this.props;
    if (
      (prevState.searchString !== this.state.searchString && this.state.searchString === '') ||
      prevState.pageSize !== this.state.pageSize ||
      prevState.pageNumber !== this.state.pageNumber
    ) {
      dispatch(
        actions.doFetch({
          searchString: this.state.searchString,
          pageSize: this.state.pageSize,
          pageNumber: this.state.pageNumber,
          legalCaseId: this.state.legalCaseId
        })
      ).then(() => {
        this.setState({
          newRows: this.props.rows,
          totalCount: this.props.count
        });
      });
    }
    if (prevProps.rows !== this.props.rows) {
      this.setState({
        newRows: this.props.rows,
        totalCount: this.props.count
      });
    }
    if (prevProps.refreshInterval !== this.props.refreshInterval) {
      this.refreshTable();
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  handlePageChange = (pageNumber) => {
    this.setState({ pageNumber: pageNumber });
  };

  toggleExport = () => {
    this.setState({
      expandExport: !this.state.expandExport
    });
  };

  toggleActions = (cell, row) => {
    if (this.state.selectedExportId === '') {
      this.setState({
        selectedExportId: cell
      });
    } else {
      this.setState({
        selectedExportId: ''
      });
    }
  };

  exportMenu = () => {
    return (
      <Dropdown
        isOpen={this.state.expandExport}
        className={`${styles.exportButton}`}
        toggle={this.toggleExport}
      >
        <DropdownToggle
          className={`${styles.exportButton} bg-transparent text-white mb-0`}
          disabled={this.state.totalCount === 0}
        >
          Export
          {!this.state.expandExport ? (
            <img src={caretDown} alt={'uncollapsed'} width='20px' height='20px' className='ms-2' />
          ) : (
            <img src={caretUp} alt={'collapsed'} width='20px' height='20px' className='ms-2' />
          )}
        </DropdownToggle>
        <DropdownMenu className='p-0 bg-transparent'>
          <DropdownItem className={`${styles.exportItem}`} onClick={this.handleExportCSV}>
            CSV
          </DropdownItem>
          <DropdownItem className={`${styles.exportItem}`} onClick={this.handleExportPDF}>
            PDF
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  };

  columnsToExport = [
    'name',
    'exportPolicyName',
    'created',
    'totalItems',
    'exportedItems',
    'totalSizeInBytes',
    'itemsRoutedForExport',
    'failedItems',
    'statusMessage',
    'exportStatus'
  ];

  handleExportCSV = () => {
    const { dispatch } = this.props;
    const url = `${config.externalApi}/legal/api/ExportJob/ExportToCsv?`;
    const currentUser = this.props?.currentUser;
    const columnHeaders = this.columnsToExport.join(',');
    dispatch(
      actions.handleExportToCSV(
        url,
        currentUser,
        this.state.legalCaseId,
        this.state.searchString,
        columnHeaders
      )
    );
  };

  handleExportPDF = () => {
    const { dispatch } = this.props;
    const url = `${config.externalApi}/legal/api/ExportJob/ExportToPdf?`;
    const currentUser = this.props?.currentUser;
    const columnHeaders = this.columnsToExport.join(',');
    dispatch(
      actions.handleExportToPDF(
        url,
        currentUser,
        this.state.legalCaseId,
        this.state.searchString,
        columnHeaders
      )
    );
  };

  showTable() {
    const { dispatch } = this.props;
    dispatch(actions.doShowTable());
  }

  render() {
    const toReadableSizeFormat = new FormattingService();

    return (
      <>
        <Widget style={{ marginTop: '20px', width: '100%' }}>
          <>
            <Row>
              <Col style={{ marginTop: '0px', marginBottom: '10px' }}>
                <div className={'d-flex align-items-center'}>
                  <h3 className={'fw-semi-bold me-4 mb-0'}>Export Jobs</h3>
                </div>
              </Col>
            </Row>
            <Row key={'table-part'}>
              <Col style={{ marginTop: '8px' }}>
                {this.props.permissions['LegalUI.Export.Create'] &&
                this.props?.caseStatus !== config.caseStatus.Closed ? (
                  <div className={'d-flex align-items-center'}>
                    {this.props.legalCaseId && (
                      <button
                        className='btn fw-semi-bold d-flex align-items-center'
                        type='button'
                        onClick={() => this.openModal()}
                      >
                        <img src={plusIcon} alt={'plus'} className={'me-2'} />
                        <p className={'mb-0'}>New</p>
                      </button>
                    )}
                  </div>
                ) : null}
              </Col>
              <Col
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '5px'
                }}
              >
                <div style={{ marginRight: '5px' }}>{this.exportMenu()}</div>
                <span>
                  <button
                    className='btn fw-semi-bold d-flex align-items-center'
                    type='button'
                    onClick={() => this.showTable()}
                    style={{ height: '37px', marginRight: '5px' }}
                  >
                    <FontAwesomeIcon icon={faTable} />
                  </button>
                </span>
                <span>
                  <input
                    type='search'
                    placeholder={'Search'}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        this.handleSearch();
                      }
                    }}
                    style={{
                      border: '0.5px solid #0046b1',
                      borderRadius: '0'
                    }}
                    className={'form-control search-input me-5 w-200'}
                    value={this.state.searchString}
                    onChange={(e) => {
                      this.setState({
                        searchString: e.target.value
                      });
                    }}
                  />
                </span>
                <span>
                  <button
                    style={{
                      borderRadius: '0',
                      height: '37px',
                      width: '37px'
                    }}
                    className='btn me-2 ms-10'
                    type='button'
                    onClick={this.handleSearch}
                  >
                    <img title={'search'} alt={'search'} width={14} height={14} src={searchIcon} />
                  </button>
                </span>
              </Col>
            </Row>
            <Row sm={12} className='mt-4'>
              {this.state.newRows?.length ? (
                this.state.newRows?.map((item) => (
                  <Col sm={4} key={item.id}>
                    <Widget
                      style={{
                        height: '380px',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        width: '100%'
                      }}
                    >
                      <p className={exportStyles.cardName}>{item.name}</p>
                      <p className='mb-0'>
                        <span className='fw-bold me-2'>Policy:</span>
                        <span>{item.exportPolicyName}</span>
                      </p>
                      <p>
                        <span className='fw-bold me-2'>Status:</span>
                        <span>{item.statusMessage}</span>
                      </p>
                      <div>
                        <p className={styles.sectionHeader}>Results</p>
                        <div className='d-flex w-100 mb-0'>
                          <p className='w-50 mb-0'>
                            <span className='fw-bold me-2'> Total Items:</span>
                            <span>{toReadableSizeFormat.numberWithCommas(item.totalItems)}</span>
                          </p>
                          <p className='w-50 mb-0'>
                            <span className='fw-bold me-2'> Size:</span>
                            <span>{dataFormat.sizeFormatter(item.totalSizeInBytes)}</span>
                          </p>
                        </div>
                        <p className='mb-0'>
                          <span className='fw-bold me-2'>Exported Items:</span>
                          <span>{toReadableSizeFormat.numberWithCommas(item.exportedItems)}</span>
                        </p>
                        <p className='mb-0'>
                          <span className='fw-bold me-2'>Expected items after export:</span>
                          <span>
                            {toReadableSizeFormat.numberWithCommas(item.itemsRoutedForExport)}
                          </span>
                        </p>
                        <p className='mb-0'>
                          <span className='fw-bold me-2'>Failed Items:</span>
                          <span>{toReadableSizeFormat.numberWithCommas(item.failedItems)}</span>
                        </p>
                        <p>
                          <span className='fw-bold me-2'>Export Finished:</span>
                          {dataFormat.dateTimeFormatter(item.exportFinishedTimestamp)}
                        </p>
                      </div>
                      <p>
                        <span className='fw-bold me-2'>Created by:</span>
                        <span className='me-1'>{item?.expireonUser?.firstName}</span>
                        <span className='me-1'>{item?.expireonUser?.lastName}</span>
                        <span>({dataFormat.dateTimeFormatter(item.created)})</span>
                      </p>
                      <p className='d-flex justify-content-end'>
                        {this.actionFormatter(item.id, item)}
                      </p>
                    </Widget>
                  </Col>
                ))
              ) : (
                <div
                  style={{ height: this.state.newRows?.length ? 500 : 'auto' }}
                  className={'d-flex w-100 justify-content-center align-items-center'}
                >
                  <p className={'text-white'}>No data available</p>
                </div>
              )}
            </Row>

            <Row key={'pagination'}>
              <Col style={{ marginTop: '3px' }}>
                <p>Total: {this.state.totalCount}</p>
              </Col>
              <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {this.state.totalCount ? (
                  <Pagination
                    totalItemsCount={this.state.totalCount}
                    onChange={this.handlePageChange}
                    activePage={this.state.pageNumber}
                    itemsCountPerPage={this.state.pageSize}
                    pageRangeDisplayed={5}
                    prevPageText={'<'}
                    nextPageText={'>'}
                    firstPageText={'<<'}
                    lastPageText={'>>'}
                    linkClassFirst={styles.paginationNext}
                    linkClassPrev={styles.paginationNext}
                    linkClassNext={styles.paginationNext}
                    linkClassLast={styles.paginationNext}
                    linkClass={styles.pageLink}
                    activeLinkClass={styles.activeLinkClass}
                  />
                ) : null}
                <Dropdown
                  isOpen={this.state.paginationModalOpen}
                  toggle={() =>
                    this.setState({
                      paginationModalOpen: !this.state.paginationModalOpen
                    })
                  }
                  className={styles.pageSizeDropdown}
                >
                  <DropdownToggle className='bg-transparent text-white d-flex'>
                    <p style={{ marginBottom: '3px', marginRight: '2px' }}>{this.state.pageSize}</p>
                    <img
                      src={caretDown}
                      alt={'uncollapsed'}
                      width='20px'
                      height='20px'
                      className='ms-2'
                    />
                  </DropdownToggle>
                  <DropdownMenu positionFixed={true}>
                    {[3, 6, 9].map((item) => (
                      <DropdownItem
                        className={styles.dropdownItem}
                        key={item}
                        onClick={() => {
                          this.setState({
                            pageSize: item,
                            paginationModalOpen: false,
                            pageNumber: 1
                          });
                        }}
                      >
                        {item}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>
              </Col>
            </Row>
            <Modal
              size='m'
              isOpen={this.props.modalOpen}
              className={`themeStyle${this.props.currentTheme?.replace('#', '')}`}
            >
              <ModalHeader toggle={() => this.closeModal()}>Create export job</ModalHeader>
              <ModalBody>
                <ExportJobFormPage legalCaseId={this.props.legalCaseId} />
              </ModalBody>
            </Modal>
          </>
        </Widget>
        <Modal
          size='sm'
          isOpen={this.state.resetModalOpen}
          className={`themeStyle${this.props.currentTheme?.replace('#', '')}`}
        >
          <ModalHeader toggle={() => this.closeResetModal()}>Confirm!</ModalHeader>
          <ModalBody>
            Do you want to reset queued items for {this.state?.selectedJob?.name} export job?
            <Button
              color='primary'
              onClick={() => this.handleResetQueuedItems()}
              className={'mt-4 w-100'}
            >
              Reset
            </Button>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

function mapStateToProps(store) {
  return {
    rows: store.Export.list.rows,
    modalOpen: store.Export.form.modalOpen,
    count: store.Export.list.count,
    permissions: store.auth.permissions,
    refreshInterval: store.LegalHold.list.refreshInterval,
    currentUser: store.auth.currentUser,
    currentTheme: store.layout.currentTheme
  };
}
export default connect(mapStateToProps)(ExportTilesView);
