const CredentialFields = {
  credentialId: { type: "id", label: "Credential Id" },
  credentialName: { type: "string", label: "Credential Name*" },
  login: { type: "string", label: "Login*" },
  password: { type: "string", label: "Password*" },
  credentialType: { type: "enum", label: "Credential Type*" },
  encryptedTenantSecret: { type: "string", label: "Encrypted Tenant Secret*" },
  hashedTenantSecret: { type: "string", label: "Hashed Tenant Secret*" },
  tenantCertificateId: { type: "id", label: "Certificate*" },
};

export default CredentialFields;
