import Errors from 'components/FormItems/error/errors';
import config from '../../config';
import { Client } from '../../legal-api';
import { defaultOptions } from '../../services/userService';
import { store } from 'index';
import { isLegalAdmin } from 'utils/isLegalAdmin';
export function getPageRangeDisplayed(currentPage) {
  if (currentPage > 999) {
    return 2;
  }
  if (currentPage > 99) {
    return 3;
  }
  return 5;
}

async function list(legalCaseId, searchString, pageNumber, pageSize, sortOrder) {
  const currentUser = store.getState().auth?.currentUser;
  const legalAdmin = isLegalAdmin(currentUser);
  const loggedUser = currentUser?.userName;
  const response = await new Client(config.externalApi).contentSearchAll(
    legalCaseId,
    sortOrder,
    undefined,
    searchString,
    pageNumber,
    pageSize,
    loggedUser,
    legalAdmin
  );
  return response;
}

async function getTreeNodes(legalCaseId, loggedUser) {
  let baseUrl = `${config.externalApi}/legal/api/ContentSearch/GetMockupNodes?`;

  if (legalCaseId !== undefined && legalCaseId !== null && legalCaseId !== '') {
    baseUrl += 'legalCaseId=' + encodeURIComponent('' + legalCaseId) + '&';
  }
  if (loggedUser !== undefined && loggedUser !== '') {
    baseUrl += 'loggedUser=' + encodeURIComponent('' + loggedUser) + '&';
  }
  baseUrl = baseUrl.replace(/[?&]$/, '');

  const response = await fetch(baseUrl, {
    ...defaultOptions,
    method: 'GET'
  });

  const data = await response.json();
  return data;
}

const actions = {
  doFetch:
    (filter, keepPagination = false) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: 'SEARCH_LIST_FETCH_STARTED',
          payload: { filter, keepPagination }
        });

        const response = await list(
          filter.legalCaseId,
          filter.searchString,
          filter.pageNumber,
          filter.pageSize,
          filter.sortOrder
        );
        dispatch({
          type: 'SEARCH_LIST_FETCH_SUCCESS',
          payload: {
            rows: response.contentSearches,
            count: response.totalCount
          }
        });
      } catch (error) {
        Errors.handle(error);
        dispatch({
          type: 'SEARCH_LIST_FETCH_ERROR'
        });
      }
    },
  doOpenConfirm: () => async (dispatch) => {
    dispatch({
      type: 'SEARCH_LIST_OPEN_CONFIRM'
    });
  },
  doCloseConfirm: () => async (dispatch) => {
    dispatch({
      type: 'SEARCH_LIST_CLOSE_CONFIRM'
    });
  },
  doShowTable: () => async (dispatch) => {
    dispatch({
      type: 'SEARCH_LIST_SHOW_TABLE_CONFIRM'
    });
  },
  doHideTable: () => async (dispatch) => {
    dispatch({
      type: 'SEARCH_LIST_HIDE_TABLE_CONFIRM'
    });
  },
  doDiscardSearch: (id) => async (dispatch) => {
    dispatch({
      type: 'SEARCH_DISCARD_CONFIRM'
    });
  },
  doToggleDiscardSearch: () => async (dispatch) => {
    dispatch({
      type: 'SEARCH_DISCARD_TOGGLE'
    });
  },
  doFetchTreeNodes: (legalCaseId, loggedUser) => async (dispatch) => {
    try {
      dispatch({
        type: 'TREE_NODES_FETCH_STARTED'
      });

      const response = await getTreeNodes(legalCaseId, loggedUser);
      dispatch({
        type: 'TREE_NODES_FETCH_SUCCESS',
        payload: response
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'TREE_NODES_FETCH_ERROR'
      });
    }
  }
};

export default actions;
