import Errors from 'components/FormItems/error/errors';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import config from '../../config';
import credentialListActions from './CredentialListActions';
import { defaultOptions } from '../../services/userService';

async function doCreateCredential(data, expireonUser) {
  const formData = new FormData();
  const allData = {
    ...data,
    credentialType: parseInt(data.credentialType)
  };
  Object.keys(allData).forEach((key) => {
    if (allData[key] === undefined || allData[key] === null || allData[key] === '') {
      delete allData[key];
    }
  });
  const user = JSON.stringify(expireonUser?.expireonUser);
  for (const key in allData) {
    formData.append(key, allData[key]);
  }
  formData.append('expireonUser', user);
  const url = `${config.externalApi}/api/Credentials`;

  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: defaultOptions?.headers?.Authorization
    },
    body: formData
  };
  try {
    const response = await fetch(url, requestOptions);
  } catch (error) {
    console.error('Error:', error.message);
  }
}

async function doFindCredential(credentialId) {
  const response = await fetch(
    `${config.externalApi}/api/Credentials/credentialId?credentialId=${credentialId}`,
    {
      ...defaultOptions,
      method: 'GET'
    }
  );

  const credential = await response.json();
  return credential;
}

async function doUpdateCredential(credentialId, data, expireonUser) {
  const formData = new FormData();
  const allData = { ...data, credentialType: parseInt(data.credentialType) };
  const user = JSON.stringify(expireonUser?.expireonUser);
  Object.keys(allData).forEach((key) => {
    if (allData[key] === undefined || allData[key] === null || allData[key] === '') {
      delete allData[key];
    }
  });
  for (const key in allData) {
    formData.append(key, allData[key]);
  }
  formData.append('expireonUser', user);
  const url = `${config.externalApi}/api/Credentials/${credentialId}`;

  const requestOptions = {
    method: 'PUT',
    headers: {
      Authorization: defaultOptions?.headers?.Authorization
    },
    body: formData
  };
  try {
    const response = await fetch(url, requestOptions);
  } catch (error) {
    console.error('Error:', error.message);
  }
}

async function doCreateCertificate(data) {
  await fetch(`${config.externalApi}/api/Credentials/CreateTenantCertificate`, {
    ...defaultOptions,
    method: 'POST',
    body: JSON.stringify(data)
  });
}

async function getCertificatesList() {
  const response = await fetch(`${config.externalApi}/api/Credentials/GetCertificates`, {
    ...defaultOptions,
    method: 'GET'
  });

  const list = await response.json();
  return list;
}

const actions = {
  doNew: () => {
    return {
      type: 'CREDENTIAL_FORM_RESET'
    };
  },

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: 'CREDENTIAL_FORM_FIND_STARTED'
      });

      doFindCredential(id).then((res) => {
        dispatch({
          type: 'CREDENTIAL_FORM_FIND_SUCCESS',
          payload: res
        });
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'CREDENTIAL_FORM_FIND_ERROR'
      });
      dispatch(push('/admin/credentials'));
    }
  },

  doCreate:
    (values, expireonUser, pageNumber, pageSize, searchString, sortOrder) => async (dispatch) => {
      try {
        dispatch({
          type: 'CREDENTIAL_FORM_CREATE_STARTED'
        });

        doCreateCredential(values, expireonUser).then(async (res) => {
          dispatch({
            type: 'CREDENTIAL_FORM_CREATE_SUCCESS'
          });
          dispatch(
            credentialListActions.doFetch({
              pageNumber,
              pageSize,
              searchString,
              sortOrder
            })
          );
          dispatch(credentialListActions.doCloseConfirm());
          toast.success('Credential created');
        });
      } catch (error) {
        Errors.handle(error);
        dispatch({
          type: 'CREDENTIAL_FORM_CREATE_ERROR'
        });
      }
    },

  doUpdate:
    (id, values, expireonUser, pageNumber, pageSize, searchString, sortOrder) =>
    async (dispatch) => {
      try {
        dispatch({
          type: 'CREDENTIAL_FORM_UPDATE_STARTED'
        });

        await doUpdateCredential(id, values, expireonUser);
        dispatch({
          type: 'CREDENTIAL_FORM_UPDATE_SUCCESS'
        });

        dispatch(
          credentialListActions.doFetch({
            pageNumber,
            pageSize,
            searchString,
            sortOrder
          })
        );
        dispatch(credentialListActions.doCloseConfirm());
        toast.success('Credential updated');
      } catch (error) {
        Errors.handle(error);
        dispatch({
          type: 'CREDENTIAL_FORM_UPDATE_ERROR'
        });
      }
    },
  doCreateCertificate:
    (values, pageNumber, pageSize, searchString, sortOrder) => async (dispatch) => {
      try {
        dispatch({
          type: 'CERTIFICATE_FORM_CREATE_STARTED'
        });

        doCreateCertificate(values).then(async (res) => {
          dispatch({
            type: 'CERTIFICATE_FORM_CREATE_SUCCESS'
          });
          dispatch(
            credentialListActions.doGetCertificatesList({
              pageNumber,
              pageSize,
              searchString,
              sortOrder
            })
          );
          dispatch(credentialListActions.doCloseKeyModal());
          toast.success('New Certificate created');
        });
      } catch (error) {
        Errors.handle(error);
        dispatch({
          type: 'CERTIFICATE_FORM_CREATE_ERROR'
        });
      }
    },
  doGetCertificatesList: () => async (dispatch) => {
    try {
      dispatch({
        type: 'GET_CERTIFICATES_LIST_STARTED'
      });

      getCertificatesList().then((res) => {
        dispatch({
          type: 'GET_CERTIFICATES_LIST_SUCCESS',
          payload: res
        });
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'GET_CERTIFICATES_LIST_ERROR'
      });
    }
  }
};

export default actions;
