import React from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { Switch, Route, Redirect } from 'react-router';
import { HashRouter } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { getHistory, store } from './index';
import 'styles/theme.scss';
import ErrorPage from 'pages/error';
import LayoutComponent from 'components/Layout';
import DocumentationLayoutComponent from 'documentation/DocumentationLayout';
import Login from 'pages/auth/login';
import SigninOidc from 'pages/auth/login/SigninOidc';
import SignoutOidc from 'pages/auth/login/SignoutOidc';
import PermissionDenied from 'routes/PermissionDenied';
import PrivateRoute from 'routes/PrivateRoute';
import { loadUserFromStorage, refreshAuthToken } from 'services/userService';
import { getConfigFromApi } from 'services/apiService';
import Loader from './components/Loader/Loader';
import { getCurrentUser, loginSuccessfull } from 'actions/auth';
import NoAccessPage from 'routes/NoAccessPage';
import holdsActions from 'actions/LegalHold/LegalHoldListActions';
import 'styles/themeStyles.scss';
import closeIcon from './images/icons/close.svg';

const CloseButton = ({ closeToast }) => (
  <img src={closeIcon} onClick={closeToast} alt={'close'} width={'15px'} height={'15px'} />
);

class App extends React.PureComponent {
  constructor() {
    super();
    this.refreshToken();
  }

  refreshToken = async () => {
    try {
      const newToken = refreshAuthToken();
      this.refreshTokenTimeout = setTimeout(this.refreshToken, 5 * 60 * 1000);
    } catch (error) {
      console.error('Error refreshing token:', error);
    }
  };

  componentDidMount() {
    async function getUser() {
      await loadUserFromStorage(store);
      let configData = await getConfigFromApi();
      let currentUser = configData.currentUser;
      let permissions = configData.auth.grantedPolicies;
      store.dispatch(getCurrentUser(currentUser, permissions));
    }
    getUser();
  }
  componentDidUpdate(prevProps) {
    const currentUser = this.props?.currentUser;
    if (prevProps.currentUser !== currentUser && currentUser?.userName) {
      this.props.dispatch(holdsActions.doGetRefreshInterval(currentUser?.userName));
    }
    if (currentUser?.isAuthenticated) {
      loginSuccessfull();
    }
  }
  render() {
    return (
      <div>
        <ToastContainer autoClose={5000} hideProgressBar closeButton={<CloseButton />} />
        <ConnectedRouter history={getHistory()}>
          <HashRouter>
            {this.props.currentUser ? (
              <Switch>
                <Route
                  path='/documentation'
                  exact
                  render={() => <Redirect to='/documentation/getting-started/overview' />}
                />
                <Route path='/documentation' component={DocumentationLayoutComponent} />
                <Route exact path='/' render={() => <Redirect to='/app/Dashboard' />} />
                <Route path='/login' exact component={Login} />
                <Route path='/signin-oidc' component={SigninOidc} />
                <Route path='/signout-oidc' component={SignoutOidc} />
                <PrivateRoute
                  path='/app'
                  component={
                    this.props.permissions['LegalUI.Credential'] &&
                    this.props.permissions['LegalUI.Credential.KeyManagement'] &&
                    Object.keys(this.props.permissions).length === 2
                      ? NoAccessPage
                      : LayoutComponent
                  }
                />
                <PrivateRoute path='/admin' component={LayoutComponent} />
                <Route path='/denied' exact component={PermissionDenied} />
                <Route path='/no-access' exact component={NoAccessPage} />
                <Route path='/error' exact component={ErrorPage} />
                <Route render={() => <Redirect to='/' />} />
              </Switch>
            ) : (
              <Loader />
            )}
          </HashRouter>
        </ConnectedRouter>
      </div>
    );
  }
}

function mapStateToProps(store) {
  return {
    currentUser: store.auth.currentUser,
    loadingInit: store.auth.loadingInit,
    permissions: store.auth.permissions
  };
}

export default connect(mapStateToProps)(App);
