import React, { Component } from 'react';
import Loader from 'components/Loader';
import Widget from 'components/Widget';
import {
  Button,
  Card,
  CardBody,
  Col,
  Collapse,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from 'reactstrap';
import personIcon from '../../../../images/icons/person.svg';
import styles from '../../LegalCase/list/styles.module.scss';
import { FormattingService } from '../../../../utils/sizeFormatting';
import cx from 'classnames';
import ContentSearchItemContent from '../../Search/itemContent/ItemContentPage';
import actions from '../../../../actions/search/searchFormActions';
import listActions, { getPageRangeDisplayed } from '../../../../actions/search/searchListActions';
import { connect } from 'react-redux';
import viewIcon from '../../../../images/icons/eye.svg';
import BulkSelectionFormPage from '../bulkSelection/BulkSelectionFormPage';
import attachmentIcon from '../../../../images/attachment.svg';
import SelectFormItem from '../../../FormItems/items/SelectFormItem';
import searchFilterFields from './searchFilterFields';
import { Formik } from 'formik';
import IniValues from '../../../FormItems/iniValues';
import FormValidations from '../../../FormItems/formValidations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import PreparedValues from '../../../FormItems/preparedValues';
import Pagination from 'react-js-pagination';
import paginationStyles from 'components/CRUD/LegalHold/list/LegalHoldList.module.scss';
import { toast } from 'react-toastify';
import { MESSAGES } from '../../../../actions/common';
import s from 'components/CRUD/Search/itemContent/styles.module.scss';
// import SearchTreeView from "./SearchTreeView";
import caretDown from 'images/icons/caret-down.svg';
import * as dataFormat from '../list/SearchDataFormatters';

class SearchView extends Component {
  constructor(props) {
    super(props);
    this.textRef = React.createRef();
  }

  state = {
    widget: [],
    searchString: '',
    modalOpen: false,
    isExcluded: false,
    items: [],
    itemsWithAttachment: [],
    isDropdownShown: false,
    resetFilter: false,
    tagList: [],
    totalCount: 0,
    pageNumber: 1,
    lastActivePageNumber: 1,
    pageSize: 10,
    paginationModalOpen: false,
    legalHolds: [],
    isExcludedFilter: null,
    hasAttachments: null,
    useTags: null,
    attachmentTypes: undefined,
    legalHoldList: [],
    disableAttachmentTypes: true,
    disableTagTypes: true,
    singleTags: [],
    allTagGroups: [],
    infoTags: [],
    tagGroups: {}
  };

  contentSearchItemHandle(widgetNum, contentSearchId, item, infoTags, tagGroups) {
    this.setState((prevState) => ({
      ...prevState,
      contentSearchId,
      widget: [widgetNum],
      isExcluded: item.isExcluded,
      infoTags,
      tagGroups
    }));
  }

  contentSearchItemReset() {
    this.setState((prevState) => ({
      ...prevState,
      contentSearchId: null,
      widget: [],
      isExcluded: false,
      infoTags: [],
      tagGroups: {}
    }));
  }

  toggleModal = () => {
    this.setState({
      modalOpen: !this.state.modalOpen
    });
  };

  showDropdown = () => {
    this.setState({
      isDropdownShown: !this.state.isDropdownShown
    });
  };

  iniValues = () => {
    const record = {
      ...this.props.record,
      isExcluded: 'null',
      hasAttachments: 'null',
      useTags: 'null'
    };
    return IniValues(searchFilterFields, record || {});
  };

  formValidations = () => {
    return FormValidations(searchFilterFields, this.props.record || {});
  };

  componentDidMount() {
    const { dispatch, match } = this.props;
    const { name: firstName, surName: lastName, email, userName } = this.props.currentUser || {};
    dispatch(listActions.doFetchTreeNodes(match.params.caseId, this.props.currentUser?.userName));
    dispatch(
      actions.doFind(
        match.params.id,
        match.params.caseId,
        undefined,
        this.state.pageSize,
        this.state.pageNumber
      )
    ).then((res) => {
      dispatch(
        actions.doFindSearchItems({
          legalCaseId: match.params.caseId,
          contentSearchId: match.params.id,
          pageSize: this.state.pageSize,
          page: this.state.pageNumber,
          expireonUser: {
            firstName,
            lastName,
            email,
            userName
          },
          subject: this.props.contentSearch?.queryString?.subject,
          from: this.props.contentSearch?.queryString?.from,
          to: this.props.contentSearch?.queryString?.to,
          cc: this.props.contentSearch?.queryString?.cc,
          bcc: this.props.contentSearch?.queryString?.bcc,
          messageId: this.props.contentSearch?.queryString?.messageId,
          recipients: this.props.contentSearch?.queryString?.recipients,
          isSplitField: this.props.contentSearch?.queryString?.isSplitField
        })
      );
    });
    this.setState({
      totalCount: this.props?.count
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { dispatch, match } = this.props;
    const { name: firstName, surName: lastName, email, userName } = this.props.currentUser || {};
    if (prevProps.items !== this.props.items) {
      this.props.dispatch(actions.doAddIconToItem(this.props.items));
    }
    if (prevProps.count !== this.props.count) {
      this.setState({
        totalCount: this.props?.count
      });
    }
    if (prevProps.contentSearch !== this.props.contentSearch) {
      this.setState({
        legalHoldList: this.props?.contentSearch?.legalHoldNames
      });
      dispatch(
        actions.doFindSearchItems({
          legalCaseId: match.params.caseId,
          contentSearchId: match.params.id,
          pageSize: this.state.pageSize,
          page: this.state.pageNumber,
          expireonUser: {
            firstName,
            lastName,
            email,
            userName
          },
          subject: this.props.contentSearch?.queryString?.subject,
          from: this.props.contentSearch?.queryString?.from,
          to: this.props.contentSearch?.queryString?.to,
          cc: this.props.contentSearch?.queryString?.cc,
          bcc: this.props.contentSearch?.queryString?.bcc,
          messageId: this.props.contentSearch?.queryString?.messageId,
          recipients: this.props.contentSearch?.queryString?.recipients,
          isSplitField: this.props.contentSearch?.queryString?.isSplitField
        })
      );
    }
    if (prevState.resetFilter !== this.state.resetFilter) {
      this.setState({
        isExcludedFilter: null,
        hasAttachments: null,
        useTags: null,
        attachmentTypes: undefined,
        pageNumber: 1,
        disableAttachmentTypes: true,
        disableTagTypes: true,
        tagsTypes: undefined
      });
      dispatch(
        actions.doFindSearchItems({
          legalCaseId: match.params.caseId,
          contentSearchId: match.params.id,
          pageSize: this.state.pageSize,
          page: 1,
          searchString: this.state.searchString,
          isExcluded: null,
          hasAttachments: null,
          useTags: null,
          expireonUser: {
            firstName,
            lastName,
            email,
            userName
          },
          subject: this.props.contentSearch?.queryString?.subject,
          from: this.props.contentSearch?.queryString?.from,
          to: this.props.contentSearch?.queryString?.to,
          cc: this.props.contentSearch?.queryString?.cc,
          bcc: this.props.contentSearch?.queryString?.bcc,
          messageId: this.props.contentSearch?.queryString?.messageId,
          recipients: this.props.contentSearch?.queryString?.recipients,
          isSplitField: this.props.contentSearch?.queryString?.isSplitField
        })
      );
    }
    if (prevState.searchString !== this.state.searchString && this.state.searchString === '') {
      this.setState({ pageNumber: 1 });
      let allTags;
      if (this.state.tagsTypes) {
        allTags = this.formatTagTypes(this.state.tagsTypes);
      }
      const formattedValues = {
        legalCaseId: this.props.legalCaseId,
        contentSearchId: this.props.contentSearchId,
        searchString: this.state.searchString,
        pageSize: this.state.pageSize,
        page: 1,
        attachmentTypes: this.state.attachmentTypes,
        isExcluded: JSON.parse(this.state.isExcludedFilter),
        hasAttachments: JSON.parse(this.state.hasAttachments),
        useTags: JSON.parse(this.state.useTags),
        expireonUser: {
          firstName,
          lastName,
          email,
          userName
        },
        groupIds: allTags?.groupIds?.length !== 0 ? allTags?.groupIds : undefined,
        tagIds: allTags?.tagIds?.length !== 0 ? allTags?.tagIds : undefined,
        subject: this.props.contentSearch?.queryString?.subject,
        from: this.props.contentSearch?.queryString?.from,
        to: this.props.contentSearch?.queryString?.to,
        cc: this.props.contentSearch?.queryString?.cc,
        bcc: this.props.contentSearch?.queryString?.bcc,
        messageId: this.props.contentSearch?.queryString?.messageId,
        recipients: this.props.contentSearch?.queryString?.recipients,
        isSplitField: this.props.contentSearch?.queryString?.isSplitField
      };
      dispatch(actions.doFindSearchItems(formattedValues));
      this.contentSearchItemReset();
    }
    if (
      prevState.pageNumber !== this.state.pageNumber ||
      prevState.pageSize !== this.state.pageSize
    ) {
      let allTags;
      if (this.state.tagsTypes) {
        allTags = this.formatTagTypes(this.state.tagsTypes);
      }
      dispatch(
        actions.doFindSearchItems({
          legalCaseId: match.params.caseId,
          contentSearchId: match.params.id,
          pageSize: this.state.pageSize,
          page: this.state.pageNumber,
          searchString: this.state.searchString,
          attachmentTypes: this.state.attachmentTypes,
          isExcluded: JSON.parse(this.state.isExcludedFilter),
          hasAttachments: JSON.parse(this.state.hasAttachments),
          useTags: JSON.parse(this.state.useTags),
          expireonUser: {
            firstName,
            lastName,
            email,
            userName
          },
          groupIds: allTags?.groupIds?.length !== 0 ? allTags?.groupIds : undefined,
          tagIds: allTags?.tagIds?.length !== 0 ? allTags?.tagIds : undefined,
          subject: this.props.contentSearch?.queryString?.subject,
          from: this.props.contentSearch?.queryString?.from,
          to: this.props.contentSearch?.queryString?.to,
          cc: this.props.contentSearch?.queryString?.cc,
          bcc: this.props.contentSearch?.queryString?.bcc,
          messageId: this.props.contentSearch?.queryString?.messageId,
          recipients: this.props.contentSearch?.queryString?.recipients,
          isSplitField: this.props.contentSearch?.queryString?.isSplitField
        })
      );
      if (this.props.limitReached) {
        this.setState({ lastActivePageNumber: this.state.pageNumber });
      }
    }
    if (prevState.pageNumber !== this.state.pageNumber) {
      this.contentSearchItemReset();
    }
    if (prevState.pageSize !== this.state.pageSize) {
      const itemIndex = this.state.widget[0];
      if (itemIndex + 1 > this.state.pageSize) {
        this.contentSearchItemReset();
      }
    }
    if (
      prevProps.allTags !== this.props.allTags ||
      prevProps.allTagGroups !== this.props.allTagGroups
    ) {
      this.setState({
        singleTags: this.props?.allTags,
        allTagGroups: this.props?.allTagGroups
      });
    }
  }

  componentWillUnmount() {
    this.props.dispatch(actions.doResetItemIcons());
  }

  tagsFormatter(singleTagsList, tagGroupsList) {
    let tagGroups = [];
    let singleTags = [];
    let allTags = [];
    singleTagsList?.map((item) => {
      let oneItem = { value: item.key, label: item.displayName, isTagGroup: false };
      singleTags.push(oneItem);
    });
    tagGroupsList?.map((item) => {
      let oneGroup = { value: item.id, label: item.name, isTagGroup: true };
      tagGroups.push(oneGroup);
    });
    allTags = tagGroups.concat(singleTags);
    return allTags;
  }

  showExcludedItems = () => {
    if (this.state.items.length) {
      this.setState({
        items: []
      });
      return;
    }
    this.setState({
      items: this.props.items.filter(
        (item) => item.isExcluded || this.props.itemIcon.includes(item.id)
      )
    });
  };

  showAttachmentItems = () => {
    if (this.state.itemsWithAttachment.length) {
      this.setState({
        itemsWithAttachment: []
      });
      return;
    }

    let result = this.props.items.filter(
      (item) => item.hasAttachments || this.props.itemIcon.includes(item.id)
    );

    if (this.state.items.length) {
      result = [...result, ...this.state.items];
    }

    this.setState({
      itemsWithAttachment: result
    });
  };

  toData = (data) => {
    const arr = [];
    if (typeof data === 'string') {
      return data;
    }
    if (Array.isArray(data)) {
      for (let key in data) {
        const obj = {};
        obj.value = data[key];
        obj.label = data[key];
        arr.push(obj);
      }
    } else {
      for (let key in data) {
        const obj = {};
        obj.value = key;
        obj.label = data[key];
        arr.push(obj);
      }
    }
    return arr;
  };

  validateAttachmentType = (value) => {
    if (!value) {
      return undefined;
    }
    let error;
    if ((value.includes('All') || value.includes('Others')) && value.length > 1) {
      error = 'All/Others can be used only as exclusive values';
    }
    return error;
  };

  validateAttachment = (value) => {
    if (value === 'true') {
      this.setState({
        disableAttachmentTypes: false
      });
    } else {
      this.setState({
        disableAttachmentTypes: true
      });
    }
  };

  validateTags = (value) => {
    if (value === 'true') {
      this.setState({
        disableTagTypes: false
      });
    } else {
      this.setState({
        disableTagTypes: true
      });
    }
  };

  formatTagTypes = (selectedTags) => {
    let tagIds = [];
    let groupIds = [];
    selectedTags?.map((item, index) => {
      let isGroup = false;
      isGroup = this.state.allTagGroups?.find((tag) => tag.id === item);
      if (isGroup) {
        groupIds.push(item);
      } else {
        tagIds.push(item);
      }
    });
    const allTags = {
      tagIds,
      groupIds
    };
    return allTags;
  };

  handleSubmit = (values) => {
    const { id, ...data } = PreparedValues(searchFilterFields, values || {});
    const { name: firstName, surName: lastName, email, userName } = this.props.currentUser || {};
    this.setState({
      hasAttachments: values.hasAttachments,
      isExcludedFilter: values.isExcluded,
      attachmentTypes: values.attachmentTypes,
      pageNumber: 1,
      useTags: values.useTags,
      tagsTypes: values?.tagsTypes
    });
    this.contentSearchItemReset();
    let allTags;
    if (values?.tagsTypes) {
      allTags = this.formatTagTypes(values?.tagsTypes);
    }
    const formattedValues = {
      ...values,
      searchString: this.state.searchString,
      page: 1,
      pageSize: this.state.pageSize,
      attachmentTypes:
        this.state.disableAttachmentTypes === true ? undefined : values.attachmentTypes,
      expireonUser: {
        firstName,
        lastName,
        email,
        userName
      },
      groupIds:
        allTags?.groupIds?.length !== 0 && !this.state.disableTagTypes
          ? allTags?.groupIds
          : undefined,
      tagIds:
        allTags?.tagIds?.length !== 0 && !this.state.disableTagTypes ? allTags?.tagIds : undefined,
      tagsTypes: undefined,
      subject: this.props.contentSearch?.queryString?.subject,
      from: this.props.contentSearch?.queryString?.from,
      to: this.props.contentSearch?.queryString?.to,
      cc: this.props.contentSearch?.queryString?.cc,
      bcc: this.props.contentSearch?.queryString?.bcc,
      messageId: this.props.contentSearch?.queryString?.messageId,
      recipients: this.props.contentSearch?.queryString?.recipients,
      isSplitField: this.props.contentSearch?.queryString?.isSplitField
    };
    for (let x in formattedValues) {
      if (x === 'isExcluded' || x === 'hasAttachments' || x === 'useTags') {
        formattedValues[x] = JSON.parse(formattedValues[x]);
      } else if (!formattedValues[x]) {
        formattedValues[x] = null;
      }
    }
    this.props.dispatch(actions.doFindSearchItems(formattedValues));
  };

  handleSearch = () => {
    const { name: firstName, surName: lastName, email, userName } = this.props.currentUser || {};
    this.setState({
      pageNumber: 1
    });
    let allTags;
    if (this.state.tagsTypes) {
      allTags = this.formatTagTypes(this.state.tagsTypes);
    }
    this.contentSearchItemReset();
    const formattedValues = {
      legalCaseId: this.props.legalCaseId,
      contentSearchId: this.props.contentSearchId,
      searchString: this.state.searchString,
      pageSize: this.state.pageSize,
      page: 1,
      attachmentTypes: this.state.attachmentTypes,
      isExcluded: JSON.parse(this.state.isExcludedFilter),
      hasAttachments: JSON.parse(this.state.hasAttachments),
      useTags: JSON.parse(this.state.useTags),
      expireonUser: {
        firstName,
        lastName,
        email,
        userName
      },
      groupIds: allTags?.groupIds?.length !== 0 ? allTags?.groupIds : undefined,
      tagIds: allTags?.tagIds?.length !== 0 ? allTags?.tagIds : undefined,
      tagsTypes: undefined,
      subject: this.props.contentSearch?.queryString?.subject,
      from: this.props.contentSearch?.queryString?.from,
      to: this.props.contentSearch?.queryString?.to,
      cc: this.props.contentSearch?.queryString?.cc,
      bcc: this.props.contentSearch?.queryString?.bcc,
      messageId: this.props.contentSearch?.queryString?.messageId,
      recipients: this.props.contentSearch?.queryString?.recipients,
      isSplitField: this.props.contentSearch?.queryString?.isSplitField
    };
    this.props.dispatch(actions.doFindSearchItems(formattedValues));
  };

  resetHandler = () => {
    this.setState({
      resetFilter: !this.state.resetFilter,
      searchString: '',
      pageNumber: 1,
      hasAttachments: null,
      isExcludedFilter: null,
      attachmentTypes: undefined,
      useTags: null,
      tagsTypes: undefined
    });
    this.contentSearchItemReset();
  };

  handlePageChange = (pageNumber) => {
    if (this.props.limitReached) {
      this.setState({ pageNumber: this.state.lastActivePageNumber });
      if (this.state.pageNumber === pageNumber) {
        toast.error(MESSAGES.LIMIT_REACHED);
      }
    } else {
      this.setState({ lastActivePageNumber: this.state.pageNumber });
    }
    this.setState({ pageNumber: pageNumber });
  };

  handleCopyClick = async () => {
    try {
      const textToCopy = this.textRef.current.textContent;
      await navigator.clipboard.writeText(textToCopy);
      toast.success('Query copied to clipboard');
    } catch (error) {
      console.error('Failed to copy text to clipboard:', error);
    }
  };

  renderView() {
    const { record } = this.props;

    const { saveLoading } = this.props;

    const toReadableSizeFormat = new FormattingService();

    return (
      <>
        <Row
          onClick={() => {
            this.props.dispatch(actions.doCloseTagCard());
          }}
        >
          <Col sm={4}>
            <Widget
              style={{ height: 150 }}
              bodyClass={'h-100 d-flex flex-column justify-content-center'}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <div className={s.searchNameContainer}>
                  <p className={s.searchNameHeader}>Content search:</p>
                  <p className={s.searchName}>{this.props.record.name}</p>
                </div>

                {/* <button
                  className="btn btn-info fw-semi-bold d-flex align-items-center mb-3"
                  type="button"
                  onClick={this.toggleModal}
                >
                  <img src={viewIcon} alt={"plus"} className={"me-2"} />
                  <p className={`${s.btnFont} mb-0`}>Exclusions</p>
                </button> */}
              </div>
              <Row
                onClick={() => {
                  this.props.dispatch(actions.doCloseTagCard());
                }}
              >
                <Col sm={6}>
                  <Widget
                    style={{ height: 80 }}
                    className={'mb-0'}
                    bodyClass={'h-100 d-flex flex-column justify-content-center'}
                    fetchingData={this.props.findStatsLoading}
                  >
                    {this.props.record?.excludedItemCount ? (
                      <>
                        <h6 className={`${s.headerFont} text-center mb-1 mt-0 fw-normal`}>
                          Total Items / Excluded Items
                        </h6>
                        <div className={`${s.headerFont} text-center`}>
                          <h6 className={'fw-bold d-inline-block mb-0'}>
                            {toReadableSizeFormat.numberWithCommas(
                              this.props.record?.itemCount || 0
                            )}
                            /
                            {toReadableSizeFormat.numberWithCommas(
                              this.props.record?.excludedItemCount || 0
                            )}
                          </h6>
                        </div>
                      </>
                    ) : (
                      <>
                        <h6 className={`${s.headerFont} text-center mb-2 mt-0 fw-normal`}>
                          Items Count
                        </h6>
                        <div className={'text-center'}>
                          <h5 className={`${s.headerFont} fw-bold d-inline-block mb-0 mt-0`}>
                            {toReadableSizeFormat.numberWithCommas(
                              this.props.record?.itemCount || 0
                            )}
                          </h5>
                        </div>
                      </>
                    )}
                  </Widget>
                </Col>
                <Col sm={6}>
                  <Widget
                    style={{ height: 80 }}
                    className={'mb-0'}
                    bodyClass={'h-100 d-flex flex-column justify-content-center'}
                    fetchingData={this.props.findStatsLoading}
                  >
                    {this.props.record?.excludedItemSize ? (
                      <>
                        <h6 className={`${s.headerFont} text-center mb-1 mt-0 fw-normal`}>
                          Total Size / Excluded Size
                        </h6>
                        <div className={'text-center'}>
                          <h6 className={`${s.headerFont} fw-bold d-inline-block mb-0 mt-0`}>
                            {toReadableSizeFormat.getBytesReadable(this.props.record.itemSize || 0)}
                            /
                            {toReadableSizeFormat.getBytesReadable(
                              this.props.record.excludedItemSize || 0
                            )}
                          </h6>
                        </div>
                      </>
                    ) : (
                      <>
                        <h6 className={`${s.headerFont} text-center mb-2 mt-0 fw-normal`}>
                          Items Size
                        </h6>
                        <div className={'text-center'}>
                          <h5 className={`${s.headerFont} fw-bold d-inline-block mt-0`}>
                            {toReadableSizeFormat.getBytesReadable(this.props.record.itemSize || 0)}
                          </h5>
                        </div>
                      </>
                    )}
                  </Widget>
                </Col>
              </Row>
            </Widget>
          </Col>
          <Col sm={4}>
            <Widget style={{ height: 150 }} className={'mb-0'} bodyClass={'h-100'}>
              <Row>
                <Col>
                  <h5 className={`${s.headerFont} mb-1 mt-0 fw-semi-bold`}>Query:</h5>
                </Col>
              </Row>

              <Row
                style={{
                  maxHeight: '60px'
                }}
                className={'h-100 d-flex flex-column justify-content-center'}
              >
                <Col className={'h-100 d-flex flex-column justify-content-center'}>
                  <h6
                    className={`${s.headerFont} ${s.queryContainer} h-100 d-flex text-center justify-content-center fw-normal mt-0 `}
                    ref={this.textRef}
                  >
                    {this.props.contentSearch?.queryString?.query}
                  </h6>
                </Col>
              </Row>
              <div className='d-flex justify-content-end mt-1 mb-2 me-2'>
                <button
                  type='button'
                  className='btn fw-semi-bold d-flex align-items-center'
                  onClick={() => this.handleCopyClick()}
                >
                  Copy
                </button>
              </div>
            </Widget>
          </Col>
          <Col sm={4}>
            <Widget style={{ height: 150 }} className={'mb-2'} bodyClass={'h-100'}>
              <Row>
                <Col>
                  <h5 className={`${s.headerFont} mt-0 mb-0 fw-semi-bold`}>Legal Holds:</h5>
                </Col>
              </Row>

              <Row className={'h-80 d-flex flex-column justify-content-center mb-2'}>
                <Col className={'mb-0,'}>
                  {this.state.legalHoldList?.length !== 0 && (
                    <div style={{ overflowY: 'auto', maxHeight: '80px', marginBottom: '5px' }}>
                      {this.state?.legalHoldList?.map((_item, index) => {
                        return (
                          <h6 className={`${s.headerFont} text-center fw-normal`} key={index}>
                            {_item}
                          </h6>
                        );
                      })}
                    </div>
                  )}
                </Col>
              </Row>
            </Widget>
          </Col>
        </Row>
        <Row
          onClick={() => {
            this.props.dispatch(actions.doCloseTagCard());
          }}
        >
          <Col sm={4} className={styles.widgetItemsList}>
            <div>
              <div className={'d-flex mb-3 '}>
                <input
                  type='search'
                  placeholder={'Search'}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      this.handleSearch();
                    }
                  }}
                  className={'form-control search-input me-2'}
                  value={this.state.searchString}
                  onChange={(e) => {
                    this.setState({
                      searchString: e.target.value
                    });
                  }}
                />
                <button
                  className='btn me-2'
                  disabled={saveLoading}
                  type='button'
                  onClick={this.handleSearch}
                >
                  Search
                </button>
                <Button
                  color={this.state.isDropdownShown ? 'primary' : 'dark'}
                  onClick={this.showDropdown}
                  className={'filters'}
                >
                  <FontAwesomeIcon icon={faFilter} />
                </Button>
              </div>
              <div className={'mb-3'}>
                <Collapse isOpen={this.state.isDropdownShown}>
                  <Card>
                    <CardBody>
                      <Formik
                        onSubmit={this.handleSubmit}
                        onReset={this.resetHandler}
                        initialValues={this.iniValues()}
                        render={(form) => {
                          return (
                            <form onSubmit={form.handleSubmit} className={'h-100'}>
                              <div className={'d-flex justify-content-between w-100'}>
                                <div style={{ minWidth: '28%' }} className={'me-2'}>
                                  <SelectFormItem name={'isExcluded'} schema={searchFilterFields} />
                                </div>
                                <div style={{ minWidth: '28%' }} className={'me-2'}>
                                  <SelectFormItem
                                    name={'hasAttachments'}
                                    schema={searchFilterFields}
                                    validate={this.validateAttachment}
                                  />
                                </div>
                                <div style={{ minWidth: '42%' }}>
                                  <SelectFormItem
                                    name={'attachmentTypes'}
                                    schema={searchFilterFields}
                                    mode={'fetch'}
                                    isMulti={true}
                                    isDisabled={this.state.disableAttachmentTypes}
                                    data={this.toData(this.props.attachmentTypes)}
                                    validate={this.validateAttachmentType}
                                    isAttachment={true}
                                  />
                                  {form?.errors?.attachmentTypes && (
                                    <p style={{ color: 'red', marginTop: -12 }}>
                                      {form?.errors?.attachmentTypes}
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className={'d-flex justify-content-between w-100'}>
                                <div style={{ minWidth: '36%' }} className={'me-3'}>
                                  <SelectFormItem
                                    name={'useTags'}
                                    schema={searchFilterFields}
                                    validate={this.validateTags}
                                  />
                                </div>
                                <div style={{ minWidth: '62%' }}>
                                  <SelectFormItem
                                    name={'tagsTypes'}
                                    schema={searchFilterFields}
                                    mode={'fetch'}
                                    isMulti={true}
                                    data={this.tagsFormatter(
                                      this.state.singleTags,
                                      this.state.allTagGroups
                                    )}
                                    isDisabled={this.state.disableTagTypes}
                                    tagGroupsList={this.state.allTagGroups}
                                    isTagType={true}
                                    lastGroup={
                                      this.state.allTagGroups[this.state.allTagGroups?.length - 1]
                                    }
                                    dispatch={this.props.dispatch}
                                    openTagCard={this.props.openTagCard}
                                  />
                                </div>
                              </div>
                              <div className={'d-flex'}>
                                <button
                                  className='btn w-100 me-2'
                                  disabled={saveLoading}
                                  type='button'
                                  onClick={form.handleSubmit}
                                  style={{ minWidth: '50%' }}
                                >
                                  Apply
                                </button>
                                <button
                                  className='btn w-100'
                                  type='button'
                                  style={{ minWidth: '50%' }}
                                  onClick={form.handleReset}
                                >
                                  Reset
                                </button>
                              </div>
                            </form>
                          );
                        }}
                      />
                    </CardBody>
                  </Card>
                </Collapse>
              </div>
            </div>
            {/* <div
             className={styles.mainContainer}
             > */}
            {/* <div style={{ minWidth: "35%", marginRight: "20px" }}>
                <SearchTreeView nodes={this.props?.nodesList} />
              </div> */}
            {/* <div
            className={styles.itemsList}
            > */}
            {this.props.findItemsLoading ? (
              <Loader />
            ) : (
              <div className={styles.searchItemsList}>
                {this.props.items?.map((item, index) => {
                  return (
                    <Widget
                      onClick={() =>
                        this.contentSearchItemHandle(
                          index,
                          item.id,
                          item,
                          item?.infoTags,
                          item?.tagGroups
                        )
                      }
                      className={cx({
                        [styles.activeWidget]: this.state.widget.includes(index)
                      })}
                      key={index}
                    >
                      <div className={'d-flex justify-content-between'}>
                        <div className={'d-flex flex-column'}>
                          <small
                            title={item.sender?.smtpAddress}
                            className={`${s.headerFont} pe-3`}
                          >
                            <img src={personIcon} className={'me-2'} alt={'person'} />
                            {item?.sender
                              ? item.sender?.smtpAddress.length > 40
                                ? `${item.sender?.smtpAddress.substring(0, 40)}...`
                                : item.sender?.smtpAddress
                              : 'Unknown'}
                          </small>
                          <h6
                            className={`${s.headerFont} fw-semi-bold mb-0`}
                            style={{ marginTop: 12 }}
                          >
                            {item.subject?.length > 40
                              ? item.subject?.slice(0, 30) + '...'
                              : item.subject}
                          </h6>
                        </div>
                        <div
                          className={`d-flex flex-column align-items-end ${
                            !item.isExcluded &&
                            !this.props.itemIcon.includes(item.id) &&
                            'justify-content-center'
                          }`}
                        >
                          {this.props.itemIcon.includes(item.id) && (
                            <>
                              <span
                                className={'close'}
                                aria-hidden='true'
                                style={{
                                  color: '#FF2D2E',
                                  textShadow: 'none'
                                }}
                              >
                                ×
                              </span>
                            </>
                          )}
                          <div className={'d-flex'}>
                            {item?.hasAttachments && (
                              <img
                                title={'Has Attachments'}
                                alt={'Has Attachments'}
                                width={16}
                                height={16}
                                src={attachmentIcon}
                                className={'me-2'}
                              />
                            )}
                            <small
                              className={'text-muted'}
                              style={{
                                marginTop: `${
                                  item?.isExcluded ||
                                  (this.props?.itemIcon?.includes(item.id) && 'auto')
                                }`
                              }}
                            >
                              {dataFormat.dateTimeFormatter(item?.itemDate)}
                            </small>
                          </div>
                        </div>
                      </div>
                      <p
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                          width: '100%',
                          fontSize: '14px'
                        }}
                        className={`${s.headerFont}`}
                      >
                        {item.messageClass ? item.messageClass : null}
                      </p>
                    </Widget>
                  );
                })}
              </div>
            )}
            {/* </div> */}
            {/* </div> */}
            <div className={styles.pagination}>
              <div>Total: {this.state.totalCount}</div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}
              >
                <div style={{ marginRight: '5px' }}>
                  {this.state.totalCount ? (
                    <Pagination
                      totalItemsCount={this.state.totalCount}
                      onChange={this.handlePageChange}
                      activePage={
                        this.props.limitReached
                          ? this.state.lastActivePageNumber
                          : this.state.pageNumber
                      }
                      itemsCountPerPage={this.state.pageSize}
                      pageRangeDisplayed={getPageRangeDisplayed(
                        this.props.limitReached
                          ? this.state.lastActivePageNumber
                          : this.state.pageNumber
                      )}
                      prevPageText={'<'}
                      nextPageText={'>'}
                      firstPageText={'<<'}
                      lastPageText={'>>'}
                      linkClassFirst={paginationStyles.linkNext}
                      linkClassPrev={paginationStyles.linkNext}
                      linkClassNext={paginationStyles.linkNext}
                      linkClassLast={paginationStyles.linkNext}
                      linkClass={paginationStyles.linkClass}
                      activeLinkClass={paginationStyles.activeLinkClass}
                      activeClass={paginationStyles.activeLinkClass}
                    />
                  ) : null}
                </div>
                <Dropdown
                  isOpen={this.state.paginationModalOpen}
                  toggle={() =>
                    this.setState({
                      paginationModalOpen: !this.state.paginationModalOpen
                    })
                  }
                  className={paginationStyles.dropdownClass}
                >
                  <DropdownToggle className='bg-transparent text-white d-flex'>
                    <p style={{ marginBottom: '3px', marginRight: '2px' }}>{this.state.pageSize}</p>
                    <img
                      src={caretDown}
                      alt={'uncollapsed'}
                      width='20px'
                      height='20px'
                      className='ms-2'
                    />
                  </DropdownToggle>
                  <DropdownMenu positionFixed={true}>
                    {[10, 15, 25].map((item) => (
                      <DropdownItem
                        className={paginationStyles.dropdownItem}
                        key={item}
                        onClick={() => {
                          this.setState({
                            pageSize: item,
                            paginationModalOpen: false,
                            pageNumber: 1
                          });
                        }}
                      >
                        {item}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
          </Col>
          <Col sm={8} style={{ height: '100vh' }}>
            <Widget
              className={`h-100 d-flex justify-content-center ${styles.emailWidget}`}
              bodyClass={'h-100'}
              style={{ overflow: 'auto' }}
            >
              {!this.state.contentSearchId ? (
                <div
                  className={'d-flex flex-column justify-content-center align-items-center'}
                  style={{ height: '100%' }}
                >
                  <h3>No data to display</h3>
                  <h3>Select Search Item to see Statistics</h3>
                </div>
              ) : (
                <ContentSearchItemContent
                  legalCaseId={this.props.legalCaseId}
                  itemId={this.state.contentSearchId}
                  contentSearchId={this.props.contentSearchId}
                  isExcluded={this.state.isExcluded}
                  infoTags={this.state.infoTags}
                  tagGroups={this.state.tagGroups}
                />
              )}
            </Widget>
          </Col>
          <Modal
            size='lg'
            isOpen={this.state.modalOpen}
            toggle={() => this.toggleModal()}
            className={`themeStyle${this.props.currentTheme?.replace('#', '')}`}
          >
            <ModalHeader toggle={() => this.toggleModal()} className={'fw-bold'}>
              Bulk
            </ModalHeader>
            <ModalBody>
              <BulkSelectionFormPage
                match={this.props.match}
                dispatch={this.props.dispatch}
                items={this.props?.items}
                contentSearchId={this.props.contentSearchId}
                legalCaseId={this.props.legalCaseId}
              />
            </ModalBody>
          </Modal>
        </Row>
      </>
    );
  }

  render() {
    const { record, findLoading } = this.props;

    if (findLoading || !record) {
      return <Loader />;
    }

    return this.renderView();
  }
}

function mapStateToProps(store) {
  return {
    findLoading: store.search.form.findLoading,
    record: store.search.form.record,
    items: store.search.form.rows,
    limitReached: store.search.form.limitReached,
    itemIcon: store.search.form.addIconToItems,
    findStatsLoading: store.search.form.findStatsLoading,
    count: store.search.form.count,
    contentSearch: store.search.form.contentSearch,
    findItemsLoading: store.search.form.findItemsLoading,
    currentUser: store.auth.currentUser,
    currentTheme: store.layout.currentTheme,
    allTags: store.search.form.allTags,
    allTagGroups: store.search.form.allTagGroups,
    openTagCard: store.search.form.openTagCard,
    nodesList: store.search.list.nodesList
  };
}

export default connect(mapStateToProps)(SearchView);
