import React, { Component } from 'react';
import Audit from './Audit';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

class AuditPage extends Component {
  render() {
    return (
      <>
        <div>
          {this.props.permissions['LegalUI.Audit'] ? <Audit /> : <Redirect to={'/no-access'} />}
        </div>
      </>
    );
  }
}

function mapStateToProps(store) {
  return {
    permissions: store.auth.permissions
  };
}
export default connect(mapStateToProps)(AuditPage);
