import { Formik } from 'formik';
import React, { Component } from 'react';
import Loader from 'components/Loader';
import { Row, Col } from 'reactstrap';
import InputFormItem from 'components/FormItems/items/InputFormItem';
import TextAreaFormItem from 'components/FormItems/items/TextAreaFormItem';
import LegalCaseFields from 'components/CRUD/LegalCase/LegalCaseFields';
import IniValues from 'components/FormItems/iniValues';
import PreparedValues from 'components/FormItems/preparedValues';
import FormValidations from 'components/FormItems/formValidations';

class LegalCaseForm extends Component {
  iniValues = () => {
    return IniValues(LegalCaseFields, this.props.record || {});
  };

  formValidations = () => {
    return FormValidations(LegalCaseFields, this.props.record || {});
  };

  handleSubmit = (values) => {
    const { id, ...data } = PreparedValues(LegalCaseFields, values || {});
    this.props.onSubmit(data);
  };

  renderForm() {
    const { saveLoading } = this.props;
    return (
      <>
        <Formik
          onSubmit={this.handleSubmit}
          initialValues={this.iniValues()}
          validationSchema={this.formValidations()}
          render={(form) => {
            return (
              <Row>
                <Col sm={12}>
                  <form onSubmit={form.handleSubmit}>
                    <InputFormItem name={'caseName'} schema={LegalCaseFields} autoFocus />
                    <InputFormItem name={'caseReferenceId'} schema={LegalCaseFields} />
                    {this.props.saveLoading ? <Loader size={40} /> : null}
                    <TextAreaFormItem
                      name={'caseDescription'}
                      schema={LegalCaseFields}
                      textAreaStyle={{ height: 130 }}
                    />
                    <div className='form-buttons' style={{ marginTop: 25 }}>
                      <button
                        className='btn w-100 fw-bold'
                        disabled={saveLoading}
                        type='button'
                        onClick={form.handleSubmit}
                      >
                        {this.props.isEditing ? 'Save Case Information' : 'Add Legal Case'}
                      </button>
                    </div>
                  </form>
                </Col>
              </Row>
            );
          }}
        />
      </>
    );
  }

  render() {
    const { isEditing, findLoading, record } = this.props;

    if (findLoading) {
      return <Loader />;
    }

    if (isEditing && !record) {
      return <Loader />;
    }

    return this.renderForm();
  }
}

export default LegalCaseForm;
