import Errors from 'components/FormItems/error/errors';
import config from '../../config';
import { Client } from '../../legal-api';
import { toast } from 'react-toastify';
import { store } from 'index';
import { downloadService } from 'services/downloadService';
import { defaultOptions } from '../../services/userService';
import { isLegalAdmin } from 'utils/isLegalAdmin';

async function list(legalCaseId, searchString, pageNumber, pageSize, sortOrder) {
  const currentUser = store.getState().auth.currentUser;
  const legalAdmin = isLegalAdmin(currentUser);
  const loggedUser = currentUser?.userName;
  const legalHolds = await new Client(config.externalApi).legalHoldAll(
    legalCaseId,
    sortOrder,
    undefined,
    searchString,
    pageNumber,
    pageSize,
    loggedUser,
    legalAdmin
  );
  return legalHolds;
}

async function getRefreshInterval(userName) {
  const response = await fetch(
    `${config.externalApi}/api/Widget/GetIntervalExpireonUser?expireonUser=${userName}`,
    {
      ...defaultOptions
    }
  );
  if (response.status == 204) {
    return {};
  }
  return response.json();
}

async function allowIndexing(legalHoldId, currentUser) {
  const { name: firstName, surName: lastName, email, userName } = currentUser || {};
  const expireonUser = { firstName, lastName, email, userName };

  await fetch(`${config.externalApi}/legal/api/LegalHold/${legalHoldId}/AllowIndex`, {
    method: 'PUT',
    headers: defaultOptions.headers,
    body: JSON.stringify(expireonUser)
  });
}

async function resetQueuedItems(legalHoldId, currentUser) {
  const { name: firstName, surName: lastName, email, userName } = currentUser || {};
  const expireonUser = { firstName, lastName, email, userName };

  await fetch(`${config.externalApi}/legal/api/LegalHold/${legalHoldId}/ResetQueued`, {
    method: 'PUT',
    headers: defaultOptions?.headers,
    body: JSON.stringify(expireonUser)
  });
}

async function exportToCSV(downloadUrl, currentUser, legalCaseId, searchString, columnHeaders) {
  const { name: firstName, surName: lastName, email, userName } = currentUser || {};
  const data = { firstName, lastName, email, userName };
  const legalAdmin = isLegalAdmin(currentUser);
  const loggedUser = currentUser?.userName;

  let baseUrl = downloadUrl;
  if (legalCaseId !== undefined && legalCaseId !== null) {
    baseUrl += 'legalCaseId=' + encodeURIComponent('' + legalCaseId) + '&';
  }
  if (searchString !== undefined && searchString !== null && searchString !== '') {
    baseUrl += 'searchString=' + encodeURIComponent('' + searchString) + '&';
  }
  if (columnHeaders !== undefined && columnHeaders !== null) {
    baseUrl += 'columnHeaders=' + encodeURIComponent('' + columnHeaders) + '&';
  }
  if (legalAdmin !== undefined && legalAdmin !== null) {
    baseUrl += 'isLegalAdmin=' + encodeURIComponent('' + legalAdmin) + '&';
  }
  if (loggedUser !== undefined && loggedUser !== null) {
    baseUrl += 'loggedUser=' + encodeURIComponent('' + loggedUser) + '&';
  }
  baseUrl = baseUrl.replace(/[?&]$/, '');
  await downloadService(baseUrl, 'POST', data);
}

async function exportToPDF(downloadUrl, currentUser, legalCaseId, searchString, columnHeaders) {
  const { name: firstName, surName: lastName, email, userName } = currentUser || {};
  const data = { firstName, lastName, email, userName };
  const legalAdmin = isLegalAdmin(currentUser);
  const loggedUser = currentUser?.userName;

  let baseUrl = downloadUrl;
  if (legalCaseId !== undefined && legalCaseId !== null) {
    baseUrl += 'legalCaseId=' + encodeURIComponent('' + legalCaseId) + '&';
  }
  if (searchString !== undefined && searchString !== null && searchString !== '') {
    baseUrl += 'searchString=' + encodeURIComponent('' + searchString) + '&';
  }
  if (columnHeaders !== undefined && columnHeaders !== null) {
    baseUrl += 'columnHeaders=' + encodeURIComponent('' + columnHeaders) + '&';
  }
  if (legalAdmin !== undefined && legalAdmin !== null) {
    baseUrl += 'isLegalAdmin=' + encodeURIComponent('' + legalAdmin) + '&';
  }
  if (loggedUser !== undefined && loggedUser !== null) {
    baseUrl += 'loggedUser=' + encodeURIComponent('' + loggedUser) + '&';
  }
  baseUrl = baseUrl.replace(/[?&]$/, '');
  await downloadService(baseUrl, 'POST', data);
}

async function deleteLegalHold(legalHoldId, expireonUser) {
  const { name: firstName, surName: lastName, email, userName } = expireonUser || {};
  let loggedUserString = { firstName, lastName, email, userName };
  const legalAdmin = isLegalAdmin(expireonUser);
  await fetch(
    `${config.externalApi}/legal/api/LegalHold/${legalHoldId}?isLegalAdmin=${legalAdmin}`,
    {
      method: 'DELETE',
      headers: defaultOptions.headers,
      body: JSON.stringify(loggedUserString)
    }
  );
}
const actions = {
  doFetch:
    (filter, keepPagination = false) =>
    async (dispatch) => {
      try {
        dispatch({
          type: 'LEGALHOLD_LIST_FETCH_STARTED',
          payload: { filter, keepPagination }
        });

        const response = await list(
          filter.legalCaseId,
          filter.searchString,
          filter.pageNumber,
          filter.pageSize,
          filter.sortOrder
        );
        dispatch({
          type: 'LEGALHOLD_LIST_FETCH_SUCCESS',
          payload: {
            rows: response.legalHolds,
            count: response.totalCount
          }
        });
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: 'LEGALHOLD_LIST_FETCH_ERROR'
        });
      }
    },

  doDelete: (id, filter, expireonUser) => async (dispatch) => {
    try {
      dispatch({
        type: 'LEGALHOLD_LIST_DELETE_STARTED'
      });

      await deleteLegalHold(id, expireonUser);
      dispatch({
        type: 'LEGALHOLD_LIST_DELETE_SUCCESS'
      });
      toast.success('Legal Hold deleted successfully');
      const response = await list(
        filter.legalCaseId,
        filter.searchString,
        filter.pageNumber,
        filter.pageSize,
        filter.sortOrder
      );
      dispatch({
        type: 'LEGALHOLD_LIST_FETCH_SUCCESS',
        payload: {
          rows: response.legalHolds,
          count: response.totalCount
        }
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'LEGALHOLD_LIST_DELETE_ERROR'
      });
    }
  },
  doGetRefreshInterval: (userName) => async (dispatch) => {
    try {
      dispatch({
        type: 'REFRESH_INTERVAL_FETCH_STARTED'
      });

      const response = await getRefreshInterval(userName);
      dispatch({
        type: 'REFRESH_INTERVAL_FETCH_SUCCESS',
        payload: {
          refreshInterval: response.settingValue || '15'
        }
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: 'REFRESH_INTERVAL_FETCH_ERROR'
      });
    }
  },
  doAllowIndexing: (legalHoldId, currentUser) => async (dispatch) => {
    try {
      dispatch({
        type: 'LEGALHOLD_INDEXING_STARTED'
      });
      toast.success('Indexing process has started');
      await allowIndexing(legalHoldId, currentUser);
      dispatch({
        type: 'LEGALHOLD_INDEXING_SUCCESS'
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: 'LEGALHOLD_INDEXING_ERROR'
      });
    }
  },
  doResetQueuedItems: (legalHoldId, currentUser) => async (dispatch) => {
    try {
      dispatch({
        type: 'LEGALHOLD_RESET_QUEUED_ITEMS_STARTED'
      });
      toast.success('Reset process has started');
      await resetQueuedItems(legalHoldId, currentUser);
      dispatch({
        type: 'LEGALHOLD_RESET_QUEUED_ITEMS_SUCCESS'
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: 'LEGALHOLD_RESET_QUEUED_ITEMS_ERROR'
      });
    }
  },
  handleExportToCSV:
    (downloadUrl, currentUser, legalCaseId, searchString, columnHeaders) => async (dispatch) => {
      try {
        dispatch({
          type: 'EXPORT_TO_CSV_STARTED'
        });

        exportToCSV(downloadUrl, currentUser, legalCaseId, searchString, columnHeaders).then(() => {
          dispatch({
            type: 'EXPORT_TO_CSV_SUCCESS'
          });
        });
      } catch (error) {
        Errors.handle(error);
        dispatch({
          type: 'EXPORT_TO_CSV_ERROR'
        });
      }
    },
  handleExportToPDF:
    (downloadUrl, currentUser, legalCaseId, searchString, columnHeaders) => async (dispatch) => {
      try {
        dispatch({
          type: 'EXPORT_TO_PDF_STARTED'
        });

        exportToPDF(downloadUrl, currentUser, legalCaseId, searchString, columnHeaders).then(() => {
          dispatch({
            type: 'EXPORT_TO_PDF_SUCCESS'
          });
        });
      } catch (error) {
        Errors.handle(error);
        dispatch({
          type: 'EXPORT_TO_PDF_ERROR'
        });
      }
    },
  doShowTable: () => async (dispatch) => {
    dispatch({
      type: 'LEGAL_HOLD_LIST_SHOW_TABLE_CONFIRM'
    });
  },
  doHideTable: () => async (dispatch) => {
    dispatch({
      type: 'LEGAL_HOLD_LIST_HIDE_TABLE_CONFIRM'
    });
  }
};

export default actions;
