import React, { Component } from 'react';
import Loader from 'components/Loader';
import Widget from 'components/Widget';
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import backIcon from '../../../../images/icons/arrow-left.svg';
import LegalHoldListPage from '../../LegalHold/list/LegalHoldListPage';
import viewerActions from 'actions/viewers/LegalCaseViewerFormActions';
import LegalCaseViewerFormPage from '../form/LegalCaseViewerFormPage';
import caseActions from 'actions/LegalCase/LegalCaseListActions';
import AssignedUsersPage from '../assignedUsersList/AssignedUsersPage';
import syncIcon from '../../../../images/icons/sync.svg';
import { store } from 'index';
import SearchTable from 'components/CRUD/Search/list/SearchTable';
import colorFormatter from '../../../../core/colorFormatter';
import styles from 'components/CRUD/LegalCase/closedLegalCasesList/ClosedList.module.scss';
import * as dataFormat from 'components/CRUD/LegalHold/list/LegalHoldDataFormatters';
import ExportListPage from 'components/CRUD/Export/list/ExportListPage';
class ClosedLegalCaseView extends Component {
  state = {
    reOpening: false
  };

  constructor(props) {
    super(props);
  }

  openViewModal() {
    const { dispatch } = this.props;
    dispatch(viewerActions.doOpenConfirm());
  }

  closeViewModal() {
    const { dispatch } = this.props;
    dispatch(viewerActions.doCloseConfirm());
  }

  openReOpenModal() {
    this.setState({
      reOpening: true
    });
  }

  closeReOpenModal() {
    this.setState({
      reOpening: false
    });
  }

  handleReOpen() {
    const { dispatch } = this.props;
    const legalCaseId = this.props?.record?.legalCaseId;
    const expireonUser = this.props?.record?.expireonUser;
    dispatch(caseActions.doReopenCase(legalCaseId, expireonUser)).then(() => {
      this.closeReOpenModal();
    });
  }

  caseStatusFormatter = (caseStatus) => {
    return (
      <>
        <div
          style={{
            width: 8,
            height: 8,
            backgroundColor: colorFormatter(caseStatus),
            borderRadius: '50%'
          }}
          className={'me-2 d-inline-block'}
        />
        {caseStatus}
      </>
    );
  };

  renderView() {
    const currentUser = store.getState().auth.currentUser;
    const isAdmin = currentUser?.roles?.includes('admin');
    return (
      <>
        <Row>
          <Col sm={12}>
            <div className={'d-flex mb-4 align-items-center'}>
              <button
                className={'btn bg-transparent me-2 ps-0'}
                onClick={() => this.props.history.goBack()}
              >
                <img src={backIcon} alt={'arrow-back'} />
              </button>
              <h3 className={'fw-semi-bold me-4 mb-0'}>{this.props.record?.caseName}</h3>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Widget
              className={`mb-0`}
              bodyClass={'h-100 w-200 d-flex flex-column justify-content-between'}
            >
              <Row>
                <Col>
                  <small
                    className={'text-muted case-info-text'}
                    style={{
                      whiteSpace: 'pre-wrap',
                      overflowWrap: 'break-word',
                      wordBreak: 'break-all'
                    }}
                  >
                    Reference ID: {this.props.record.caseReferenceId} --{' '}
                    {this.caseStatusFormatter(this.props.record?.caseStatus)}
                  </small>
                </Col>
                {isAdmin === true ||
                currentUser.userName === this.props?.record?.expireonUser?.userName ? (
                  <Col>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginBottom: '5px'
                      }}
                    >
                      <button
                        style={{
                          height: 37,
                          width: '150px',
                          borderRadius: '0'
                        }}
                        className='btn fw-semi-bold me-2 ms-10'
                        type='button'
                        onClick={() => this.openReOpenModal()}
                      >
                        <img src={syncIcon} alt={'plus'} className={'me-2'} />
                        Re-Open Case
                      </button>
                    </div>
                  </Col>
                ) : null}
              </Row>
              <Row>
                <Col>
                  <h5 className={'fw-semi-bold mb-1'} style={{ marginTop: 12 }}>
                    {this.props.record?.caseName}
                  </h5>
                  <small className={styles.caseDescription}>
                    {this.props.record?.caseDescription}
                  </small>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className={'d-flex'} style={{ marginTop: 12 }}>
                    <small className={'pe-3 text-muted case-info-text'}>
                      Custodians: {this.props.record?.custodianCount}
                    </small>
                    <small className={'pe-3 text-muted case-info-text'}>
                      Holds: {this.props.record?.holdCount}
                    </small>
                    <small className={'pe-3 text-muted case-info-text'}>
                      Searches: {this.props.record?.contentSearchCount}
                    </small>
                    <small className={'text-muted case-info-text'}>
                      Exports: {this.props.record?.exportCount}
                    </small>
                  </div>
                  <div>
                    {this.props.record.expireonUser?.firstName && (
                      <small className={'text-muted case-info-text'}>
                        Created by: {this.props.record?.expireonUser?.firstName}{' '}
                        {this.props.record?.expireonUser?.lastName} -{' '}
                        {dataFormat.dateTimeToStringFormatter(this.props.record?.createdTime)}
                      </small>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div>
                    <small className={'text-muted case-info-text'}>
                      Closure Reason: {this.props.record.closureReason}
                    </small>
                  </div>
                </Col>
              </Row>
            </Widget>
          </Col>
        </Row>
        <Row>
          <Col sm={12} style={{ marginTop: 40 }}>
            <LegalHoldListPage
              legalCaseId={this.props.record?.legalCaseId}
              caseStatus={this.props.record?.caseStatus}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12} style={{ marginTop: 40 }}>
            <Widget style={{ marginTop: '20px' }}>
              <SearchTable
                hideTableToggle={true}
                legalCaseId={this.props.record?.legalCaseId}
                caseStatus={this.props.record?.caseStatus}
              />
            </Widget>
          </Col>
        </Row>
        <Row>
          <Col sm={12} style={{ marginTop: 40 }}>
            <ExportListPage
              legalCaseId={this.props.record?.legalCaseId}
              caseStatus={this.props.record?.caseStatus}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12} style={{ marginTop: 40 }}>
            <AssignedUsersPage
              legalCaseId={this.props.record?.legalCaseId}
              caseStatus={this.props.record?.caseStatus}
            />
          </Col>
        </Row>
        <Modal
          size='md'
          isOpen={this.props.openViewerModal}
          className={`themeStyle${this.props.currentTheme?.replace('#', '')}`}
        >
          <ModalHeader toggle={() => this.closeViewModal()} className={'fw-bold'}>
            Add Viewers
          </ModalHeader>
          <ModalBody>
            <LegalCaseViewerFormPage
              dispatch={this.props.dispatch}
              legalCaseId={this.props.record?.legalCaseId}
            />
          </ModalBody>
        </Modal>
        <Modal
          size='sm'
          isOpen={this.state.reOpening}
          className={`themeStyle${this.props.currentTheme?.replace('#', '')}`}
        >
          <ModalHeader toggle={() => this.closeReOpenModal()}>Confirmation</ModalHeader>
          <ModalBody>
            Are you sure you want to Re-Open this Legal Case?
            <Button color='primary' onClick={() => this.handleReOpen()} className={'mt-4 w-100'}>
              Re-Open
            </Button>
          </ModalBody>
        </Modal>
      </>
    );
  }

  render() {
    const { record, loading } = this.props;

    if (loading || !record) {
      return <Loader />;
    }

    return this.renderView();
  }
}

export default ClosedLegalCaseView;
