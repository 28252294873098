import React from "react";
import { Route, Redirect } from "react-router-dom";

function PermissionRoute({
  isPermitted,
  children,
  component: Component,
  ...rest
}) {
  return isPermitted ? (
    <>
      <Route {...rest} component={Component} />
    </>
  ) : (
    <>
      <Redirect to={"/no-access"} />
    </>
  );
}

export default PermissionRoute;
