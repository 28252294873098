import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import backIcon from 'images/icons/arrow-left.svg';
import Widget from 'components/Widget/Widget';
import { FormattingService } from 'utils/sizeFormatting';
import styles from './SearchDetailsView.module.scss';
import * as dataFormat from 'components/CRUD/LegalHold/list/LegalHoldDataFormatters';
import { toast } from 'react-toastify';
export default class SearchDetailsView extends Component {
  constructor(props) {
    super(props);
    this.textRef = React.createRef();
  }

  state = {
    allRecipientsList: []
  };

  componentDidUpdate(prevProps) {
    const { contentSearch } = this.props;
    if (prevProps.contentSearch !== this.props.contentSearch) {
      let allRecipientsList = [];
      if (contentSearch?.queryString?.to) {
        allRecipientsList.push(contentSearch?.queryString?.to);
      }
      if (contentSearch?.queryString?.cc) {
        allRecipientsList.push(contentSearch?.queryString?.cc);
      }
      if (contentSearch?.queryString?.bcc) {
        allRecipientsList.push(contentSearch?.queryString?.bcc);
      }

      this.setState({
        allRecipientsList
      });
    }
  }

  handleCopyClick = async () => {
    try {
      const textToCopy = this.textRef.current.textContent;
      await navigator.clipboard.writeText(textToCopy);
      toast.success('Query copied to clipboard');
    } catch (error) {
      console.error('Failed to copy text to clipboard:', error);
    }
  };

  render() {
    const { contentSearch } = this.props;
    const toReadableSizeFormat = new FormattingService();
    return (
      <div>
        <Row>
          <Col sm={12}>
            <div className={'d-flex mb-4 align-items-center'}>
              <button
                className={'btn bg-transparent me-2 ps-0'}
                onClick={() => this.props.history.goBack()}
              >
                <img src={backIcon} alt={'arrow-back'} />
              </button>
              <h3
                style={{
                  whiteSpace: 'pre-wrap',
                  overflowWrap: 'break-word',
                  wordBreak: 'break-all'
                }}
                className={'fw-semi-bold me-4 mb-0'}
              >
                {contentSearch?.name}
              </h3>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Widget style={{ minHeight: 430, marginRight: '3%' }}>
              <h4 className=' mb-4'>
                <span className='fw-semi-bold'>Search Name:</span>
                <span className='ms-2'>{contentSearch?.name}</span>
              </h4>
              <div className={styles.searchDetails}>
                <h5 className='mb-3'>
                  <span className='fw-semi-bold'>Created:</span>
                  <span className='ms-2'>
                    {dataFormat.dateTimeFormatter(contentSearch?.creationDate)}
                  </span>
                </h5>
                <h5 className='mb-3'>
                  <p className='fw-semi-bold'>Legal Holds:</p>
                  <p className='mb-1'>
                    <span>{contentSearch?.legalHoldNames?.join(', ')}</span>
                  </p>
                </h5>
                <h5 className='mb-3'>
                  <span className='fw-semi-bold'>Sender (From):</span>

                  <span className='ms-2'>
                    {contentSearch?.queryString?.from ? contentSearch?.queryString?.from : 'ANY'}
                  </span>
                </h5>
                <h5 className='mb-3'>
                  <span className='fw-semi-bold'>Recipients:</span>
                  <span className='ms-2'>
                    {contentSearch?.queryString?.recipients ? (
                      <span>{contentSearch?.queryString?.recipients}</span>
                    ) : (
                      'ANY'
                    )}
                  </span>
                </h5>
                <h5 className='mb-3'>
                  <p className='fw-semi-bold'>To:/CC:/BCC:</p>
                  <p className='mb-0'>
                    <span>
                      {this.state.allRecipientsList?.length !== 0
                        ? this.state.allRecipientsList?.join(', ')
                        : 'ANY'}
                    </span>
                  </p>
                </h5>
                <h5 className='mb-3'>
                  <span className='fw-semi-bold'> MessageID:</span>
                  <span className='ms-2'>
                    {contentSearch?.queryString?.messageId
                      ? contentSearch?.queryString?.messageId
                      : 'ANY'}
                  </span>
                </h5>
                <h5 className='mb-3'>
                  <span className='fw-semi-bold me-2'>Only mails with Attachments:</span>
                  <span>{contentSearch?.onlyMailsWithAttachments ? 'Yes' : 'No'}</span>
                </h5>
                <h5 className='mb-2'>
                  <span className='fw-semi-bold'> Fuzziness:</span>
                  <span className='ms-2'>{contentSearch?.fuzzynessType}</span>
                </h5>
              </div>
            </Widget>
          </Col>
          <Col sm={6}>
            <Widget style={{ minHeight: 430 }}>
              <h4 className='fw-semi-bold mb-4'>Content Query</h4>
              <h5 className={styles.query} ref={this.textRef}>
                {contentSearch?.queryString?.query}
              </h5>
              <div className='d-flex justify-content-end mt-1 mb-2 me-5'>
                <button
                  type='button'
                  className='btn fw-semi-bold d-flex align-items-center'
                  onClick={() => this.handleCopyClick()}
                >
                  Copy
                </button>
              </div>
              <div className={styles.scopeContainer}>
                <h5 className='fw-semi-bold mt-8 mb-4'>Scope:</h5>
                <h5 className='d-flex justify-content-between w-100'>
                  <p>
                    <span className='fw-semi-bold me-2'> Header:</span>
                    <span className='me-1'>{contentSearch.searchInHeader ? 'Yes' : 'No'}</span>
                  </p>
                  <p>
                    <span className='fw-semi-bold me-2'>Subject:</span>
                    <span className='me-1'>{contentSearch.searchInSubject ? 'Yes' : 'No'}</span>
                  </p>
                  <p>
                    <span className='fw-semi-bold  me-2'>Body:</span>
                    <span className='me-1'>{contentSearch.searchInBody ? 'Yes' : 'No'}</span>
                  </p>
                  <p>
                    <span className='fw-semi-bold  me-2'>Attachments:</span>
                    <span className='me-1'>{contentSearch.searchInAttachments ? 'Yes' : 'No'}</span>
                  </p>
                </h5>
              </div>
            </Widget>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Widget style={{ height: 420, marginTop: '3%', marginRight: '3%' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between'
                }}
              >
                <h4 className={'mb-2 text-start fw-semi-bold'}>Statistics</h4>
                <Widget className={styles.statsWidget}>
                  <h5 className={'text-center mb-1'}>Total Items Size</h5>
                  <div className={'text-center'}>
                    <h4 className={'fw-bold d-inline-block'}>
                      {contentSearch?.totalSize &&
                        dataFormat.sizeFormatter(contentSearch?.totalSize)}
                    </h4>
                  </div>
                </Widget>
                <Widget className={styles.statsWidget}>
                  <h5 className={'text-center mb-1'}>Total Items Count</h5>
                  <div className={'text-center'}>
                    <h4 className={'fw-bold d-inline-block mb-0'}>
                      {toReadableSizeFormat.numberWithCommas(contentSearch?.totalItems || 0)}
                    </h4>
                  </div>
                </Widget>
                <Widget style={{ marginBottom: '0px' }} className={styles.statsWidget}>
                  <h5 className={'text-center mb-1'}>Items With Attachments</h5>
                  <div className={'text-center'}>
                    <h4 className={'fw-bold d-inline-block mb-0'}>
                      {toReadableSizeFormat.numberWithCommas(
                        contentSearch?.hasAttachmentsCount || 0
                      )}
                    </h4>
                  </div>
                </Widget>
              </div>
            </Widget>
          </Col>
        </Row>
      </div>
    );
  }
}
