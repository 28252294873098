import React, { Component } from 'react';
import Loader from 'components/Loader';
import Widget from 'components/Widget';
import ApexChart from 'react-apexcharts';
import { Row, Col } from 'reactstrap';
import cd from './mock';
import { FormattingService } from '../../../../utils/sizeFormatting';
import backIcon from '../../../../images/icons/arrow-left.svg';
import * as dataFormat from '../../Search/list/SearchDataFormatters';
import Tooltip from '../../../Tooltip';
import config from '../../../../config';
import styles from './LegalHoldView.module.scss';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { emptyDataMessage } from 'actions/common';
import s from '../form/Wizard.module.scss';
import { BiCopyright } from 'react-icons/bi';
import { ImInfo } from 'react-icons/im';
import LegalHoldTooltip from '../form/LegalHoldTooltip';
import UserIdentitiesTooltip from '../form/UserIdentitiesTooltip';
import LongNameTooltip from '../form/LongNameTooltip';
import formActions from 'actions/LegalHold/LegalHoldFormActions';

class LegalHoldView extends Component {
  constructor(props) {
    super(props);
    this.handleResize = this.handleResize.bind(this);
  }
  state = {
    position: {
      top: 0,
      left: 0
    },
    showDetails: false,
    person: {},
    custodianId: null,
    custodianName: null,
    custodianPosition: {
      top: 0,
      left: 0
    },
    allAttachments: [],
    messageClassList: [],
    containersList: [],
    showTooltip: false,
    topSendersArray: [],
    externalEmailsArray: [],
    internalEmailsArray: [],
    custodiansList: [],
    externalCustodianInfo: [],
    allSelectedCustodians: [],
    showIdentitiesTooltip: false,
    currentIdentities: [],
    showNameTooltip: false,
    identityType: '',
    count: 0,
    longName: '',
    showNameTooltip: false,
    namePosition: {
      top: 0,
      left: 0
    },
    width: window.innerWidth,
    cardPosition: {
      top: 0,
      left: 0
    },
    containerDescription: ''
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize() {
    this.setState({
      width: window.innerWidth
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      const attachmentNames = this.props?.record?.attachmentNames;
      const attachmentExtensions = this.props?.record?.attachmentExtensions;
      const allAttachments = attachmentNames?.concat(attachmentExtensions);
      this.setState({
        allAttachments: allAttachments
      });
      let messageList = this.props?.record?.messageClass?.split(',');
      this.setState({
        messageClassList: messageList,
        containersList: this.props.record?.containers
      });
    }
    if (prevProps.record !== this.props?.record && this.props?.record) {
      this.setState(
        {
          topSendersArray: this.toData(this.props?.record?.topSenders),
          externalEmailsArray: this.toData(this.props?.record?.topExternalEmailAddresses),
          internalEmailsArray: this.toData(this.props?.record?.topInternalEmailAddresses),
          custodiansList: this.props?.record?.custodians,
          externalCustodianInfo: this.props?.record?.externalCustodianInfo
        },
        () => {
          let formattedExternal = this.getInitialExternalCustodians(
            this.props?.record?.externalCustodianInfo
          );
          let allCustodians = [];
          let allInternal = this.props?.record?.custodians ? this.props?.record?.custodians : [];
          let allExternal = this.props?.record?.externalCustodianInfo ? formattedExternal : [];
          allCustodians = allInternal?.concat(allExternal);
          this.setState({
            allSelectedCustodians: allCustodians
          });
        }
      );
    }
    if (
      prevState.currentIdentities !== this.state.currentIdentities &&
      this.state.currentIdentities.length > 0
    ) {
      this.setState({
        showIdentitiesTooltip: true
      });
    }
  }

  toData = (values) => {
    let modifiedArray = [];
    if (JSON.stringify(values) !== '{}') {
      Object.entries(values)?.map((item, index) => {
        modifiedArray.push({ id: index + 1, email: item[0], count: item[1] });
      });
    }
    return modifiedArray;
  };

  getInitialExternalCustodians(data) {
    let externalList = [];
    data?.map((item, index) => {
      externalList.push({
        custodianId: index,
        isExternal: true,
        companyName: item?.companyName,
        smtpAddress: item?.emailAddress,
        displayName: item?.displayName
      });
    });

    return externalList;
  }

  handleGetCustodianDetails = (id) => {
    const selectedCustodian = this.state.custodiansList?.find((item) => item?.custodianId === id);
    this.setState(
      {
        showDetails: selectedCustodian?.displayName !== null ? true : false,
        person: selectedCustodian
      },
      () => {
        this.setState({
          showTooltip: true
        });
      }
    );
  };

  handleUserIdentities = (custodianId, identityType) => {
    const { dispatch } = this.props;
    dispatch(formActions.doGetCustodianIdentities(custodianId)).then(() => {
      let newList = [];
      this.props.userIdentities?.map((item) => {
        if (item?.identityType === identityType) {
          newList = item?.value;
        }
      });
      this.setState({
        currentIdentities: newList
      });
    });
  };

  renderView() {
    const { record } = this.props;

    const toReadableSizeFormat = new FormattingService();
    const columns = [
      {
        dataField: 'id',
        text: '#',
        sort: false,
        headerStyle: {
          width: '15%'
        }
      },
      {
        dataField: 'email',
        text: 'Email',
        sort: false,
        headerStyle: {
          width: '65%'
        },
        formatter: (cell, row) => <div className={styles.upn}>{cell}</div>
      },
      {
        dataField: 'count',
        text: 'Count',
        sort: false,
        headerStyle: {
          width: '20%'
        },
        formatter: (item) => toReadableSizeFormat.numberWithCommas(item)
      }
    ];

    const custodianColumns = [
      {
        dataField: 'displayName',
        text: 'Display Name',
        headerFormatter: (column, colIndex) => {
          return (
            <div>
              <p className='mb-0'>{column.text}</p>
              <p className='mb-0 fw-normal'>Department</p>
            </div>
          );
        },
        formatter: (cell, row) => {
          return (
            <div className='w-100 d-flex align-items-center'>
              {row.isExternal ? (
                <span className='me-3'>
                  <BiCopyright style={{ marginLeft: '8px', width: '18px', height: '18px' }} />
                </span>
              ) : (
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => {
                    this.setState({
                      position: {
                        top: e.pageY,
                        left: e.pageX
                      }
                    });
                  }}
                >
                  <ImInfo
                    onClick={(e) => {
                      this.handleGetCustodianDetails(row?.custodianId);
                      this.setState({
                        showTooltip: true,
                        showIdentitiesTooltip: false,
                        showNameTooltip: false
                      });
                    }}
                    style={{
                      marginLeft: '8px',
                      width: '16px',
                      height: '16px',
                      color: '#2d8515'
                    }}
                    className='me-3'
                  />
                </span>
              )}

              <div className='w-100'>
                {row?.isExternal ? (
                  <p
                    className={`mb-0 fw-semi-bold`}
                    onMouseOver={(e) => {
                      if (this.state.width < 1920 && row?.displayName?.length > 15) {
                        this.setState({
                          showNameTooltip: true,
                          longName: row?.displayName,
                          namePosition: {
                            top: e.pageY,
                            left: e.pageX
                          }
                        });
                      }
                    }}
                    onMouseLeave={() => {
                      if (this.state.width < 1920 && row?.displayName?.length > 15) {
                        this.setState({
                          showNameTooltip: false,
                          longName: '',
                          namePosition: {
                            top: 0,
                            left: 0
                          }
                        });
                      }
                    }}
                  >
                    {this.state.width < 1920 && row?.displayName?.length > 15
                      ? `${row?.displayName?.slice(0, 15)}...`
                      : row?.displayName}
                  </p>
                ) : (
                  <p
                    className={`mb-0 fw-semi-bold`}
                    onMouseOver={(e) => {
                      if (this.state.width < 1920 && row?.displayName?.length > 15) {
                        this.setState({
                          showNameTooltip: true,
                          longName: row?.displayName,
                          namePosition: {
                            top: e.pageY,
                            left: e.pageX
                          }
                        });
                      }
                    }}
                    onMouseLeave={() => {
                      if (this.state.width < 1920 && row?.displayName?.length > 15) {
                        this.setState({
                          showNameTooltip: false,
                          longName: '',
                          namePosition: {
                            top: 0,
                            left: 0
                          }
                        });
                      }
                    }}
                  >
                    {this.state.width < 1920 && row?.displayName?.length > 15
                      ? `${row?.displayName?.slice(0, 15)}...`
                      : row?.displayName}
                  </p>
                )}
                <div className='d-flex'>
                  {row?.isExternal && row?.companyName ? (
                    <p
                      className='mb-0'
                      onMouseOver={(e) => {
                        if (this.state.width < 1920 && row?.companyName?.length > 15) {
                          this.setState({
                            showNameTooltip: true,
                            longName: row?.companyName,
                            namePosition: {
                              top: e.pageY,
                              left: e.pageX
                            }
                          });
                        }
                      }}
                      onMouseLeave={() => {
                        if (this.state.width < 1920 && row?.companyName?.length > 15) {
                          this.setState({
                            showNameTooltip: false,
                            longName: '',
                            namePosition: {
                              top: 0,
                              left: 0
                            }
                          });
                        }
                      }}
                    >
                      {this.state.width < 1920 && row?.companyName?.length > 15
                        ? `${row?.companyName?.slice(0, 15)}...`
                        : row?.companyName}
                    </p>
                  ) : (
                    <>
                      {row?.department && (
                        <p
                          className='mb-0'
                          onMouseOver={(e) => {
                            if (this.state.width < 1920 && row?.department?.length > 15) {
                              this.setState({
                                showNameTooltip: true,
                                longName: row?.department,
                                namePosition: {
                                  top: e.pageY,
                                  left: e.pageX
                                }
                              });
                            }
                          }}
                          onMouseLeave={() => {
                            if (this.state.width < 1920 && row?.department?.length > 15) {
                              this.setState({
                                showNameTooltip: false,
                                longName: '',
                                namePosition: {
                                  top: 0,
                                  left: 0
                                }
                              });
                            }
                          }}
                        >
                          {this.state.width < 1920 && row?.department?.length > 15
                            ? `${row?.department?.slice(0, 15)}...`
                            : row?.department}
                        </p>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          );
        }
      },
      {
        dataField: 'smtpAddress',
        text: 'Email Address',
        headerFormatter: (column, colIndex) => {
          return (
            <div>
              <p className='mb-0'>{column.text}</p>
              <p className='mb-0 fw-normal'>Sam Account</p>
            </div>
          );
        },
        formatter: (cell, row) => (
          <div className='d-flex justify-content-between'>
            <div>
              {row?.smtpAddress && (
                <p
                  className={`mb-0 fw-semi-bold`}
                  onMouseOver={(e) => {
                    if (this.state.width < 1920 && row?.smtpAddress?.length > 35) {
                      this.setState({
                        showNameTooltip: true,
                        longName: row?.smtpAddress,
                        namePosition: {
                          top: e.pageY,
                          left: e.pageX
                        }
                      });
                    }
                  }}
                  onMouseLeave={() => {
                    if (this.state.width < 1920 && row?.smtpAddress?.length > 35) {
                      this.setState({
                        showNameTooltip: false,
                        longName: '',
                        namePosition: {
                          top: 0,
                          left: 0
                        }
                      });
                    }
                  }}
                >
                  {this.state.width < 1920 && row?.smtpAddress?.length > 35
                    ? `${row?.smtpAddress?.slice(0, 35)}...`
                    : row?.smtpAddress}
                </p>
              )}
              {!row?.isExternal ? (
                <p
                  className='mb-0'
                  onMouseOver={(e) => {
                    if (this.state.width < 1920 && row?.samAccountName?.length > 35) {
                      this.setState({
                        showNameTooltip: true,
                        longName: row?.samAccountName,
                        namePosition: {
                          top: e.pageY,
                          left: e.pageX
                        }
                      });
                    }
                  }}
                  onMouseLeave={() => {
                    if (this.state.width < 1920 && row?.samAccountName?.length > 35) {
                      this.setState({
                        showNameTooltip: false,
                        longName: '',
                        namePosition: {
                          top: 0,
                          left: 0
                        }
                      });
                    }
                  }}
                >
                  {this.state.width < 1920 && row?.samAccountName?.length > 35
                    ? `${row?.samAccountName?.slice(0, 35)}...`
                    : row?.samAccountName}
                </p>
              ) : (
                <p className='mb-0'>
                  added by: {this.props?.record?.expireonUser?.firstName}{' '}
                  {this.props?.record?.expireonUser?.lastName}{' '}
                </p>
              )}
            </div>
            <div>
              {row?.custodianIdentitiesCount?.map((item) => {
                return (
                  <>
                    <p className='d-flex justify-content-between'>
                      <span className='me-2'>{item.count}</span>
                      <span
                        className={`${
                          item.identityType === 'SMTP' ? s.identitySmtpBox : s.identityX500Box
                        }`}
                        onClick={(e) => {
                          this.handleUserIdentities(row?.custodianId, item?.identityType);
                          this.setState({
                            showIdentitiesTooltip: true,
                            position: {
                              top: e.pageY,
                              left: e.pageX
                            },
                            identityType: item?.identityType,
                            showTooltip: false,
                            showNameTooltip: false,
                            count: item?.count
                          });
                        }}
                      >
                        {item.identityType}
                      </span>
                    </p>
                  </>
                );
              })}
            </div>
          </div>
        )
      },
      {
        dataField: 'upn',
        text: 'Source',
        headerFormatter: (column, colIndex) => {
          return (
            <div>
              <p className='mb-0'>{column.text}</p>
              <p className='mb-0 fw-normal'>User principal name</p>
            </div>
          );
        },
        formatter: (cell, row) => (
          <>
            {row.isExternal ? (
              <p className={s.customAddress}>Custom Address</p>
            ) : (
              <div className='w-100'>
                <p className={`mb-0 fw-semi-bold`}>{row?.hasAdRecord ? 'AD' : 'N/A'}</p>
                {row?.upn && (
                  <p
                    className='mb-0'
                    onMouseOver={(e) => {
                      if (this.state.width < 1920 && row?.upn?.length > 20) {
                        this.setState({
                          showNameTooltip: true,
                          longName: row?.upn,
                          namePosition: {
                            top: e.pageY,
                            left: e.pageX
                          }
                        });
                      }
                    }}
                    onMouseLeave={() => {
                      if (this.state.width < 1920 && row?.upn?.length > 20) {
                        this.setState({
                          showNameTooltip: false,
                          longName: '',
                          namePosition: {
                            top: 0,
                            left: 0
                          }
                        });
                      }
                    }}
                  >
                    {this.state.width < 1920 && row?.upn?.length > 20
                      ? `${row?.upn?.slice(0, 20)}...`
                      : row?.upn}
                  </p>
                )}
              </div>
            )}
          </>
        )
      }
    ];
    let tooltipChartCount = 0;

    const attachmentsCount = toReadableSizeFormat.getBytesReadable(record.itemSizeInBytes);
    const incOutCount = [
      (record.isIncomingCount * 100) / record?.itemCount || 0,
      (record.isOutgoingCount * 100) / record?.itemCount || 0
    ];
    const externalCount = [
      (record?.isInternalOnlyCount * 100) / record?.itemCount || 0,
      ((record?.itemCount - record.isInternalOnlyCount) * 100) / record?.itemCount || 0
    ];

    return (
      <div
        style={{ minHeight: '100vh' }}
        onClick={() => {
          this.setState({
            showTooltip: false
          });
          if (this.state.showIdentitiesTooltip) {
            this.setState({
              showIdentitiesTooltip: false,
              currentIdentities: []
            });
          }
          if (this.state.showNameTooltip) {
            this.setState({
              showNameTooltip: false
            });
          }
        }}
      >
        <Row>
          <Col sm={12}>
            <div className={'d-flex mb-4 align-items-center'}>
              <button
                className={'btn bg-transparent me-2 ps-0'}
                onClick={() => this.props.history.goBack()}
              >
                <img src={backIcon} alt={'arrow-back'} />
              </button>
              <h3
                style={{
                  whiteSpace: 'pre-wrap',
                  overflowWrap: 'break-word',
                  wordBreak: 'break-all'
                }}
                className={'fw-semi-bold me-4 mb-0'}
              >
                {this.props.record?.legalHoldName}
              </h3>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={5}>
            <Widget
              style={{
                height: 580
              }}
            >
              <div>
                <div className='mb-3'>
                  <h4 className={'mb-2 mt-2 d-flex w-100'}>
                    <p className='me-2 fw-bold w-20 mb-0'>Hold Name:</p>
                    <p className={styles.legalHoldName}>{record.legalHoldName}</p>
                  </h4>
                  <div>
                    <h5 className='me-2 fw-bold'>Description</h5>
                    <span className={styles.legalHoldDescription}>
                      {record.legalHoldDescription}
                    </span>
                  </div>
                </div>
                <div className={'mt-2'}>
                  <h5 className={'fw-bold mb-4'}>Filter by</h5>
                  <h5 className='mb-3'>
                    <span className={'fw-bold me-2'}>Date :</span>
                    <span className={'me-2'}>
                      {dataFormat.dateTimeFormatter(this.props.record.dateFrom)}
                    </span>
                    <span className={'fw-bold me-2'}>To:</span>
                    <span>{dataFormat.dateTimeFormatter(this.props.record.dateTo)}</span>
                  </h5>
                  <h5 className='mb-3'>
                    <span className={'fw-bold me-2'}>Direction:</span>
                    <span>
                      {this.props?.record?.internalOnly ? (
                        <span>Internal Emails Only</span>
                      ) : this.props?.record?.incoming === null &&
                        this.props?.record?.outgoing === null &&
                        this.props?.record?.internalOnly === false ? (
                        <span>Incoming & Outgoing</span>
                      ) : this.props?.record?.outgoing ? (
                        <span>Outgoing Emails Only</span>
                      ) : this.props?.record?.incoming ? (
                        <span>Incoming Emails Only</span>
                      ) : (
                        <span>All Emails</span>
                      )}
                    </span>
                    <span>
                      {this.props?.record?.noOtherInternalRecipient ? (
                        <span className={'ms-2'}>(No other internal recipient)</span>
                      ) : null}
                    </span>
                  </h5>
                  <h5 className='mb-3'>
                    <span className={'fw-bold me-2'}>Subject:</span>
                    <span>
                      {this.props.record.subject ? (
                        this.props.record.subject
                      ) : (
                        <span>(No Filter)</span>
                      )}
                    </span>
                  </h5>
                  {this.props?.record?.messageClass !== '' && (
                    <div className={styles.messageClassContainer}>
                      <h5 className={`${styles.messageClass} mb-3`}>Message Class:</h5>
                      <div className={styles.filterContainer}>
                        {this.state?.messageClassList?.map((item, index) => (
                          <span key={index} style={{ margin: '2px' }}>
                            {item}
                            {index + 1 < this.state?.messageClassList?.length && <span>,</span>}
                          </span>
                        ))}
                      </div>
                    </div>
                  )}
                  {this.state.allAttachments?.length !== 0 && (
                    <div className={'d-flex'}>
                      <h5 className={'fw-bold me-2 mb-3'}>Attachment:</h5>
                      <span className={styles.filterContainer}>
                        {this.state.allAttachments?.map((item, index) => (
                          <span key={index} style={{ margin: '3px' }}>
                            {item}
                            {index + 1 < this.state?.allAttachments?.length && <span>,</span>}
                          </span>
                        ))}
                      </span>
                    </div>
                  )}
                  {this.props?.record?.containers?.length !== 0 && (
                    <>
                      <div className={styles.mainContainer}>
                        {this.state.openCard && (
                          <LongNameTooltip
                            longName={this.state.containerDescription}
                            position={this.state.cardPosition}
                            isContainer={true}
                          />
                        )}
                        <h5 className={'fw-bold me-3'}>Containers:</h5>
                        <span className={styles.containerNames}>
                          {this.state.containersList?.map((item, index) => {
                            return (
                              <div className={styles.item} key={index}>
                                {item?.name}
                                <span
                                  style={{
                                    position: 'relative',
                                    cursor: 'pointer',
                                    zIndex: 'auto'
                                  }}
                                  onMouseLeave={() => {
                                    this.setState({
                                      openCard: false,
                                      cardPosition: {
                                        top: 0,
                                        left: 0
                                      }
                                    });
                                  }}
                                  onClick={(e) => {
                                    this.setState({
                                      openCard: true,
                                      cardPosition: {
                                        top: e.pageY,
                                        left: e.pageX
                                      },
                                      containerDescription: item?.description
                                    });
                                    e.stopPropagation();
                                  }}
                                >
                                  <ImInfo style={{ marginLeft: '8px', marginBottom: '5px' }} />
                                </span>
                              </div>
                            );
                          })}
                        </span>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </Widget>
          </Col>
          <Col sm={7}>
            <Widget
              style={{
                height: 580,
                marginLeft: '3%'
              }}
            >
              {this.state.showTooltip && (
                <LegalHoldTooltip
                  currentCustodian={this.state.person}
                  position={this.state.position}
                />
              )}
              {this.state.showIdentitiesTooltip && (
                <UserIdentitiesTooltip
                  identitiesList={this.state.currentIdentities}
                  position={this.state.position}
                  identityType={this.state.identityType}
                  count={this.state.count}
                />
              )}
              {this.state.showNameTooltip && (
                <LongNameTooltip
                  longName={this.state.longName}
                  position={this.state.namePosition}
                />
              )}
              <div>
                <h4 className={'mb-2 text-start fw-semi-bold'}>Custodians</h4>
                <div>
                  <ToolkitProvider
                    bootstrap4
                    key={this.state.width}
                    keyField='custodianId'
                    columns={custodianColumns}
                    data={
                      this.state.allSelectedCustodians?.length > 0
                        ? this.state.allSelectedCustodians
                        : []
                    }
                  >
                    {(props) => (
                      <>
                        <div>
                          <BootstrapTable
                            bordered={false}
                            classes={`table-striped table-hover  mt-4 fs-sm ${styles.internalCustodiansTable}`}
                            remote={{
                              filter: false,
                              pagination: false,
                              sort: true,
                              cellEdit: false
                            }}
                            noDataIndication={emptyDataMessage}
                            {...props.baseProps}
                          />
                        </div>
                      </>
                    )}
                  </ToolkitProvider>
                </div>
              </div>
            </Widget>
          </Col>
        </Row>

        <Row>
          <Col sm={5}>
            <Widget style={{ marginTop: '3%' }}>
              <div
                style={{
                  height: '400px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between'
                }}
              >
                <h4 className={'mb-2 text-start fw-semi-bold'}>Statistics</h4>
                <Widget className={styles.statsWidget}>
                  <h5 className={'text-center mb-2'}>Total Items Size</h5>
                  <div className={'text-center'}>
                    <h4 className={'fw-bold d-inline-block'}>{attachmentsCount}</h4>
                  </div>
                </Widget>
                <Widget className={styles.statsWidget}>
                  <h5 className={'text-center mb-2'}>Total Items Count</h5>
                  <div className={'text-center'}>
                    <h4 className={'fw-bold d-inline-block mb-0'}>
                      {toReadableSizeFormat.numberWithCommas(
                        this.props?.record?.legalHoldStatistics?.itemCount || 0
                      )}
                    </h4>
                  </div>
                </Widget>
                <Widget style={{ marginBottom: '0px' }} className={styles.statsWidget}>
                  <h5 className={'text-center mb-2'}>Items With Attachments</h5>
                  <div className={'text-center'}>
                    <h4 className={'fw-bold d-inline-block mb-0'}>
                      {toReadableSizeFormat.numberWithCommas(
                        this.props?.record?.legalHoldStatistics?.hasAttachmentsCount || 0
                      )}
                    </h4>
                  </div>
                </Widget>
              </div>
            </Widget>
          </Col>
          <Col sm={7}>
            <Widget
              title={
                <h4 style={{ fontSize: '21px' }} className={'mb-2 text-start fw-semi-bold'}>
                  Top Senders
                </h4>
              }
              style={{ maxHeight: 400, marginLeft: '3%', marginTop: '2%' }}
            >
              <div>
                <ToolkitProvider
                  bootstrap4
                  columns={columns}
                  data={this.state.topSendersArray}
                  keyField='id'
                >
                  {(props) => (
                    <>
                      <BootstrapTable
                        bordered={false}
                        classes={`table-striped table-hover mt-4 fs-sm`}
                        remote={{
                          filter: false,
                          pagination: false,
                          sort: false,
                          cellEdit: false
                        }}
                        noDataIndication={emptyDataMessage}
                        {...props.baseProps}
                      />
                    </>
                  )}
                </ToolkitProvider>
              </div>
            </Widget>
          </Col>
        </Row>
        <Row>
          <Col sm={8}>
            {/* <Widget title={<h4>Incoming/Outgoing Count</h4>}>
              <ApexChart
                className="sparkline-chart"
                style={{ marginBottom: 70, marginTop: -27 }}
                type={'radialBar'}
                height={250}
                series={[...incOutCount]}
                options={{
                  dataLabels: {
                    enabled: false,
                  },
                  plotOptions: {
                    radialBar: {
                      hollow: {
                        margin: 20,
                        size: '50%',
                      },
                      dataLabels: {
                        show: false,
                        value: {
                          show: false,
                        },
                        name: {
                          show: false,
                        },
                      },
                      track: {
                        background: 'rgba(5, 5, 15, 1)',
                        strokeWidth: '50%',
                      },
                    },
                  },
                  fill: {
                    type: 'gradient',
                    gradient: {
                      shade: 'dark',
                      type: 'vertical',
                      gradientToColors: [
                        '#4DA1FF',
                        'rgba(117, 23, 248, 1)',
                        'rgba(255, 125, 5, 1)',
                      ],
                      stops: [0, 100],
                    },
                  },
                  stroke: {
                    lineCap: 'round',
                  },
                  chart: {
                    offsetY: 40,
                  },
                  tooltip: {
                    enabled: true,
                    y: {
                      formatter: (cell) => {
                        const arr = [
                          record.isIncomingCount,
                          record.isOutgoingCount,
                        ];
                        if (cell === incOutCount[0]) return arr[0];
                        return arr[1];
                      },
                      title: {
                        formatter: (title) => {
                          if (title === 'series-1') return 'Incoming Count';
                          return 'Outgoing Count';
                        },
                      },
                    },
                  },
                  legend: {
                    formatter: (title) => {
                      if (title === 'series-1') {
                        return 'Incoming';
                      }
                      return 'Outgoing';
                    },
                    position: 'bottom',
                    show: true,
                    labels: {
                      colors: '#fff',
                    },
                    markers: {
                      radius: 30,
                    },
                  },
                  colors: [
                    '#4DFFDF',
                    'rgba(227, 35, 255, 1)',
                    'rgba(255, 212, 34, 1)',
                  ],
                }}
              />
            </Widget> */}
            {/* <Widget
              title={<h4>Top External Emails</h4>}
              // style={{ height: 400 }}
            >
              <div className=" mt-3">
                <ToolkitProvider
                  bootstrap4
                  columns={columns}
                  data={this.state.externalEmailsArray}
                  keyField="id"
                >
                  {(props) => (
                    <>
                      <BootstrapTable
                        bordered={false}
                        classes={`table-striped table-hover mt-4 fs-sm`}
                        remote={{
                          filter: false,
                          pagination: false,
                          sort: false,
                          cellEdit: false,
                        }}
                        noDataIndication={emptyDataMessage}
                        {...props.baseProps}
                      />
                    </>
                  )}
                </ToolkitProvider>
              </div>
            </Widget> */}
          </Col>
          {/* <Col sm={4}>
            <div
              style={{
                height: "400px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Widget className={styles.statsWidget}>
                <h5 className={"text-center mb-2"}>Total Items Size</h5>
                <div className={"text-center"}>
                  <h4 className={"fw-bold d-inline-block"}>
                    {attachmentsCount}
                  </h4>
                </div>
              </Widget>
              <Widget className={styles.statsWidget}>
                <h5 className={"text-center mb-2"}>Total Items Count</h5>
                <div className={"text-center"}>
                  <h4 className={"fw-bold d-inline-block mb-0"}>
                    {toReadableSizeFormat.numberWithCommas(
                      this.props?.record?.legalHoldStatistics?.itemCount || 0
                    )}
                  </h4>
                </div>
              </Widget>
              <Widget
                style={{ marginBottom: "0px" }}
                className={styles.statsWidget}
              >
                <h5 className={"text-center mb-2"}>Items With Attachments</h5>
                <div className={"text-center"}>
                  <h4 className={"fw-bold d-inline-block mb-0"}>
                    {toReadableSizeFormat.numberWithCommas(
                      this.props?.record?.legalHoldStatistics
                        ?.hasAttachmentsCount || 0
                    )}
                  </h4>
                </div>
              </Widget>
            </div>
          </Col> */}
          {/* <Widget title={<h4>Internal/External count</h4>}>
              <ApexChart
                className="sparkline-chart"
                style={{ marginBottom: 70 }}
                type={'radialBar'}
                height={250}
                series={[...externalCount]}
                options={Object.assign(cd.donut.options, {
                  legend: {
                    ...cd.donut.options.legend,
                    formatter: (cell) => {
                      if (cell === 'series-1') {
                        return 'Internal';
                      }
                      return 'External';
                    },
                  },
                  tooltip: {
                    enabled: true,
                    y: {
                      formatter: (cell) => {
                        const arr = [
                          record?.isInternalOnlyCount,
                          record?.itemCount - record?.isInternalOnlyCount,
                        ];
                        if (cell === externalCount[0]) return arr[0];
                        return arr[1];
                      },
                      title: {
                        formatter: (title) => {
                          if (title === 'series-1') return 'Internal Count';
                          return 'External Count';
                        },
                      },
                    },
                  },
                })}
              />
            </Widget> */}
          {/* <Widget
              title={<h4>Top Internal Emails</h4>}
              style={{ maxHeight: 400 }}
            >
              <div className="table-responsive">
                <ToolkitProvider
                  bootstrap4
                  columns={columns}
                  data={this.state.internalEmailsArray}
                  keyField="id"
                >
                  {(props) => (
                    <>
                      <BootstrapTable
                        bordered={false}
                        classes={`table-striped table-hover mt-4 fs-sm`}
                        remote={{
                          filter: false,
                          pagination: false,
                          sort: false,
                          cellEdit: false,
                        }}
                        noDataIndication={emptyDataMessage}
                        {...props.baseProps}
                      />
                    </>
                  )}
                </ToolkitProvider>
              </div>
            </Widget> */}
        </Row>
      </div>
    );
  }

  render() {
    const { findLoading, record } = this.props;

    if (findLoading) {
      return <Loader />;
    }

    if (!record) {
      return <Loader />;
    }

    return this.renderView();
  }
}

export default LegalHoldView;
