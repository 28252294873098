import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  Col,
  Row
} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import plusIcon from 'images/icons/plus.svg';
import barsIcon from 'images/bars.svg';
import actions from 'actions/credentials/CredentialListActions';
import { emptyDataMessage } from 'actions/common';
import Pagination from 'react-js-pagination';
import holdListStyles from 'components/CRUD/LegalHold/list/LegalHoldList.module.scss';
import config from 'config';
import Widget from 'components/Widget/Widget';
import searchIcon from 'images/icons/search.svg';
import CertificateFormPage from '../form/CertificateFormPage';
import { HiOutlineDownload } from 'react-icons/hi';
import s from './CertificateList.module.scss';
import * as dataFormat from 'components/CRUD/LegalHold/list/LegalHoldDataFormatters';
import caretDown from 'images/icons/caret-down.svg';

class CertificateListTable extends Component {
  state = {
    dropdownItem: [],
    pageNumber: 1,
    pageSize: 5,
    totalCount: 0,
    newRows: [],
    paginationModalOpen: false,
    sortOrder: '',
    searchString: ''
  };

  openModal() {
    this.props.dispatch(actions.doOpenKeyModal());
  }

  closeModal() {
    this.props.dispatch(actions.doCloseKeyModal());
  }

  handleCertificateDownload = (id) => {
    const { dispatch } = this.props;
    const { name: firstName, surName: lastName, email, userName } = this.props.currentUser || {};
    let expireonUser = {
      firstName,
      lastName,
      email,
      userName
    };
    expireonUser = JSON.stringify(expireonUser);
    const url = `${config.externalApi}/api/Credentials/DownloadCertificate/${id}?loggedUser=${expireonUser}`;
    dispatch(actions.handleDownloadCertificate(url));
  };

  actionFormatter(cell, row) {
    return (
      <Dropdown group isOpen={this.state.dropdownItem.includes(cell)} size='lg' toggle={() => {}}>
        <DropdownToggle className={'bg-transparent border-0'}>
          <img src={barsIcon} alt='bar' width={'24px'} height={'24px'} />
        </DropdownToggle>
        <DropdownMenu positionFixed={true}>
          <button
            className={`btn text-white fw-semi-bold d-flex align-items-center w-100 bg-transparent`}
            type='button'
            onClick={() => this.handleCertificateDownload(cell)}
          >
            <HiOutlineDownload className={s.icon} />
            <p className={'mb-0'}>Download</p>
          </button>
        </DropdownMenu>
      </Dropdown>
    );
  }

  sortFormatter(sortField) {
    const SortEnum = {
      label: 'label',
      uniqueName: 'uniquename',
      creationTime: 'creationtime',
      keySize: 'keysize'
    };
    return SortEnum[sortField];
  }

  handlePageChange = (pageNumber) => {
    this.setState({ pageNumber: pageNumber });
  };

  handleTableChange = (type, { page, sortField, sortOrder }) => {
    if (type === 'sort') {
      this.setState({
        pageNumber: 1,
        sortOrder: this.sortFormatter(sortField).concat('_').concat(sortOrder)
      });
    }
  };

  handleSearch = () => {
    const { dispatch } = this.props;
    this.setState({
      pageNumber: 1
    });
    dispatch(
      actions.doGetCertificatesList({
        pageSize: this.state.pageSize,
        pageNumber: this.state.pageNumber,
        sortOrder: this.state.sortOrder,
        searchString: this.state.searchString
      })
    ).then(() => {
      this.setState({
        newRows: this.props.certificatesList,
        totalCount: this.props.certificatesCount
      });
    });
  };

  resetDropdowns(e) {
    if (e.target?.getAttribute('alt') != 'bar') {
      this.setState({
        dropdownItem: []
      });
    }
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(
      actions.doGetCertificatesList({
        pageSize: this.state.pageSize,
        pageNumber: this.state.pageNumber,
        sortOrder: this.state.sortOrder,
        searchString: this.state.searchString
      })
    ).then(() => {
      this.setState({
        newRows: this.props.certificatesList,
        totalCount: this.props.certificatesCount
      });
    });
    window.addEventListener('click', (e) => this.resetDropdowns(e));
  }

  componentDidUpdate(prevProps, prevState) {
    const { dispatch } = this.props;
    if (prevProps.certificatesList !== this.props.certificatesList) {
      this.setState({
        newRows: this.props.certificatesList,
        totalCount: this.props.certificatesCount
      });
    }
    if (
      prevState.pageNumber !== this.state.pageNumber ||
      prevState.pageSize !== this.state.pageSize ||
      prevState.sortOrder !== this.state.sortOrder ||
      (prevState.searchString !== this.state.searchString && this.state.searchString === '')
    ) {
      dispatch(
        actions.doGetCertificatesList({
          pageSize: this.state.pageSize,
          pageNumber: this.state.pageNumber,
          sortOrder: this.state.sortOrder,
          searchString: this.state.searchString
        })
      ).then(() => {
        this.setState({
          newRows: this.props.certificatesList,
          totalCount: this.props.certificatesCount
        });
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('click', (e) => this.resetDropdowns(e));
  }

  render() {
    const columns = [
      {
        dataField: 'label',
        text: 'Label',
        sort: true,
        formatter: (cell, row) => <div className={s.certificateName}>{cell}</div>
      },
      {
        dataField: 'uniqueName',
        text: 'Unique Name',
        sort: true,
        formatter: (cell, row) => <div className={s.certificateName}>{cell}</div>
      },
      {
        dataField: 'creationTime',
        text: 'Created',
        formatter: dataFormat.dateTimeFormatter,
        sort: true
      },
      {
        dataField: 'keySize',
        text: 'Key Size',
        sort: true
      },
      {
        dataField: 'id',
        text: 'Actions',
        formatter: (cell, row) => {
          return this.actionFormatter.bind(this)(cell, row);
        },
        events: {
          onClick: (e, column, columnIndex, row) => {
            if (this.state.dropdownItem.includes(row.id)) {
              this.setState({
                dropdownItem: []
              });
            } else {
              this.setState({
                dropdownItem: [row.id]
              });
            }
          }
        }
      }
    ];
    return (
      <>
        <Widget>
          <Row>
            <Col sm={12}>
              <ToolkitProvider bootstrap4 columns={columns} data={this.state.newRows} keyField='id'>
                {(props) => (
                  <>
                    <Row className={'w-100 mb-4'}>
                      <Col sm={12}>
                        <h3 className={'fw-semi-bold me-4 mb-4'}>Certificates</h3>
                      </Col>
                      <Col sm={12} className={'d-flex justify-content-between'}>
                        <div className='d-flex justify-content-between align-items-center mb-3'>
                          <div>
                            <button
                              className='btn fw-semi-bold d-flex align-items-center'
                              type='button'
                              onClick={() => this.openModal()}
                            >
                              <img src={plusIcon} alt={'plus'} className={'me-2'} />
                              <p className={'mb-0'}>Add</p>
                            </button>
                          </div>
                        </div>
                        <div className='d-flex'>
                          <span>
                            <input
                              type='search'
                              placeholder={'Search'}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  this.handleSearch();
                                }
                              }}
                              style={{
                                border: '0.5px solid #0046b1',
                                borderRadius: '0'
                              }}
                              className={'form-control search-input me-5 ms-2 w-200'}
                              value={this.state.searchString}
                              onChange={(e) => {
                                this.setState({
                                  searchString: e.target.value
                                });
                              }}
                            />
                          </span>
                          <span>
                            <button
                              style={{
                                borderRadius: '0',
                                height: '37px',
                                width: '37px'
                              }}
                              className='btn me-2 ms-10'
                              type='button'
                              onClick={this.handleSearch}
                            >
                              <img
                                title={'search'}
                                alt={'search'}
                                width={14}
                                height={14}
                                src={searchIcon}
                              />
                            </button>
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <div
                      onClick={() => {
                        if (this.props.showTooltip) {
                          this.props.dispatch(actions.doToggleTooltip(false));
                        }
                      }}
                      className='table-container'
                    >
                      <BootstrapTable
                        bordered={false}
                        classes={`table-striped table-hover fs-sm custom-table`}
                        remote={{
                          filter: false,
                          pagination: false,
                          sort: true,
                          cellEdit: false
                        }}
                        onTableChange={this.handleTableChange}
                        noDataIndication={emptyDataMessage}
                        {...props.baseProps}
                      />
                    </div>
                    <Row className='mt-4'>
                      <Col style={{ marginTop: '3px' }}>
                        <p>Total: {this.state.totalCount}</p>
                      </Col>
                      <Col className='d-flex justify-content-end'>
                        {this.state.totalCount ? (
                          <Pagination
                            totalItemsCount={this.state.totalCount}
                            onChange={this.handlePageChange}
                            activePage={this.state.pageNumber}
                            itemsCountPerPage={this.state.pageSize}
                            pageRangeDisplayed={5}
                            prevPageText={'<'}
                            nextPageText={'>'}
                            firstPageText={'<<'}
                            lastPageText={'>>'}
                            linkClassFirst={holdListStyles.paginationNext}
                            linkClassPrev={holdListStyles.paginationNext}
                            linkClassNext={holdListStyles.paginationNext}
                            linkClassLast={holdListStyles.paginationNext}
                            linkClass={holdListStyles.pageLink}
                            activeLinkClass={holdListStyles.activeLinkClass}
                          />
                        ) : null}
                        <Dropdown
                          isOpen={this.state.paginationModalOpen}
                          toggle={() =>
                            this.setState({
                              paginationModalOpen: !this.state.paginationModalOpen
                            })
                          }
                          className={holdListStyles.pageSizeDropdown}
                        >
                          <DropdownToggle className='bg-transparent text-white d-flex'>
                            <p style={{ marginBottom: '3px', marginRight: '2px' }}>
                              {this.state.pageSize}
                            </p>
                            <img
                              src={caretDown}
                              alt={'uncollapsed'}
                              width='20px'
                              height='20px'
                              className='ms-2'
                            />
                          </DropdownToggle>
                          <DropdownMenu positionFixed={true}>
                            {[5, 10, 25].map((item) => (
                              <DropdownItem
                                className={holdListStyles.dropdownItem}
                                key={item}
                                onClick={() => {
                                  this.setState({
                                    pageSize: item,
                                    paginationModalOpen: false,
                                    pageNumber: 1
                                  });
                                }}
                              >
                                {item}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </Dropdown>
                      </Col>
                    </Row>
                  </>
                )}
              </ToolkitProvider>
            </Col>
          </Row>
          <Modal
            size='md'
            isOpen={this.props.keyModal}
            className={`themeStyle${this.props.currentTheme?.replace('#', '')}`}
          >
            <ModalHeader toggle={() => this.closeModal()} className={'fw-bold'}>
              Add New Certificate
            </ModalHeader>
            <ModalBody>
              <CertificateFormPage
                pageNumber={this.state.pageNumber}
                pageSize={this.state.pageSize}
                searchString={this.state.searchString}
                sortOrder={this.state.sortOrder}
              />
            </ModalBody>
          </Modal>
        </Widget>
      </>
    );
  }
}

function mapStateToProps(store) {
  return {
    currentTheme: store.layout.currentTheme,
    keyModal: store.credentials.list.keyModal,
    certificatesList: store.credentials.list.certificatesList,
    certificatesCount: store.credentials.list.certificatesCount,
    currentUser: store.auth.currentUser
  };
}

export default connect(mapStateToProps)(CertificateListTable);
