import Errors from 'components/FormItems/error/errors';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import config from '../../config';
import { Client } from '../../legal-api';
import legalCaseListActions from './LegalCaseListActions';
import caseViewerFormActions from '../viewers/LegalCaseViewerFormActions';
import { defaultOptions } from '../../services/userService';

async function doCreateLegalCase(data) {
  return await new Client(config.externalApi).legalCasePOST({
    ...data
  });
}

async function doFindLegalCase(caseId) {
  const legalCase = await new Client(config.externalApi).legalCaseGET(caseId);
  return Object.assign(legalCase);
}

async function doGetStats(caseId) {
  const legalCaseStats = await new Client(config.externalApi).legalCaseStats(caseId);
  return legalCaseStats;
}

async function getUsersByCase(legalCaseId) {
  let caseUsers = null;
  const url = `${config.externalApi}/legal/api/LegalCase/GetUsersByCaseId?legalCaseId=${legalCaseId}`;
  await fetch(url, {
    ...defaultOptions,
    method: 'GET'
  })
    .then((response) => response.json())
    .then((data) => (caseUsers = data));

  return caseUsers;
}

async function doUpdateLegalCase(caseId, data) {
  return await new Client(config.externalApi).legalCasePUT(caseId, data);
}

const actions = {
  doNew: () => {
    return {
      type: 'LEGALCASE_FORM_RESET'
    };
  },

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: 'LEGALCASE_FORM_FIND_STARTED'
      });

      doFindLegalCase(id).then((res) => {
        const record = res;

        dispatch({
          type: 'LEGALCASE_FORM_FIND_SUCCESS',
          payload: record
        });
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'LEGALCASE_FORM_FIND_ERROR'
      });
      dispatch(push('/app/LegalCase'));
    }
  },

  doGetStats: (id) => async (dispatch) => {
    try {
      dispatch({
        type: 'LEGALCASE_FORM_STATS_FIND_STARTED'
      });

      doGetStats(id).then((res) => {
        const record = res;

        dispatch({
          type: 'LEGALCASE_FORM_STATS_FIND_SUCCESS',
          payload: record
        });
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'LEGALCASE_FORM_FIND_ERROR'
      });
      dispatch(push('/app/LegalCase'));
    }
  },

  doGetUsersByCase: (legalCaseId) => async (dispatch) => {
    try {
      dispatch({
        type: 'LEGAL_CASE_USERS_FETCH_STARTED'
      });

      const response = await getUsersByCase(legalCaseId);
      dispatch({
        type: 'LEGAL_CASE_USERS_FETCH_SUCCESS',
        payload: {
          caseUsers: response
        }
      });
    } catch (error) {
      Errors.handle(error);
      dispatch(caseViewerFormActions.doCloseConfirm());
      dispatch({
        type: 'LEGAL_CASE_USERS_FETCH_ERROR'
      });
    }
  },

  doCreate: (values, pageNumber, pageSize) => async (dispatch) => {
    try {
      dispatch({
        type: 'LEGALCASE_FORM_CREATE_STARTED'
      });

      doCreateLegalCase(values).then(async (res) => {
        if (res instanceof Error) {
          dispatch({
            type: 'LEGALCASE_FORM_CREATE_ERROR'
          });
          toast.error('Something went wrong, please try again!');
        } else {
          dispatch({
            type: 'LEGALCASE_FORM_CREATE_SUCCESS'
          });
          dispatch(
            legalCaseListActions.doFetch({
              pageNumber: pageNumber,
              pageSize: pageSize
            })
          );
          toast.success('Legal Case created');
        }
        dispatch(legalCaseListActions.doCloseConfirm());
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'LEGALCASE_FORM_CREATE_ERROR'
      });
      dispatch(legalCaseListActions.doCloseConfirm());
      toast.error('Something went wrong, please try again!');
    }
  },

  doUpdate: (id, values) => async (dispatch) => {
    try {
      dispatch({
        type: 'LEGALCASE_FORM_UPDATE_STARTED'
      });

      await doUpdateLegalCase(id, values);
      dispatch({
        type: 'LEGALCASE_FORM_UPDATE_SUCCESS'
      });
      toast.success('LegalCase updated');
      dispatch(legalCaseListActions.doCloseConfirm());
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'LEGALCASE_FORM_UPDATE_ERROR'
      });
      toast.error('Something went wrong, please try again!');
      dispatch(push(`/app/LegalCase/${id}`));
    }
  }
};

export default actions;
