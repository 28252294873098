import React, { Component } from 'react';
import plusIcon from '../../../../images/icons/plus.svg';
import eyeIcon from '../../../../images/icons/eye.svg';
import actions from 'actions/LegalCase/LegalCaseListActions';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import LegalCaseFormPage from '../form/LegalCaseFormPage';
import LegalCaseStatsPage from '../../LegalCaseStats/list/LegalCaseStatsListPage';
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col
} from 'reactstrap';
import Widget from 'components/Widget';
import styles from './styles.module.scss';
import cx from 'classnames';
import Loader from '../../../Loader';
import Pagination from 'react-js-pagination';
import paginationStyles from 'components/CRUD/LegalHold/list/LegalHoldList.module.scss';
import colorFormatter from '../../../../core/colorFormatter';
import searchListActions from 'actions/search/searchListActions';
import holdFormActions from 'actions/LegalHold/LegalHoldFormActions';
import 'components/Layout/layoutStyles.css';
import searchIcon from 'images/icons/search.svg';
import caretDown from 'images/icons/caret-down.svg';
import * as dataFormat from 'components/CRUD/LegalHold/list/LegalHoldDataFormatters';

class LegalCaseListTable extends Component {
  constructor(props) {
    super(props);
    this.openModal = this.openModal.bind(this);
  }

  state = {
    searchString: '',
    newRows: [],
    totalCount: 0,
    pageNumber: 1,
    pageSize: 10,
    paginationModalOpen: false
  };

  openModal() {
    this.props.dispatch(actions.doOpenConfirm());
  }

  closeModal() {
    this.props.dispatch(actions.doCloseConfirm());
  }

  handleAddLogForCaseView = async (legalCaseId) => {
    const { dispatch } = this.props;
    dispatch(actions.doAddLogForCaseView(legalCaseId, this.props?.currentUser));
  };

  handleSearch = () => {
    const { dispatch } = this.props;
    this.setState({
      pageNumber: 1,
      widgetActiveIndex: 0
    });
    dispatch(
      actions.doFetch({
        searchString: this.state.searchString,
        pageNumber: this.state.pageNumber,
        pageSize: this.state.pageSize
      })
    ).then(() => {
      this.setState({
        totalCount: this.props.count,
        newRows: this.props.rows,
        legalCaseId: this.props.rows.length > 0 ? this.props.rows[0].legalCaseId : null
      });
    });
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(
      actions.doFetch({
        pageNumber: this.state.pageNumber,
        pageSize: this.state.pageSize
      })
    ).then(() => {
      this.setState({
        totalCount: this.props.count,
        newRows: this.props.rows
      });

      if (this.state.newRows.length > 0) {
        this.setState({
          legalCaseId: this.state.newRows[0].legalCaseId,
          widgetActiveIndex: 0
        });
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { dispatch } = this.props;
    if (
      (prevState.searchString !== this.state.searchString && this.state.searchString === '') ||
      prevState.pageNumber !== this.state.pageNumber
    ) {
      this.setState({
        widgetActiveIndex: 0
      });
      dispatch(
        actions.doFetch({
          searchString: this.state.searchString,
          pageSize: this.state.pageSize,
          pageNumber: this.state.pageNumber
        })
      ).then(() => {
        this.setState({
          newRows: this.props.rows,
          totalCount: this.props.count,
          legalCaseId:
            this.props?.rows?.length > 0
              ? this.props?.rows[this.state.widgetActiveIndex]?.legalCaseId
              : null
        });
      });
    }
    if (prevState.pageSize !== this.state.pageSize) {
      this.setState({
        widgetActiveIndex:
          this.state.widgetActiveIndex + 1 <= this.state.pageSize ? this.state.widgetActiveIndex : 0
      });
      dispatch(
        actions.doFetch({
          searchString: this.state.searchString,
          pageSize: this.state.pageSize,
          pageNumber: this.state.pageNumber
        })
      ).then(() => {
        this.setState({
          newRows: this.props.rows,
          totalCount: this.props.count,
          legalCaseId:
            this.props?.rows.length > 0
              ? this.props?.rows[this.state.widgetActiveIndex]?.legalCaseId
              : null
        });
      });
    }
    if (prevProps.rows !== this.props.rows) {
      this.setState({
        newRows: this.props.rows,
        totalCount: this.props.count
      });
    }
  }

  handlePageChange = (pageNumber) => {
    this.setState({ pageNumber: pageNumber });
  };

  caseStatusFormatter = (caseStatus) => {
    return (
      <>
        <div
          style={{
            width: 8,
            height: 8,
            backgroundColor: colorFormatter(caseStatus),
            borderRadius: '50%'
          }}
          className={'me-2 d-inline-block'}
        />
        {caseStatus}
      </>
    );
  };

  renderForm() {
    const { rows } = this.props;
    return (
      <Row>
        <Col sm={12}>
          <Widget>
            <div className='d-flex align-items-center justify-content-between'>
              <div className={'d-flex align-items-center'}>
                <h3 className={'fw-semi-bold me-4 '}>Cases</h3>
                {this.props.permissions['LegalUI.Cases.Create'] ? (
                  <button
                    className='btn fw-semi-bold d-flex align-items-center'
                    style={{
                      borderRadius: '10px',
                      marginLeft: '5px'
                    }}
                    type='button'
                    onClick={this.openModal}
                  >
                    <img src={plusIcon} alt={'plus'} className={'me-2'} />
                    <p className={'mb-0 '}>New</p>
                  </button>
                ) : null}
              </div>

              <div style={{ display: 'flex' }}>
                <input
                  type='search'
                  placeholder={'Search'}
                  style={{
                    border: '0.5px solid #0046b1',
                    borderRadius: '3px'
                  }}
                  className={'form-control search-input '}
                  value={this.state.searchString}
                  onChange={(e) => {
                    this.setState({
                      searchString: e.target.value
                    });
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      this.handleSearch();
                    }
                  }}
                />
                <button
                  className='btn search-button-with-icon me-2 ms-10'
                  type='button'
                  onClick={this.handleSearch}
                  style={{ height: '37px', width: '37px' }}
                >
                  <img title={'search'} alt={'search'} width={14} height={14} src={searchIcon} />
                </button>
              </div>
            </div>
          </Widget>
        </Col>
        <Col sm={4} className={styles.casesList}>
          <div className={styles.widgetList}>
            {this.state.newRows?.map((item, index) => {
              return (
                <Widget
                  style={{ marginBottom: index === rows.length - 1 && 0 }}
                  onClick={() => {
                    this.setState({
                      widgetActiveIndex: index,
                      legalCaseId: item.legalCaseId
                    });
                  }}
                  className={cx({
                    [styles.activeWidget]: this.state.widgetActiveIndex === index
                  })}
                  key={item.legalCaseId}
                >
                  <Row>
                    <Col sm={8}>
                      {item?.caseReferenceId && (
                        <>
                          <small
                            className={'text-muted '}
                            style={{
                              whiteSpace: 'pre-wrap',
                              overflowWrap: 'break-word',
                              wordBreak: 'break-all'
                            }}
                          >
                            Reference ID : {item?.caseReferenceId}
                          </small>
                          <span className={'text-muted pe-1 pl-1'}>--</span>
                          <small>{this.caseStatusFormatter(item?.caseStatus)}</small>
                        </>
                      )}
                    </Col>
                    <Col sm={4}>
                      {this.props.permissions['LegalUI.Cases'] ? (
                        <Link to={`/app/LegalCase/${item.legalCaseId}`}>
                          <button
                            className='btn fw-semi-bold d-flex align-items-center'
                            type='button'
                            style={{
                              height: 40,
                              width: 85,
                              float: 'right'
                            }}
                            onClick={() => {
                              this.handleAddLogForCaseView(item.legalCaseId);
                              this.props.dispatch(searchListActions.doToggleDiscardSearch());
                              this.props.dispatch(holdFormActions.doCloseConfirm());
                            }}
                          >
                            <img src={eyeIcon} alt={'plus'} className={'me-2'} />
                            <p className={'mb-0'}>Open</p>
                          </button>
                        </Link>
                      ) : null}
                    </Col>
                    <Col sm={12}>
                      <div>
                        <h5
                          className={'fw-semi-bold mb-1 d-inline-block'}
                          style={{
                            marginTop: 12,
                            whiteSpace: 'pre-wrap',
                            overflowWrap: 'break-word',
                            wordBreak: 'break-all'
                          }}
                        >
                          {item.caseName}
                        </h5>
                      </div>
                    </Col>
                  </Row>
                  <div
                    style={{
                      maxHeight: '180px',
                      display: 'flex',
                      overflow: ' hidden'
                    }}
                  >
                    <small>{item.caseDescription}</small>
                  </div>
                  <div className={'d-flex'} style={{ marginTop: 12 }}>
                    <small className={'pe-3 text-muted'}>Custodians: {item.custodianCount}</small>
                    <small className={'pe-3 text-muted'}>Holds: {item.holdCount}</small>
                    <small className={'pe-3 text-muted'}>Searches: {item.contentSearchCount}</small>
                    <small className={'pe-3 text-muted'}>Exports: {item.exportCount}</small>
                  </div>
                  <div>
                    {item.expireonUser?.firstName && (
                      <small className={'text-muted'}>
                        Created by: {item.expireonUser?.firstName} {item.expireonUser?.lastName} -{' '}
                        {dataFormat.dateTimeToStringFormatter(item?.createdTime)}{' '}
                      </small>
                    )}
                  </div>
                </Widget>
              );
            })}
          </div>
          <div className={styles.pagination}>
            {this.state.totalCount ? (
              <div>
                <p>Total: {this.state.totalCount}</p>
              </div>
            ) : null}
            <div className='d-flex justify-content-end'>
              {this.state.totalCount ? (
                <Pagination
                  totalItemsCount={this.state.totalCount}
                  onChange={this.handlePageChange}
                  activePage={this.state.pageNumber}
                  itemsCountPerPage={this.state.pageSize}
                  pageRangeDisplayed={5}
                  prevPageText={'<'}
                  nextPageText={'>'}
                  firstPageText={'<<'}
                  lastPageText={'>>'}
                  linkClassFirst={paginationStyles.linkNext}
                  linkClassPrev={paginationStyles.linkNext}
                  linkClassNext={paginationStyles.linkNext}
                  linkClassLast={paginationStyles.linkNext}
                  linkClass={paginationStyles.linkClass}
                  activeLinkClass={paginationStyles.caseActiveLinkClass}
                  activeClass={paginationStyles.caseActiveLinkClass}
                />
              ) : null}
              {this.state.totalCount ? (
                <Dropdown
                  isOpen={this.state.paginationModalOpen}
                  toggle={() =>
                    this.setState({
                      paginationModalOpen: !this.state.paginationModalOpen
                    })
                  }
                  className={paginationStyles.dropdownClass}
                >
                  <DropdownToggle className='bg-transparent text-white d-flex'>
                    <p style={{ marginBottom: '3px', marginRight: '2px' }}>{this.state.pageSize}</p>
                    <img
                      src={caretDown}
                      alt={'uncollapsed'}
                      width='20px'
                      height='20px'
                      className='ms-2'
                    />
                  </DropdownToggle>
                  <DropdownMenu positionFixed={true}>
                    {[10, 15, 25].map((item) => (
                      <DropdownItem
                        className={paginationStyles.dropdownItem}
                        key={item}
                        onClick={() => {
                          this.setState({
                            pageSize: item,
                            paginationModalOpen: false,
                            pageNumber: 1
                          });
                        }}
                      >
                        {item}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>
              ) : null}
            </div>
          </div>
        </Col>

        <Col sm={8} className={styles.statsContainer}>
          <Widget className={'h-100 d-flex justify-content-center'} bodyClass={'h-100'}>
            {!this.state?.legalCaseId ||
            this.state.legalCaseId === null ||
            this.props?.rows.length === 0 ? (
              <div
                className={'d-flex flex-column justify-content-center align-items-center'}
                style={{ height: '100%' }}
              >
                <h3>No data to display</h3>
                <h3>Select Case to see Statistics</h3>
              </div>
            ) : (
              <div className={styles.caseStatsPage}>
                <LegalCaseStatsPage
                  legalCaseId={this.props.rows[this.state.widgetActiveIndex]?.legalCaseId}
                  dispatch={this.props.dispatch}
                />
              </div>
            )}
          </Widget>
          <div>
            <Modal
              size='md'
              isOpen={this.props.modalOpen}
              className={`themeStyle${this.props.currentTheme?.replace('#', '')}`}
            >
              <ModalHeader toggle={() => this.closeModal()}>Create New Case</ModalHeader>
              <ModalBody>
                <LegalCaseFormPage
                  legalCaseId={this.state.legalCaseId}
                  pageNumber={this.state.pageNumber}
                  pageSize={this.state.pageSize}
                />
              </ModalBody>
            </Modal>
          </div>
        </Col>
      </Row>
    );
  }

  render() {
    const { loading, rows } = this.props;

    if (loading) {
      return (
        <div className={'hw-100'}>
          <Loader />
        </div>
      );
    }

    if (loading && !rows) {
      return (
        <div className={'hw-100'}>
          <Loader />
        </div>
      );
    }

    return this.renderForm();
  }
}

function mapStateToProps(store) {
  return {
    loading: store.LegalCase.list.loading,
    rows: store.LegalCase.list.rows,
    count: store.LegalCase.list.count,
    modalOpen: store.LegalCase.list.modalOpen,
    permissions: store.auth.permissions,
    currentUser: store.auth.currentUser,
    currentTheme: store.layout.currentTheme
  };
}

export default connect(mapStateToProps)(LegalCaseListTable);
