import React, { Component } from 'react';
import LegalHoldListTable from 'components/CRUD/LegalHold/list/LegalHoldListTable';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import LegalHoldTilesView from './LegalHoldTilesView';

class LegalHoldListPage extends Component {
  render() {
    return (
      <div>
        {this.props.permissions['LegalUI.LegalHold'] ? (
          <>
            {this.props.showTableView ? (
              <LegalHoldListTable
                legalCaseId={this.props.legalCaseId}
                caseStatus={this.props.caseStatus}
              />
            ) : (
              <LegalHoldTilesView
                legalCaseId={this.props.legalCaseId}
                caseStatus={this.props.caseStatus}
              />
            )}
          </>
        ) : (
          <Redirect to={'/no-access'} />
        )}
      </div>
    );
  }
}

function mapStateToProps(store) {
  return {
    permissions: store.auth.permissions,
    showTableView: store.LegalHold.list.showTableView
  };
}
export default connect(mapStateToProps)(LegalHoldListPage);
