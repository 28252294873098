import React, { Component } from 'react';
import { Formik, ErrorMessage } from 'formik';
import SelectFormItem from 'components/FormItems/items/SelectFormItem';
import Loader from 'components/Loader/Loader';
import { Button } from 'reactstrap';
import LegalCaseViewerFields from '../LegalCaseViewerFields';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import actions from 'actions/viewers/LegalCaseViewerFormActions';

class LegalCaseViewerForm extends Component {
  state = {
    users: [],
    closeModal: true
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(actions.doGetExpireonUsers(this.props?.legalCaseId));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.rows !== this.props.rows) {
      this.setState({
        users: this.props.rows
      });
    }
  }

  initialValues = () => {
    const iniValues = {
      expireonUsers: []
    };
    return iniValues;
  };

  FormValidationSchema = Yup.object().shape({
    expireonUsers: Yup.string().required('Expireon Users* is a required field')
  });

  usersToData = (data) => {
    const arr = [];
    data?.map((item) => {
      const obj = {};
      obj.value = item;
      obj.label = item['userName'];
      arr.push(obj);
    });
    return arr;
  };

  handleSubmit = (values) => {
    this.props.onSubmit(values);
  };

  renderForm() {
    return (
      <>
        <Formik
          onSubmit={this.handleSubmit}
          initialValues={this.initialValues()}
          validationSchema={this.FormValidationSchema}
          render={(form) => {
            return (
              <form onSubmit={form.handleSubmit}>
                <SelectFormItem
                  name={'expireonUsers'}
                  schema={LegalCaseViewerFields}
                  mode={'fetch'}
                  data={this.usersToData(this.state.users)}
                  isMulti={true}
                />
                <ErrorMessage name='expireonUsers'>
                  {(msg) => (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '12px',
                        marginBottom: '5px'
                      }}
                    >
                      {msg}
                    </div>
                  )}
                </ErrorMessage>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    marginTop: '25px'
                  }}
                >
                  <Button
                    color='primary'
                    style={{ width: '30%' }}
                    onClick={() => {
                      form.handleSubmit();
                    }}
                  >
                    Add
                  </Button>
                </div>
              </form>
            );
          }}
        />
      </>
    );
  }
  render() {
    const { isEditing, findLoading, record } = this.props;

    if (findLoading) {
      return <Loader />;
    }

    if (isEditing && !record) {
      return <Loader />;
    }

    return this.renderForm();
  }
}
function mapStateToProps(store) {
  return {
    rows: store.viewers.form.rows
  };
}

export default connect(mapStateToProps)(LegalCaseViewerForm);
