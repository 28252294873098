import React, { Component } from 'react';
import ExportListTable from './ExportListTable';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import ExportStatistics from '../exportStatistics/ExportStatistics';
class ExportPage extends Component {
  render() {
    return (
      <React.Fragment>
        {this.props.permissions['LegalUI.Export'] ? (
          <>
            <div style={{ marginBottom: '5%' }}>
              <ExportListTable />
            </div>

            <div>
              <ExportStatistics />
            </div>
          </>
        ) : (
          <Redirect to={'/no-access'} />
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps(store) {
  return {
    permissions: store.auth.permissions
  };
}

export default connect(mapStateToProps)(ExportPage);
