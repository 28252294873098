import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormErrors from 'components/FormItems/formErrors';
import { Field } from 'formik';
import Select from 'react-select';
import { ImInfo } from 'react-icons/im';
import EmailPolicyTooltip from 'components/CRUD/Reports/EmailPolicyTooltip';
import TagGroupTooltip from 'components/CRUD/Search/view/TagGroupTooltip';
import searchFormActions from 'actions/search/searchFormActions';

class SelectFormItemNotFast extends Component {
  state = {
    openCard: false,
    emailId: '',
    emptySpacePolicies: [],
    tagId: '',
    remainingGroups: this.props?.data,
    remaingGroupId: this.props?.lastGroup?.id
  };
  componentDidUpdate(prevProps) {
    if (prevProps.emptySpacePolicies !== this.props.emptySpacePolicies) {
      this.setState({
        emptySpacePolicies: this.props?.emptySpacePolicies
      });
    }
  }
  value = () => {
    const { form, name } = this.props;
    let options = [];
    if (this.props.data) {
      options = this.props.data;
    } else {
      options = this.props.schema[name].options;
    }
    if (
      (this.props.name === 'users' ||
        this.props.name === 'auditTypes' ||
        this.props.name === 'containerIds' ||
        this.props.name === 'attachmentExtensions') &&
      Array.isArray(form.values[name])
    ) {
      let containersArr = [];
      form.values[name].map((item) => {
        const container = options.find((option) => option.value === item);
        containersArr.push(container);
      });
      return containersArr;
    }
    // select ALL_EMAILS by default
    if (name === 'flows' && !form.values[name]) {
      return options.find((option) => option.value === 'ALL_EMAILS');
    }
    // select .All by default
    // if (name === 'attachmentExtensions' && !form.values[name]) {
    //   return options.find(
    //       (option) => option.value === '.All',
    //   );
    // }
    if (name === 'fuzzyness' && !form.values[name]) {
      return options.find((option) => option.value === 0);
    }
    if (name === 'fuzzyness' && form.values[name]) {
      return options.find((option) => option.value === form.values[name]);
    }
    if (name === 'attachmentTypes' && this.props.isDisabled) {
      return '';
    }
    if (name === 'tagsTypes' && this.props.isDisabled) {
      return '';
    }
    if (form.values[name]) {
      return options.find((option) => option.value === form.values[name]);
    }
    return '';
  };

  selectedGroups(values) {
    const allOptions = this.props.data;
    const selectedOptions = values;
    let resultArray = allOptions;
    let remaingGroupIndex = 0;
    if (values) {
      resultArray = allOptions.filter((itemA) => {
        const valueA = itemA.value;
        return !selectedOptions.some((itemB) => itemB.value === valueA);
      });
    }
    this.setState({
      remainingGroups: resultArray
    });
    for (let i = 0; i < resultArray.length; i++) {
      if (!resultArray[i]?.isTagGroup) {
        remaingGroupIndex = i - 1;
        const remainingGroupValue = resultArray[remaingGroupIndex]?.value;
        this.setState({
          remaingGroupId: remainingGroupValue
        });
        break;
      }
    }
  }

  handleSelectMultiple = (values) => {
    const { form, name, mapper } = this.props;
    if (name === 'users' && !values) {
      form.setFieldValue('userNames', []);
      form.setFieldValue(name, []);
      return;
    }
    if (name === 'auditTypes' && !values) {
      form.setFieldValue('auditRecordNames', []);
      form.setFieldValue(name, []);
      return;
    }
    if (!values) {
      form.setFieldValue(name, []);
      return;
    }
    if (name === 'users') {
      form.setFieldValue(
        'userNames',
        values.map((value) => value.label)
      );
    }
    if (name === 'auditTypes') {
      form.setFieldValue(
        'auditRecordNames',
        values.map((value) => value.label)
      );
    }

    form.setFieldValue(
      name,
      values.map((value) => value.value)
    );
  };

  handleSelect = (value) => {
    const { form, name } = this.props;
    form.setFieldTouched(name);

    if (this.props.isMulti) {
      if (this.props.isTagType) {
        this.selectedGroups(value);
      }
      return this.handleSelectMultiple(value);
    } else {
      if (name === 'legalCaseId') {
        form.setFieldValue('legalCaseName', value?.label);
      }
      form.setFieldValue(name, value?.value);
    }
  };

  render() {
    const {
      form,
      name,
      hint,
      errorMessage,
      required,
      mode,
      placeholder,
      isClearable,
      isRecord,
      isTemplate,
      isDisabled,
      showCheckbox,
      isMulti = false,
      isExportPolicy,
      isAttachment,
      isTagType
    } = this.props;

    let { label, options } = this.props.schema[name];

    if (mode === 'fetch' && this.props.data) {
      options = this.props.data;
    }

    const isInvalid = !!FormErrors.displayableError(form, name, errorMessage);

    const controlStyles = isInvalid
      ? {
          control: (provided) => ({
            ...provided,
            borderColor: 'red'
          }),
          option: (provided, state) => ({
            backgroundColor:
              this.state.emptySpacePolicies?.includes(state.value) && state.isFocused
                ? '#808080'
                : state.isFocused
                ? '#474D84'
                : '',
            height: 'auto',
            paddingLeft: '5px',
            paddingTop: '5px',
            pointerEvents:
              this.state.emptySpacePolicies?.includes(state.value) && state.isFocused
                ? 'none'
                : 'auto',
            color: 'white'
          }),
          menuList: (provided) => ({
            ...provided,
            backgroundColor: '#040620'
          }),
          input: (provided) => ({
            ...provided,
            color: 'white'
          }),
          container: (provided) => ({
            ...provided,
            paddingTop: '5px',
            paddingBottom: '5px'
          }),
          valueContainer: (provided) => ({
            ...provided,
            paddingBottom: '10px',
            color: 'white !important'
          })
        }
      : {
          control: (provided, state) => ({
            ...provided,
            backgroundColor: state.isDisabled ? '#13152b' : '#040620',
            border: 'none',
            paddingTop: '5px',
            paddingBottom: isTemplate ? '7px' : '5px',
            paddingLeft: '5px',
            overflowX: isRecord || isAttachment || isTagType ? 'auto' : 'none',
            maxHeight: isRecord ? 150 : isAttachment || isTagType ? 100 : 'none'
          }),

          placeholder: (defaultStyles, state) => ({
            ...defaultStyles,
            color:
              name === 'attachmentExtensions' && state.isDisabled
                ? '#999999'
                : name === 'attachmentExtensions' && !state.isDisabled
                ? 'white'
                : '#999999'
          }),
          option: (provided, state) => ({
            backgroundColor:
              this.state.emptySpacePolicies?.includes(state.value) && state.isFocused
                ? '#808080'
                : state.isFocused
                ? '#474D84'
                : '',
            height: 'auto',
            paddingLeft: '5px',
            paddingTop: '5px',
            paddingBottom: '5px',
            pointerEvents:
              this.state.emptySpacePolicies?.includes(state.value) && state.isFocused
                ? 'none'
                : 'auto',
            borderBottomStyle:
              state.value === this.state.remaingGroupId &&
              isTagType &&
              this.props?.data?.length !== this.props?.tagGroupsList?.length
                ? 'dashed'
                : 'none',
            borderBottomColor:
              state.value === this.state.remaingGroupId &&
              isTagType &&
              this.props?.data?.length !== this.props?.tagGroupsList?.length
                ? '#3978f6'
                : 'none',
            marginBottom: state.value === this.state.remaingGroupId && isTagType ? '5px' : '',
            color: 'white'
          }),
          menuList: (provided) => ({
            ...provided,
            backgroundColor: '#040620'
          }),
          input: (provided) => ({
            ...provided,
            color: 'white'
          }),
          container: (provided) => ({
            ...provided,
            paddingTop: '5px',
            paddingBottom: '5px'
          }),
          valueContainer: (provided) => ({
            ...provided,
            paddingBottom: '10px',
            color: 'white !important'
          })
        };

    return (
      <div className='form-group mb-1'>
        {!!label && (
          <label
            style={{ marginLeft: `${showCheckbox ? '20px' : 0}` }}
            className={`col-form-label ${required ? 'required' : null}`}
          >
            {label}
          </label>
        )}

        <br />
        <div
          style={{
            display: 'flex',
            position: 'relative',
            zIndex: 'auto',
            paddingRight: '0'
          }}
          onClick={() => {
            if (isTagType) {
              this.props.dispatch(searchFormActions.doCloseTagCard());
            }
          }}
        >
          <Select
            className='w-100'
            value={this.value()}
            isDisabled={isDisabled}
            closeMenuOnSelect={isMulti ? false : true}
            onChange={this.handleSelect}
            id={name}
            name={name}
            options={options}
            isMulti={isMulti}
            placeholder={placeholder || ''}
            isClearable={isClearable}
            styles={controlStyles}
            loadingMessage={'Loading'}
            noResultsText={'No options'}
            formatOptionLabel={(option) => {
              if (isTemplate) {
                return (
                  <div>
                    <span style={{ fontWeight: '600', display: 'flex', overflowY: 'scroll' }}>
                      {option.label.split(' * ')[0]}
                    </span>
                    {name === 'reportEmailPolicyId' && (
                      <span
                        style={{
                          position: 'relative',
                          cursor: 'pointer',
                          zIndex: 'auto'
                        }}
                        onMouseLeave={() => {
                          this.setState({
                            openCard: false
                          });
                        }}
                        onClick={(e) => {
                          this.setState({
                            openCard: true,
                            emailId: option?.value
                          });
                          e.stopPropagation();
                        }}
                      >
                        <ImInfo style={{ marginLeft: '8px' }} />
                      </span>
                    )}
                    <br />
                    <div
                      style={{
                        fontStyle: 'italic',
                        fontSize: '13px',
                        maxHeight: '55px',
                        display: 'flex',
                        overflowY: 'scroll'
                      }}
                    >
                      {option.label.split(' * ')[1]}
                    </div>
                  </div>
                );
              } else if (isExportPolicy) {
                return (
                  <>
                    {this.state.emptySpacePolicies.includes(option.value) ? (
                      <div>
                        <span>{option.label}</span>
                        <br />
                        <span
                          style={{
                            fontStyle: 'italic',
                            wordBreak: 'break-all',
                            fontSize: '12px',
                            fontColor: '#808080'
                          }}
                        >
                          (Waiting for validation)
                        </span>
                      </div>
                    ) : (
                      <div>
                        <span>{option.label}</span>
                        <br />
                      </div>
                    )}
                  </>
                );
              } else if (isTagType) {
                return (
                  <div
                    onClick={() => {
                      this.props.dispatch(searchFormActions.doCloseTagCard());
                    }}
                  >
                    <span style={{ fontWeight: '600' }}>{option.label}</span>
                    <span
                      style={{
                        position: 'relative',
                        cursor: 'pointer',
                        zIndex: 'auto'
                      }}
                    >
                      {this.props.tagGroupsList?.map((item) => {
                        if (item.id === option.value) {
                          return (
                            <ImInfo
                              onClick={(e) => {
                                this.props.dispatch(searchFormActions.doOpenTagCard());
                                this.setState({
                                  tagId: option?.value
                                });
                                e.stopPropagation();
                              }}
                              style={{ marginLeft: '8px' }}
                            />
                          );
                        }
                      })}
                    </span>
                  </div>
                );
              } else {
                return (
                  <div>
                    <span>{option.label}</span>
                    <br />
                  </div>
                );
              }
            }}
          />
        </div>
        {this.props.openTagCard && (
          <TagGroupTooltip data={this.props?.tagGroupsList} id={this.state.tagId} />
        )}
        {this.state.openCard && (
          <EmailPolicyTooltip data={this.props?.emailPoliciesList} id={this.state.emailId} />
        )}
        <div className='invalid-feedback'>
          {FormErrors.displayableError(form, name, errorMessage)}
        </div>

        {!!hint && <small className='form-text text-muted'>{hint}</small>}
      </div>
    );
  }
}

SelectFormItemNotFast.defaultProps = {
  required: false,
  isClearable: true
};

SelectFormItemNotFast.propTypes = {
  form: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  schema: PropTypes.object.isRequired,
  label: PropTypes.string,
  hint: PropTypes.string,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
  mode: PropTypes.string,
  isClearable: PropTypes.bool
};

class SelectFormItem extends Component {
  render() {
    return (
      <Field
        name={this.props.name}
        validate={this.props.validate}
        render={({ form }) => <SelectFormItemNotFast {...this.props} form={form} />}
      />
    );
  }
}

export default SelectFormItem;
