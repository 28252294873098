import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormErrors from 'components/FormItems/formErrors';
import { FastField } from 'formik';

class RadioFormItemNotFast extends Component {
  componentDidMount() {
    const { form, name } = this.props;
    form.values[name] = name === 'reportOutputFileType' ? form.values[name] : null;
  }

  render() {
    const { name, form, hint, errorMessage, required } = this.props;

    const { label, options } = this.props.schema[name];

    return (
      <div className='form-group d-flex'>
        {!!label && (
          <label className={`col-form-label me-3 ${required ? 'required' : null}`}>{label}</label>
        )}

        <br />

        {options.map((option) => (
          <div key={option.value} className='form-check form-check-inline pt-2'>
            <input
              className={`form-check-input ${FormErrors.validateStatus(form, name, errorMessage)}`}
              type='radio'
              id={`${name}-${option.value}`}
              name={`${name}-${option.value}`}
              value={option.value}
              checked={option.value === form.values[name]}
              onChange={(e) => {
                const value = () => {
                  if (e.target.value === 'true') return true;
                  if (e.target.value === 'false') return false;
                  if (e.target.value) return e.target.value;
                  return null;
                };
                form.setFieldValue(name, value());
                form.setFieldTouched(name);
              }}
            />
            <label htmlFor={`${name}-${option.value}`} className='form-check-label'>
              {option.label}
            </label>
          </div>
        ))}

        <div className='invalid-feedback'>
          {FormErrors.displayableError(form, name, errorMessage)}
        </div>

        {!!hint && <small className='form-text text-muted'>{hint}</small>}
      </div>
    );
  }
}

RadioFormItemNotFast.defaultProps = {
  required: false
};

RadioFormItemNotFast.propTypes = {
  form: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  schema: PropTypes.object.isRequired,
  label: PropTypes.string,
  hint: PropTypes.string,
  required: PropTypes.bool,
  errorMessage: PropTypes.string
};

class RadioFormItem extends Component {
  render() {
    return (
      <FastField
        name={this.props.name}
        render={({ form }) => <RadioFormItemNotFast {...this.props} form={form} />}
      />
    );
  }
}

export default RadioFormItem;
