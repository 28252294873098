import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { Provider } from 'react-redux';
import ReduxThunk from 'redux-thunk';
import * as serviceWorker from 'serviceWorker';
import createRootReducer from 'reducers';
import config from 'config';
import App from 'App';
import { createHashHistory, createBrowserHistory } from 'history';
import AuthProvider from 'utils/authProvider';
import userManager from 'services/userService';
import { composeWithDevTools } from '@redux-devtools/extension';

const history = createHashHistory();

export function getHistory() {
  return history;
}

export const store = createStore(
  createRootReducer(history),
  composeWithDevTools(applyMiddleware(routerMiddleware(history), ReduxThunk))
);

ReactDOM.render(
  <Provider store={store}>
    <AuthProvider userManager={userManager} store={store}>
      <App />
    </AuthProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
