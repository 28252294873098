import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormErrors from 'components/FormItems/formErrors';
import { Field, ErrorMessage } from 'formik';
import Select from 'react-select';
import closeIcon from '../../../images/icons/close.svg';
import { Row, Col } from 'reactstrap';
import s from 'components/CRUD/LegalHold/form/Wizard.module.scss';
import { ImInfo } from 'react-icons/im';
import LongNameTooltip from 'components/CRUD/LegalHold/form/LongNameTooltip';
class SelectByAddingFormItemNotFast extends Component {
  state = {
    selectedContainers: [],
    selectedOption: [],
    openCard: false,
    containerDescription: '',
    cardPosition: {
      top: 0,
      left: 0
    }
  };

  value = () => {
    const { form, name } = this.props;
    let options = [];
    if (this.props.data) {
      options = this.props.data;
    } else {
      options = this.props.schema[name].options;
    }
    if (
      (this.props.name === 'containerIds' || this.props.name === 'messageClass') &&
      Array.isArray(form.values[name])
    ) {
      let containersArr = [];
      form.values[name].map((item) => {
        const container = options.find((option) => option.value === item);
        containersArr.push(container);
      });
      return containersArr;
    }
    if (form.values[name]) {
      return options.find((option) => option.value === form.values[name]);
    }
    return '';
  };

  handleSelectMultiple = (values) => {
    const { form, name, mapper } = this.props;

    if (!values) {
      form.setFieldValue(name, []);
      return;
    }

    form.setFieldValue(
      name,
      values.map((value) => value.value)
    );
  };

  handleSelect = (value) => {
    const { form, name } = this.props;
    form.setFieldTouched(name);

    if (this.props.isMulti) {
      return this.handleSelectMultiple(value);
    } else {
      form.setFieldValue(name, value?.value);
    }
  };

  removeItem(value) {
    const { form, name } = this.props;
    const selectedItems = form.values[name].filter((item) => item !== value);
    form.setFieldValue(name, selectedItems);
  }

  selectedValue = () => {
    const { form, name } = this.props;
    let { label, options } = this.props.schema[name];
    if (this.props.name === 'credentialType') {
      return form.values[name];
    }
    return '';
  };

  addAll = () => {
    this.handleSelectMultiple(this.props?.data);
  };

  render() {
    const {
      form,
      name,
      hint,
      errorMessage,
      required,
      mode,
      placeholder,
      isClearable,
      widgetHeight,
      showCheckbox,
      isDisabled,
      isMulti = false,
      isContainer
    } = this.props;

    let { label, options } = this.props.schema[name];

    if (mode === 'fetch' && this.props.data) {
      options = this.props.data;
    }

    const isInvalid = !!FormErrors.displayableError(form, name, errorMessage);

    const controlStyles = isInvalid
      ? {
          control: (provided) => ({
            ...provided,
            borderColor: 'red'
          }),
          option: (provided, state) => ({
            backgroundColor: state.isFocused ? '#474D84' : '',
            minHeight: isContainer ? 50 : 33,
            maxHeight: isContainer ? 80 : 33,
            paddingLeft: '5px',
            paddingTop: '5px',
            paddingBottom: '5px'
          }),
          menuList: (provided) => ({
            ...provided,
            backgroundColor: '#040620'
          }),
          input: (provided) => ({
            ...provided,
            color: 'white'
          })
        }
      : {
          placeholder: (defaultStyles, state) => ({
            ...defaultStyles,
            color:
              name === 'messageClass' && state.isDisabled
                ? '#999999'
                : name === 'messageClass' && !state.isDisabled
                ? 'white'
                : '#999999'
          }),
          option: (provided, state) => ({
            backgroundColor: state.isFocused ? '#474D84' : '',
            minHeight: isContainer ? 50 : 33,
            maxHeight: isContainer ? 80 : 33,
            paddingLeft: '5px',
            paddingTop: '5px',
            paddingBottom: '5px'
          }),
          menuList: (provided) => ({
            ...provided,
            backgroundColor: '#040620'
          }),
          input: (provided) => ({
            ...provided,
            color: 'white'
          })
        };

    return (
      <div className='form-group'>
        {!!label && (
          <label
            style={{ marginLeft: `${showCheckbox ? '20px' : 0}` }}
            className={`col-form-label ${required ? 'required' : null}`}
          >
            {label}
          </label>
        )}
        <br />
        <Row>
          <Col sm={9}>
            <Select
              className='w-100'
              value={this.value()}
              isDisabled={isDisabled}
              defaultValue={
                this.props.name === 'fuzzyness'
                  ? { value: 0, label: 'Exact Matches' }
                  : this.value()
              }
              onChange={this.handleSelect}
              id={name}
              name={name}
              options={options}
              isMulti={isMulti}
              placeholder={placeholder || ''}
              isClearable={isClearable}
              styles={controlStyles}
              loadingMessage={'Loading'}
              noResultsText={'No options'}
              defaultInputValue={this.selectedValue()}
              controlShouldRenderValue={false}
              backspaceRemoves={false}
              closeMenuOnSelect={isMulti ? false : true}
              formatOptionLabel={(option) => {
                if (isContainer) {
                  return (
                    <div>
                      <span style={{ fontWeight: '600' }}>{option.label.split('*')[0]}</span>
                      <br />
                      <span
                        style={{
                          fontStyle: 'italic',
                          fontSize: '13px',
                          maxHeight: '35px',
                          display: 'flex',
                          overflowY: 'scroll'
                        }}
                      >
                        {option.label.split('*')[1] !== 'null'
                          ? option.label.split('*')[1]
                          : 'No description added'}
                      </span>
                    </div>
                  );
                } else {
                  return (
                    <div>
                      <span>{option.label}</span>
                      <br />
                    </div>
                  );
                }
              }}
            />
            {this.state.openCard && (
              <LongNameTooltip
                longName={this.state.containerDescription}
                position={this.state.cardPosition}
                isContainer={true}
              />
            )}
          </Col>
          <Col sm={3}>
            <button
              className='btn fw-bold'
              disabled={this.props.isDisabled}
              style={{
                width: '100%',
                height: 38
              }}
              type='button'
              onClick={this.addAll}
            >
              Add All
            </button>
          </Col>
          <ErrorMessage name={name}>
            {(msg) => <div className={s.errorMessage}>{msg}</div>}
          </ErrorMessage>
        </Row>

        <div className='invalid-feedback'>
          {FormErrors.displayableError(form, name, errorMessage)}
        </div>

        {!!hint && <small className='form-text text-muted'>{hint}</small>}

        <div
          style={{
            height: widgetHeight,
            background: '#13152b',
            overflowY: 'auto'
          }}
          className={'p-2 mt-2'}
        >
          {this.props.form.values[name]
            ? this.props.form.values[name].map((_item, index) => {
                return (
                  <div className={s.optionSelected}>
                    <span
                      style={{
                        background: '#474d84',
                        display: 'inline-block',
                        marginBottom: '5px',
                        whiteSpace: 'pre-wrap',
                        overflowWrap: 'break-word',
                        wordBreak: 'break-all'
                      }}
                      className={'ps-2 pt-1 pb-1 pe-1'}
                      key={index}
                    >
                      {isContainer && (
                        <span
                          style={{
                            position: 'relative',
                            cursor: 'pointer',
                            zIndex: 'auto'
                          }}
                          onMouseLeave={() => {
                            this.setState({
                              openCard: false,
                              cardPosition: {
                                top: 0,
                                left: 0
                              }
                            });
                          }}
                          onClick={(e) => {
                            this.setState({
                              openCard: true,
                              cardPosition: {
                                top: e.pageY,
                                left: e.pageX
                              },
                              containerDescription: options
                                .find((elm) => elm.value === _item)
                                ?.label?.split('*')[1]
                            });
                            e.stopPropagation();
                          }}
                        >
                          <ImInfo style={{ marginRight: '8px' }} />
                          {options.find((elm) => elm.value === _item).label.split('*')[0]}
                        </span>
                      )}
                      {!isContainer && options.find((elm) => elm.value === _item).label}
                      <button
                        className={'me-1 ps-0 pe-0'}
                        style={{ background: 'transparent', border: 'none' }}
                        onClick={() => this.removeItem(_item)}
                        type='button'
                      >
                        <img src={closeIcon} alt={'close'} />
                      </button>
                    </span>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    );
  }
}

SelectByAddingFormItemNotFast.defaultProps = {
  required: false,
  isClearable: true
};

SelectByAddingFormItemNotFast.propTypes = {
  form: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  schema: PropTypes.object.isRequired,
  label: PropTypes.string,
  hint: PropTypes.string,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
  mode: PropTypes.string,
  isClearable: PropTypes.bool
};

class SelectByAddingFormItem extends Component {
  render() {
    return (
      <Field
        name={this.props.name}
        key={this.props.name}
        validate={this.props.validate}
        render={({ form }) => (
          <SelectByAddingFormItemNotFast {...this.props} form={form} key={this.props.name} />
        )}
      />
    );
  }
}

export default SelectByAddingFormItem;
