import auth from 'reducers/auth';
import alerts from 'reducers/auth';
import navigation from 'reducers/navigation';
import layout from 'reducers/layout';
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import LegalCase from 'reducers/LegalCase/LegalCaseReducers';
import LegalHold from 'reducers/LegalHold/LegalHoldReducers';
import search from 'reducers/search/searchReducers';
import legalCaseStats from 'reducers/legalCaseStats/legalCaseStatsReducers';
import credentials from 'reducers/credentials/credentialReducers';
import Export from 'reducers/Export/ExportReducers';
import viewers from './viewers/viewersReducers';
import AuditLogs from 'reducers/AuditLogs/AuditLogsReducers';
import Dashboard from 'reducers/Dashboard/DashboardReducers';
import Reports from 'reducers/Reports/ReportReducers';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    layout,
    alerts,
    auth,
    navigation,
    LegalCase,
    LegalHold,
    search,
    legalCaseStats,
    credentials,
    Export,
    viewers,
    AuditLogs,
    Dashboard,
    Reports
  });
