import React, { Component } from "react";
import SearchForm from "components/CRUD/Search/form/SearchForm";
import actions from "actions/search/searchFormActions";
import { connect } from "react-redux";

class SearchFormPage extends Component {
  state = {
    dispatched: false,
    hasUsedBuilder: false,
  };

  componentDidMount() {
    const { dispatch } = this.props;
    this.setState({ dispatched: true });
    dispatch(actions.doGetFuzzynessTypes());
    dispatch(actions.doFindContentSearchNames(this.props.legalCaseId));
    if (this.isDiscarded()) {
      let query = localStorage.getItem(
        `${this.props.contentSearch?.name}-${this.props.legalCaseId}`
      );
      if (query) {
        query = JSON.parse(query);
        let usedBuilder = query[0]?.rules[0]?.inputString !== "" ? true : false;
        this.setState({
          hasUsedBuilder: usedBuilder,
        });
      }
    }
  }

  doSubmit = (id, data) => {
    const { dispatch } = this.props;
    const {
      name: firstName,
      surName: lastName,
      email,
      userName,
    } = this.props.currentUser || {};
    dispatch(
      actions.doCreate(
        { ...data, legalCaseId: this.props.legalCaseId },
        {
          expireonUser: {
            firstName,
            lastName,
            email,
            userName,
          },
        }
      )
    );
  };

  isDiscarded = () => {
    return this.props.isDiscarded;
  };

  render() {
    return (
      <React.Fragment>
        {this.state.dispatched && (
          <SearchForm
            saveLoading={this.props.saveLoading}
            findLoading={this.props.findLoading}
            currentUser={this.props.currentUser}
            record={
              this.isDiscarded()
                ? {
                    ...this.props.contentSearch,
                    usedBuilder: this.state.hasUsedBuilder,
                  }
                : {}
            }
            contentSearch={this.props.contentSearch}
            onSubmit={this.doSubmit}
            legalCaseId={this.props.legalCaseId}
            isDiscarded={this.props.isDiscarded}
            fuzzynessTypes={this.props.fuzzynessTypes}
            searchNames={this.props.searchNames}
            dispatch={this.props.dispatch}
          />
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps(store) {
  return {
    findLoading: store.search.form.findLoading,
    saveLoading: store.search.form.saveLoading,
    record: store.search.form.record,
    currentUser: store.auth.currentUser,
    contentSearch: store.search.form.contentSearch,
    isDiscarded: store.search.list.isDiscarded,
    fuzzynessTypes: store.search.form.fuzzynessTypes,
    searchNames: store.search.form.searchNames,
  };
}

export default connect(mapStateToProps)(SearchFormPage);
