import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route, withRouter } from 'react-router';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Hammer from 'rc-hammerjs';
import Header from '../Header';
import Sidebar from '../Sidebar';
import { openSidebar, closeSidebar } from '../../actions/navigation';
import s from './Layout.module.scss';
import LegalCaseFormPage from '../CRUD/LegalCase/form/LegalCaseFormPage';
import LegalCaseListPage from '../CRUD/LegalCase/list/LegalCaseListPage';
import LegalCaseViewPage from '../CRUD/LegalCase/view/LegalCaseViewPage';
import LegalHoldFormPage from '../CRUD/LegalHold/form/LegalHoldFormPage';
import LegalHoldListPage from '../CRUD/LegalHold/list/LegalHoldListPage';
import LegalHoldViewPage from '../CRUD/LegalHold/view/LegalHoldViewPage';
import SearchViewPage from '../CRUD/Search/view/SearchViewPage';
import SearchDetailsPage from 'components/CRUD/Search/details/SearchDetailsPage';
import Dashboard from 'pages/dashboard/Dashboard';
import SettingsPage from 'pages/settings/SettingsPage';
import CredentialListPage from 'pages/credentials/CredentialListPage';
import ExportPage from 'components/CRUD/Export/list/ExportPage';
import ExportDetailsPage from 'components/CRUD/Export/details/ExportDetailsPage';
import PermissionRoute from 'routes/PermissionRoute';
import ClosedPage from '../CRUD/LegalCase/closedLegalCasesList/ClosedPage';
import ActivityPage from 'components/CRUD/ActivityPage/ActivityPage';
import AuditPage from 'components/CRUD/AuditLogs/AuditPage';
import ReportsPage from 'components/CRUD/Reports/ReportsPage';
import ClosedLegalCaseViewPage from '../CRUD/LegalCase/closedLegalCaseView/ClosedLegalCaseViewPage';
import layoutActions from 'actions/layout';
import 'styles/themeStyles.scss';
class Layout extends React.Component {
  static propTypes = {
    sidebarStatic: PropTypes.bool,
    sidebarOpened: PropTypes.bool,
    dispatch: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.handleSwipe = this.handleSwipe.bind(this);
  }

  handleSwipe(e) {
    if ('ontouchstart' in window) {
      if (e.direction === 4 && !this.state.chatOpen) {
        this.props.dispatch(openSidebar());
        return;
      }

      if (e.direction === 2 && this.props.sidebarOpened) {
        this.props.dispatch(closeSidebar());
        return;
      }

      this.setState({ chatOpen: e.direction === 2 });
    }
  }
  componentDidMount() {
    this.props.dispatch(layoutActions.doGetThemeColor(this.props?.currentUser?.userName));
  }
  render() {
    return (
      <div
        className={[
          s.root,
          `theme-${this.props.currentTheme ? this.props.currentTheme.replace('#', '') : '333964'}`,
          'light-blue-dashboard',
          'sidebar-' + this.props.sidebarPosition,
          'sidebar-' + this.props.sidebarVisibility
        ].join(' ')}
      >
        <Sidebar />
        <div className={s.wrap}>
          <Header />
          <Sidebar />
          <Hammer onSwipe={this.handleSwipe}>
            <main className={s.content}>
              <TransitionGroup>
                <CSSTransition key={this.props.location.key} classNames='fade' timeout={200}>
                  <Switch>
                    <Route path={'/app/settings'} exact component={SettingsPage} />

                    <Route path={'/app/Export'} exact component={ExportPage} />
                    <Route path={'/app/Export/:id'} exact component={ExportDetailsPage} />

                    <Route path={'/app/CaseManager'} exact component={ClosedPage} />
                    <Route path='/app/Activity' exact component={ActivityPage} />
                    <Route path='/app/Audit' exact component={AuditPage} />
                    <Route path='/app/Reports' exact component={ReportsPage} />
                    <Route path='/app/Dashboard' exact component={Dashboard} />

                    <Route path={'/app/LegalCase'} exact component={LegalCaseListPage} />
                    <Route path={'/app/LegalCase/new'} exact component={LegalCaseFormPage} />
                    <Route path={'/app/LegalCase/:id/edit'} exact component={LegalCaseFormPage} />
                    <Route path={'/app/LegalCase/:id'} exact component={LegalCaseViewPage} />
                    <Route
                      path={'/app/ClosedLegalCase/:id'}
                      exact
                      component={ClosedLegalCaseViewPage}
                    />

                    <Route path={'/app/LegalHold'} exact component={LegalHoldListPage} />
                    <Route path={'/app/LegalHold/:id/edit'} exact component={LegalHoldFormPage} />
                    <Route path={'/app/LegalHold/:id'} exact component={LegalHoldViewPage} />

                    <PermissionRoute
                      path={'/admin/credentials'}
                      exact
                      component={CredentialListPage}
                      isPermitted={this.props.permissions['LegalUI.Credential']}
                    />
                    <Route path={'/app/search/:id/:caseId'} exact component={SearchViewPage} />
                    <Route path={'/app/search/:id'} exact component={SearchDetailsPage} />
                  </Switch>
                </CSSTransition>
              </TransitionGroup>
            </main>
          </Hammer>
        </div>
      </div>
    );
  }
}

function mapStateToProps(store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened,
    sidebarPosition: store.navigation.sidebarPosition,
    sidebarVisibility: store.navigation.sidebarVisibility,
    permissions: store.auth.permissions,
    themeColor: store.layout.themeColor,
    currentUser: store.auth.currentUser,
    currentTheme: store.layout.currentTheme
  };
}

export default withRouter(connect(mapStateToProps)(Layout));
