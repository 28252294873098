import React, { useEffect } from "react";
import { signinRedirectCallback } from "../../../services/userService";
import { useHistory } from "react-router-dom";
import Loader from "components/Loader/Loader";
import { getConfigFromApi } from "../../../services/apiService";
import { getCurrentUser } from "../../../actions/auth";
import { store } from "index";

function SigninOidc() {
  const history = useHistory();
  useEffect(() => {
    async function signinAsync() {
      await signinRedirectCallback();
      let configData = await getConfigFromApi();
      let currentUser = configData.currentUser;
      let permissions = configData.auth.grantedPolicies;
      store.dispatch(getCurrentUser(currentUser, permissions));
      let hasPermission = Object.keys(permissions).length === 2 &&
          permissions["LegalUI.Credential"] &&
          permissions["LegalUI.Credential.KeyManagement"];

      if (hasPermission) {
        history.push("/admin/credentials");
      } else if (Object.keys(permissions).length > 0) {
        history.push("/");
      } else {
        history.push("/denied");
      }
    }
    signinAsync();
  }, [history]);

  return <Loader />;
}

export default SigninOidc;
