import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Alert, Col, Container, Row } from 'reactstrap';
// import { loginUser, receiveToken, doInit } from "actions/auth";
import microsoft from '../../../images/microsoft.png';
import { push } from 'connected-react-router';
import logo from '../../../images/expireon-logo.png';
import cloudficientLogo from '../../../images/cloudficientLogo.png';
import remadLogo from '../../../images/remad-logo.png';
import { signinRedirect } from '../../../services/userService';
import { getWelcomeMessage } from 'actions/auth';
import s from './Login.module.scss';
import { Redirect } from 'react-router';

class Login extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired
  };
  state = {
    welcomeMessage: ''
  };
  buttonstyle = {
    backgroundColor: 'red',
    fontWeight: 'bold'
  };

  constructor(props) {
    super(props);

    this.doLogin = this.doLogin.bind(this);
    this.googleLogin = this.googleLogin.bind(this);
    this.microsoftLogin = this.microsoftLogin.bind(this);
    this.changeEmail = this.changeEmail.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.signUp = this.signUp.bind(this);
  }

  componentDidMount() {
    getWelcomeMessage().then((res) => {
      this.setState({
        welcomeMessage: res.data
      });
    });
    this.setState({
      currentYear: new Date().getFullYear()
    });
  }

  changeEmail(event) {
    this.setState({ email: event.target.value });
  }

  changePassword(event) {
    this.setState({ password: event.target.value });
  }

  doLogin(e) {
    e.preventDefault();
    signinRedirect();
  }

  googleLogin() {
    //this.props.dispatch(loginUser({ social: "google" }));
  }

  microsoftLogin() {
    //this.props.dispatch(loginUser({ social: "microsoft" }));
  }

  signUp() {
    this.props.dispatch(push('/register'));
  }

  render() {
    return (
      <>
        {this.props?.currentUser?.isAuthenticated ? (
          <Redirect to='/app/Dashboard' />
        ) : (
          <div
            className={[
              s.root,
              `theme-${
                localStorage.getItem('themeColor')
                  ? localStorage.getItem('themeColor').replace('#', '')
                  : '333964'
              }`,
              'light-blue-dashboard'
            ].join(' ')}
          >
            <Container>
              <div className='widget-auth mx-auto'>
                <div
                  style={{ width: '50%', padding: '70px 0' }}
                  className={'d-flex align-items-center flex-column justify-content-center'}
                >
                  <div className={'d-flex justify-content-center'} style={{ marginBottom: 'auto' }}>
                    <img src={logo} width={'50%'} alt={'Expireon'} />
                  </div>
                  <p className={'text-center text-white mt-4'}>powered by</p>
                  <a
                    className='d-block text-center mb-4'
                    href='https://www.cloudficient.com/'
                    target={'_blank'}
                  >
                    <img src={cloudficientLogo} width='50%' alt={'cloudficient logo'} />
                  </a>
                </div>
                <form onSubmit={this.doLogin} style={{ width: '50%' }}>
                  {this.props.errorMessage && (
                    <Alert className='alert-sm widget-middle-overflow rounded-0' color='danger'>
                      {this.props.errorMessage}
                    </Alert>
                  )}
                  <div
                    className='bg-widget h-100 align-items-center justify-content-center'
                    style={{ margin: 'auto', padding: '130px 30px' }}
                  >
                    <Row className='align-items-center justify-content-center mb-5'>
                      <Col style={{ textAlign: 'center' }}>
                        <span>{this.state.welcomeMessage}</span>
                      </Col>
                    </Row>
                    <Row className='mt-5'>
                      <Col className='d-flex align-items-center flex-column justify-content-center'>
                        <button
                          onClick={(e) => this.doLogin(e)}
                          type='button'
                          style={{ width: '150px' }}
                          className='btn fw-semi-bold d-flex justify-content-center'
                        >
                          {this.props.isFetching ? 'Loading...' : 'Go to Log-in'}
                        </button>
                      </Col>
                    </Row>
                  </div>
                </form>
              </div>
            </Container>
            <footer className='auth-footer'>
              &copy; 2021-{this.state.currentYear} by cloudficient Software AG, Switzerland
            </footer>
            <a href='https://www.cloudficient.com/technology/remad-platform' target={'_blank'}>
              <img
                src={remadLogo}
                alt={'remad logo'}
                style={{
                  position: 'absolute',
                  right: 20,
                  bottom: 20,
                  width: 200
                }}
              />
            </a>
          </div>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.auth.isFetching,
    isAuthenticated: state.auth.isAuthenticated,
    errorMessage: state.auth.errorMessage,
    currentUser: state.auth.currentUser
  };
}

export default withRouter(connect(mapStateToProps)(Login));
