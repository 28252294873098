import React, { Component } from 'react';
import { Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row, Button } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import actions from 'actions/AuditLogs/AuditLogsListActions';
import { connect } from 'react-redux';
import Widget from 'components/Widget/Widget';
import * as dataFormat from 'components/CRUD/LegalHold/list/LegalHoldDataFormatters';
import Pagination from 'react-js-pagination';
import styles from 'components/CRUD/LegalHold/list/LegalHoldList.module.scss';
import { Formik } from 'formik';
import SelectFormItem from 'components/FormItems/items/SelectFormItem';
import caseFormActions from 'actions/LegalCase/LegalCaseFormActions';
import caseActions from 'actions/LegalCase/LegalCaseListActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import auditStyles from './Audit.module.scss';
import { emptyDataMessage } from 'actions/common';
import config from 'config';
import caretDown from 'images/icons/caret-down.svg';
import caretUp from 'images/icons/caret-up.svg';

class AuditLogsTable extends Component {
  state = {
    totalCount: 0,
    pageNumber: 1,
    pageSize: 10,
    newRows: [],
    sort: '',
    legalCaseId: '',
    simplifiedCases: [],
    auditRecords: [],
    showFilters: false,
    caseUsers: [],
    expandExport: false
  };

  sortFormatter(sortField) {
    const SortEnum = {
      legalCaseName: 'casename',
      recordDateUtc: 'recorddate',
      auditType: 'audittype',
      userName: 'username'
    };
    return SortEnum[sortField];
  }

  handlePageChange = (pageNumber) => {
    this.setState({ pageNumber: pageNumber });
  };

  handleTableChange = (type, { page, sortField, sortOrder }) => {
    if (type === 'sort') {
      this.setState({
        pageNumber: 1,
        sort: this.sortFormatter(sortField).concat('_').concat(sortOrder)
      });
    }
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(
      actions.doFetchAuditLogsActivity({
        pageNumber: this.state.pageNumber,
        pageSize: this.state.pageSize,
        sortOrder: this.state.sort,
        loggedUser: this.props?.currentUser?.userName
      })
    ).then(() => {
      this.setState({
        totalCount: this.props.count,
        newRows: this.props.activityRows
      });
    });
    dispatch(actions.doGetAuditRecords()).then(() => {
      this.setState({
        auditRecords: this.props.auditRecords
      });
    });

    dispatch(caseActions.doGetSimplifiedCases()).then(() => {
      this.setState({
        simplifiedCases: this.props.simplifiedCases
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { dispatch } = this.props;
    if (
      prevState.pageSize !== this.state.pageSize ||
      prevState.pageNumber !== this.state.pageNumber ||
      prevState.sort !== this.state.sort
    ) {
      dispatch(
        actions.doFetchAuditLogsActivity({
          pageSize: this.state.pageSize,
          pageNumber: this.state.pageNumber,
          sortOrder: this.state.sort,
          loggedUser: this.props?.currentUser?.userName,
          legalCaseId: this.state.legalCaseId,
          users: this.state.users,
          auditTypes: this.state.auditTypes
        })
      ).then(() => {
        this.setState({
          newRows: this.props.activityRows,
          totalCount: this.props.count
        });
      });
    }
    if (prevState.legalCaseId !== this.state.legalCaseId && this.state.legalCaseId !== '') {
      dispatch(caseFormActions.doGetUsersByCase(this.state.legalCaseId)).then(() => {
        this.setState({
          caseUsers: this.props?.caseUsers
        });
      });
    }
  }

  renameData = (data, type) => {
    const arr = [];
    if (Array.isArray(data) && type === 'cases') {
      for (let key of data) {
        const obj = {};
        obj.value = key?.legalCaseId;
        obj.label = key?.caseName;
        arr.push(obj);
      }
    }
    if (Array.isArray(data) && type === 'records') {
      for (let key of data) {
        const obj = {};
        obj.value = key.value;
        obj.label = key.key;
        arr.push(obj);
      }
    }
    if (Array.isArray(data) && type === 'users') {
      for (let key of data) {
        const obj = {};
        obj.value = key.email;
        obj.label = key.firstName;
        arr.push(obj);
      }
    }
    return arr;
  };

  toggleFilters() {
    this.setState({
      showFilters: !this.state.showFilters
    });
  }

  handleSimplifiedCaseSelect = (form) => {
    if (this.state?.legalCaseId !== form?.values?.legalCaseId) {
      this.setState({
        legalCaseId: form?.values?.legalCaseId
      });
      form.setFieldValue('users', []);
    }
  };

  resetHandler = () => {
    const { dispatch } = this.props;
    this.setState({
      legalCaseId: '',
      users: [],
      auditTypes: undefined,
      caseUsers: []
    });
    dispatch(
      actions.doFetchAuditLogsActivity({
        pageNumber: this.state.pageNumber,
        pageSize: this.state.pageSize,
        sortOrder: this.state.sort,
        loggedUser: this.props?.currentUser?.userName
      })
    ).then(() => {
      this.setState({
        totalCount: this.props.count,
        newRows: this.props.activityRows
      });
    });
  };

  handleSubmit = (values) => {
    const { dispatch } = this.props;
    this.setState({
      pageNumber: 1,
      legalCaseId: values?.legalCaseId,
      users: values?.users,
      auditTypes: values?.auditTypes
    });
    const params = {
      pageNumber: this.state.pageNumber,
      pageSize: this.state.pageSize,
      sortOrder: this.state.sort,
      legalCaseId: this.state.legalCaseId,
      users: this.state.users,
      auditTypes: this.state.auditTypes,
      loggedUser: this.props?.currentUser?.userName
    };
    dispatch(actions.doFetchAuditLogsActivity(params)).then(() => {
      this.setState({
        totalCount: this.props.count,
        newRows: this.props.activityRows
      });
    });
  };

  toggleExport = () => {
    this.setState({
      expandExport: !this.state.expandExport
    });
  };

  exportMenu = () => {
    return (
      <Dropdown
        isOpen={this.state.expandExport}
        toggle={this.toggleExport}
        className={`${styles.exportButton}`}
      >
        <DropdownToggle
          className={`${styles.exportButton} bg-transparent text-white mb-0`}
          disabled={this.state.totalCount === 0}
        >
          Export
          {!this.state.expandExport ? (
            <img src={caretDown} alt={'uncollapsed'} width='20px' height='20px' className='ms-2' />
          ) : (
            <img src={caretUp} alt={'collapsed'} width='20px' height='20px' className='ms-2' />
          )}
        </DropdownToggle>
        <DropdownMenu className='p-0 bg-transparent'>
          <DropdownItem className={`${styles.exportItem}`} onClick={this.handleExportCSV}>
            CSV
          </DropdownItem>
          <DropdownItem className={`${styles.exportItem}`} onClick={this.handleExportPDF}>
            PDF
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  };

  columnsToExport = ['legalCaseName', 'recordDateUtc', 'auditType', 'userName'];

  handleExportCSV = () => {
    const { dispatch } = this.props;
    const url = `${config.externalApi}/legal/api/AuditLogs/ExportToCsv?`;
    const loggedUser = this.props?.currentUser?.userName;
    const columnHeaders = this.columnsToExport.join(',');
    dispatch(
      actions.handleExportToCSV(
        url,
        loggedUser,
        this.state.legalCaseId,
        this.state.users,
        this.state.auditTypes,
        columnHeaders
      )
    );
  };

  handleExportPDF = () => {
    const { dispatch } = this.props;
    const url = `${config.externalApi}/legal/api/AuditLogs/ExportToPdf?`;
    const loggedUser = this.props?.currentUser?.userName;
    const columnHeaders = this.columnsToExport.join(',');
    dispatch(
      actions.handleExportToPDF(
        url,
        loggedUser,
        this.state.legalCaseId,
        this.state.users,
        this.state.auditTypes,
        columnHeaders
      )
    );
  };
  render() {
    const columns = [
      {
        dataField: 'legalCaseName',
        text: 'Legal Case Name',
        sort: true,
        formatter: (cell, row) => <div className={auditStyles.activityCaseName}>{cell}</div>
      },
      {
        dataField: 'recordDateUtc',
        text: 'Record Date',
        sort: true,
        formatter: dataFormat.dateTimeFormatter
      },
      {
        dataField: 'auditType',
        text: 'Record Type',
        sort: true
      },
      {
        dataField: 'userName',
        text: 'User',
        sort: true
      }
    ];
    return (
      <>
        <Widget
          style={{
            marginTop: '20px',
            width: '100%'
          }}
        >
          <ToolkitProvider
            bootstrap4
            keyField='auditEntryId'
            columns={columns}
            data={this.state.newRows}
          >
            {(props) => (
              <>
                <Row sm={12} className='mb-4'>
                  <Col sm={12} className={auditStyles.auditTableHeader}>
                    <h3 className={'fw-semi-bold me-4 mb-0'}>Activity</h3>
                    <div className='d-flex'>
                      <div style={{ marginRight: '5px' }}>{this.exportMenu()}</div>
                      <Button
                        color={this.state.showFilters ? 'primary' : 'dark'}
                        onClick={() => this.toggleFilters()}
                        className='btn'
                      >
                        <FontAwesomeIcon icon={faFilter} />
                      </Button>
                    </div>
                  </Col>
                </Row>
                {this.state.showFilters && (
                  <Formik
                    onSubmit={this.handleSubmit}
                    onReset={this.resetHandler}
                    initialValues={{
                      users: undefined,
                      legalCaseId: '',
                      auditTypes: undefined
                    }}
                    render={(form) => {
                      return (
                        <form onSubmit={form.handleSubmit} onReset={form.handleReset}>
                          <Widget>
                            <Row>
                              <Col>
                                <SelectFormItem
                                  name={'legalCaseId'}
                                  schema={{
                                    legalCaseId: {
                                      type: 'string',
                                      label: 'Cases'
                                    }
                                  }}
                                  placeholder={'Select Case'}
                                  onChange={this.handleSimplifiedCaseSelect(form)}
                                  mode={'fetch'}
                                  isClearable={false}
                                  data={this.renameData(this.props?.simplifiedCases, 'cases')}
                                />
                              </Col>
                              <Col>
                                <SelectFormItem
                                  name={'users'}
                                  schema={{
                                    users: {
                                      type: 'string',
                                      label: 'Users'
                                    }
                                  }}
                                  isMulti={true}
                                  placeholder={'Select Users'}
                                  mode={'fetch'}
                                  data={this.renameData(this.state?.caseUsers, 'users')}
                                  isRecord={true}
                                />
                              </Col>
                              <Col>
                                <SelectFormItem
                                  name={'auditTypes'}
                                  schema={{
                                    auditTypes: {
                                      type: 'auditTypes',
                                      label: 'Audit Types'
                                    }
                                  }}
                                  isMulti={true}
                                  placeholder={'Select Audit Types'}
                                  mode={'fetch'}
                                  data={this.renameData(this.props?.auditRecords, 'records')}
                                  isRecord={true}
                                />
                              </Col>
                              <span className={auditStyles.resetButton} onClick={form.handleReset}>
                                <button className='btn' type='button'>
                                  Reset
                                </button>
                              </span>
                              <span className={auditStyles.resetButton} onClick={form.handleSubmit}>
                                <button className='btn ' type='button'>
                                  Search
                                </button>
                              </span>
                            </Row>
                          </Widget>
                        </form>
                      );
                    }}
                  />
                )}
                <div className='table-container'>
                  <BootstrapTable
                    bordered={false}
                    classes={`table-striped table-hover fs-sm custom-table`}
                    remote={{
                      filter: false,
                      pagination: false,
                      sort: true,
                      cellEdit: false
                    }}
                    onTableChange={this.handleTableChange}
                    noDataIndication={emptyDataMessage}
                    {...props.baseProps}
                  />
                </div>
                <Row key={'pagination'}>
                  <Col>
                    <p className={styles.totalCount}>Total: {this.state.totalCount}</p>
                  </Col>
                  <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {this.state.totalCount ? (
                      <Pagination
                        totalItemsCount={this.state.totalCount}
                        onChange={this.handlePageChange}
                        activePage={this.state.pageNumber}
                        itemsCountPerPage={this.state.pageSize}
                        pageRangeDisplayed={5}
                        prevPageText={'<'}
                        nextPageText={'>'}
                        firstPageText={'<<'}
                        lastPageText={'>>'}
                        linkClassFirst={styles.paginationNext}
                        linkClassPrev={styles.paginationNext}
                        linkClassNext={styles.paginationNext}
                        linkClassLast={styles.paginationNext}
                        linkClass={styles.pageLink}
                        activeLinkClass={styles.activeLinkClass}
                      />
                    ) : null}
                    <Dropdown
                      isOpen={this.state.paginationModalOpen}
                      toggle={() =>
                        this.setState({
                          paginationModalOpen: !this.state.paginationModalOpen
                        })
                      }
                      className={styles.pageSizeDropdown}
                    >
                      <DropdownToggle className='bg-transparent text-white d-flex'>
                        <p style={{ marginBottom: '3px', marginRight: '2px' }}>
                          {this.state.pageSize}
                        </p>
                        <img
                          src={caretDown}
                          alt={'uncollapsed'}
                          width='20px'
                          height='20px'
                          className='ms-2'
                        />
                      </DropdownToggle>
                      <DropdownMenu positionFixed={true}>
                        {[10, 15, 25].map((item) => (
                          <DropdownItem
                            className={styles.dropdownItem}
                            key={item}
                            onClick={() => {
                              this.setState({
                                pageSize: item,
                                paginationModalOpen: false,
                                pageNumber: 1
                              });
                            }}
                          >
                            {item}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </Col>
                </Row>
              </>
            )}
          </ToolkitProvider>
        </Widget>
      </>
    );
  }
}

function mapStateToProps(store) {
  return {
    count: store.AuditLogs.list.count,
    currentUser: store.auth.currentUser,
    caseUsers: store.LegalCase.form.caseUsers,
    simplifiedCases: store.LegalCase.list.simplifiedCases,
    auditRecords: store.AuditLogs.list.auditRecords,
    activityRows: store.AuditLogs.list.activityRows
  };
}

export default connect(mapStateToProps)(AuditLogsTable);
