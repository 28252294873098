import Errors from 'components/FormItems/error/errors';
import config from '../../config';
import { toast } from 'react-toastify';
import { downloadService } from 'services/downloadService';
import { defaultOptions } from '../../services/userService';
import { isLegalAdmin } from 'utils/isLegalAdmin';
import { store } from 'index';
async function list(
  searchString,
  pageNumber,
  pageSize,
  sortOrder,
  legalCaseId,
  users,
  auditTypes,
  from,
  to,
  loggedUser,
  currentFilter
) {
  const currentUser = store.getState().auth?.currentUser;
  const legalAdmin = isLegalAdmin(currentUser);
  let baseUrl = `${config.externalApi}/legal/api/AuditLogs?`;
  if (loggedUser !== undefined && loggedUser !== null) {
    baseUrl += 'LoggedUser=' + encodeURIComponent('' + loggedUser) + '&';
  }
  if (pageNumber !== undefined && pageNumber !== null) {
    baseUrl += 'PageNumber=' + encodeURIComponent('' + pageNumber) + '&';
  }
  if (pageSize !== undefined && pageSize !== null) {
    baseUrl += 'PageSize=' + encodeURIComponent('' + pageSize) + '&';
  }
  if (searchString !== undefined && searchString !== '' && searchString !== null) {
    baseUrl += 'SearchString=' + encodeURIComponent('' + searchString) + '&';
  }
  if (currentFilter !== undefined && currentFilter !== '' && currentFilter !== null) {
    baseUrl += 'CurrentFilter=' + encodeURIComponent('' + currentFilter) + '&';
  }
  if (sortOrder !== undefined && sortOrder !== '' && sortOrder !== null) {
    baseUrl += 'SortOrder=' + encodeURIComponent('' + sortOrder) + '&';
  }
  if (legalCaseId !== undefined && legalCaseId !== '' && legalCaseId !== null) {
    baseUrl += 'LegalCaseId=' + encodeURIComponent('' + legalCaseId) + '&';
  }
  if (users && users.length > 0 && !users?.includes('all')) {
    for (let item of users) {
      baseUrl += 'Users=' + encodeURIComponent('' + item) + '&';
    }
  }
  if (auditTypes && auditTypes.length > 0 && !auditTypes?.includes('all')) {
    for (let item of auditTypes) {
      baseUrl += 'AuditTypes=' + encodeURIComponent('' + item) + '&';
    }
  }
  if (from !== undefined && from !== '' && from !== null) {
    baseUrl += 'From=' + encodeURIComponent('' + from) + '&';
  }
  if (to !== undefined && to !== '' && to !== null) {
    baseUrl += 'To=' + encodeURIComponent('' + to) + '&';
  }
  if (legalAdmin !== undefined && legalAdmin !== null) {
    baseUrl += 'IsLegalAdmin=' + encodeURIComponent('' + legalAdmin) + '&';
  }
  baseUrl = baseUrl.replace(/[?&]$/, '');
  const response = await fetch(baseUrl, {
    ...defaultOptions,
    method: 'GET'
  });

  if (!response.ok) {
    throw new Error(`Error: ${response.status}`);
  }

  const data = await response.json();
  return data;
}

async function getAuditGroups() {
  const response = await fetch(`${config.externalApi}/legal/api/AuditLogs/GetAuditGroups`, {
    ...defaultOptions,
    method: 'GET'
  });

  if (!response.ok) {
    throw new Error(`Error: ${response.status}`);
  }

  const data = await response.json();
  return data;
}

async function getAuditRecords() {
  const response = await fetch(`${config.externalApi}/legal/api/AuditLogs/GetAuditRecordTypes`, {
    ...defaultOptions,
    method: 'GET'
  });

  if (!response.ok) {
    throw new Error(`Error: ${response.status}`);
  }

  const data = await response.json();
  return data;
}

async function getAuditEntry(auditEntryId) {
  const response = await fetch(`${config.externalApi}/legal/api/AuditLogs/${auditEntryId}`, {
    ...defaultOptions,
    method: 'GET'
  });

  if (!response.ok) {
    throw new Error(`Error: ${response.status}`);
  }

  const data = await response.json();
  return data;
}

async function saveReportTemplate(data) {
  const url = `${config.externalApi}/legal/api/ReportTemplate`;
  await fetch(url, {
    ...defaultOptions,
    method: 'POST',
    body: JSON.stringify(data)
  });
}

async function getReportTemplate(loggedUser) {
  let baseUrl = `${config.externalApi}/legal/api/ReportTemplate?`;
  const currentUser = store.getState().auth?.currentUser;
  const legalAdmin = isLegalAdmin(currentUser);
  if (loggedUser !== undefined && loggedUser !== null) {
    baseUrl += 'loggedUser=' + encodeURIComponent('' + loggedUser) + '&';
  }
  if (legalAdmin !== undefined && legalAdmin !== null) {
    baseUrl += 'isLegalAdmin=' + encodeURIComponent('' + legalAdmin) + '&';
  }
  baseUrl = baseUrl.replace(/[?&]$/, '');
  const response = await fetch(baseUrl, {
    ...defaultOptions,
    method: 'GET'
  });

  if (!response.ok) {
    throw new Error(`Error: ${response.status}`);
  }

  const data = await response.json();
  return data;
}

async function getAuditLogsActivity(
  pageNumber,
  pageSize,
  sortOrder,
  legalCaseId,
  users,
  auditTypes,
  loggedUser
) {
  const currentUser = store.getState().auth?.currentUser;
  const legalAdmin = isLegalAdmin(currentUser);
  let baseUrl = `${config.externalApi}/legal/api/AuditLogs/AuditLogsActivity?`;
  if (loggedUser !== undefined && loggedUser !== null) {
    baseUrl += 'LoggedUser=' + encodeURIComponent('' + loggedUser) + '&';
  }
  if (pageNumber !== undefined && pageNumber !== null) {
    baseUrl += 'PageNumber=' + encodeURIComponent('' + pageNumber) + '&';
  }
  if (pageSize !== undefined && pageSize !== null) {
    baseUrl += 'PageSize=' + encodeURIComponent('' + pageSize) + '&';
  }
  if (sortOrder !== undefined && sortOrder !== '' && sortOrder !== null) {
    baseUrl += 'SortOrder=' + encodeURIComponent('' + sortOrder) + '&';
  }
  if (legalCaseId !== undefined && legalCaseId !== '' && legalCaseId !== null) {
    baseUrl += 'LegalCaseId=' + encodeURIComponent('' + legalCaseId) + '&';
  }
  if (users && users.length > 0 && !users?.includes('all')) {
    for (let item of users) {
      baseUrl += 'Users=' + encodeURIComponent('' + item) + '&';
    }
  }
  if (auditTypes && auditTypes.length > 0 && !auditTypes?.includes('all')) {
    for (let item of auditTypes) {
      baseUrl += 'AuditTypes=' + encodeURIComponent('' + item) + '&';
    }
  }
  if (legalAdmin !== undefined && legalAdmin !== null) {
    baseUrl += 'IsLegalAdmin=' + encodeURIComponent('' + legalAdmin) + '&';
  }
  baseUrl = baseUrl.replace(/[?&]$/, '');

  const response = await fetch(baseUrl, {
    ...defaultOptions,
    method: 'GET'
  });

  if (!response.ok) {
    throw new Error(`Error: ${response.status}`);
  }

  const data = await response.json();
  return data;
}

async function exportToCSV(downloadUrl, loggedUser, legalCaseId, users, auditTypes, columnHeaders) {
  let baseUrl = downloadUrl;
  const currentUser = store.getState().auth?.currentUser;
  const legalAdmin = isLegalAdmin(currentUser);
  if (loggedUser !== undefined && loggedUser !== null) {
    baseUrl += 'LoggedUser=' + encodeURIComponent('' + loggedUser) + '&';
  }

  if (legalCaseId !== undefined && legalCaseId !== '' && legalCaseId !== null) {
    baseUrl += 'LegalCaseId=' + encodeURIComponent('' + legalCaseId) + '&';
  }
  if (users && users.length > 0 && !users?.includes('all')) {
    for (let item of users) {
      baseUrl += 'Users=' + encodeURIComponent('' + item) + '&';
    }
  }
  if (auditTypes && auditTypes.length > 0 && !auditTypes?.includes('all')) {
    for (let item of auditTypes) {
      baseUrl += 'AuditTypes=' + encodeURIComponent('' + item) + '&';
    }
  }
  if (columnHeaders !== undefined && columnHeaders !== null) {
    baseUrl += 'ColumnHeaders=' + encodeURIComponent('' + columnHeaders) + '&';
  }
  if (legalAdmin !== undefined && legalAdmin !== null) {
    baseUrl += 'IsLegalAdmin=' + encodeURIComponent('' + legalAdmin) + '&';
  }
  baseUrl = baseUrl.replace(/[?&]$/, '');
  await downloadService(baseUrl, 'GET', {});
}

async function exportToPDF(downloadUrl, loggedUser, legalCaseId, users, auditTypes, columnHeaders) {
  let baseUrl = downloadUrl;
  const currentUser = store.getState().auth?.currentUser;
  const legalAdmin = isLegalAdmin(currentUser);
  if (loggedUser !== undefined && loggedUser !== null) {
    baseUrl += 'LoggedUser=' + encodeURIComponent('' + loggedUser) + '&';
  }

  if (legalCaseId !== undefined && legalCaseId !== '' && legalCaseId !== null) {
    baseUrl += 'LegalCaseId=' + encodeURIComponent('' + legalCaseId) + '&';
  }
  if (users && users.length > 0 && !users?.includes('all')) {
    for (let item of users) {
      baseUrl += 'Users=' + encodeURIComponent('' + item) + '&';
    }
  }
  if (auditTypes && auditTypes.length > 0 && !auditTypes?.includes('all')) {
    for (let item of auditTypes) {
      baseUrl += 'AuditTypes=' + encodeURIComponent('' + item) + '&';
    }
  }
  if (columnHeaders !== undefined && columnHeaders !== null) {
    baseUrl += 'ColumnHeaders=' + encodeURIComponent('' + columnHeaders) + '&';
  }
  if (legalAdmin !== undefined && legalAdmin !== null) {
    baseUrl += 'IsLegalAdmin=' + encodeURIComponent('' + legalAdmin) + '&';
  }
  baseUrl = baseUrl.replace(/[?&]$/, '');
  await downloadService(baseUrl, 'GET', {});
}

const actions = {
  doFetch:
    (filter, keepPagination = false) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: 'AUDIT_LOGS_LIST_FETCH_STARTED',
          payload: { filter, keepPagination }
        });

        const response = await list(
          filter.searchString,
          filter.pageNumber,
          filter.pageSize || 10,
          filter.sortOrder,
          filter.legalCaseId,
          filter.users,
          filter.auditTypes || [],
          filter.from,
          filter.to,
          filter.loggedUser,
          filter.currentFilter
        );

        dispatch({
          type: 'AUDIT_LOGS_LIST_FETCH_SUCCESS',
          payload: {
            rows: response.auditLogs,
            count: response.totalCount
          }
        });
      } catch (error) {
        Errors.handle(error);
        dispatch({
          type: 'AUDIT_LOGS_LIST_FETCH_ERROR'
        });
      }
    },
  doFetchAuditLogsActivity:
    (filter, keepPagination = false) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: 'AUDIT_LOGS_ACTIVITY_LIST_FETCH_STARTED',
          payload: { filter, keepPagination }
        });

        const response = await getAuditLogsActivity(
          filter.pageNumber,
          filter.pageSize || 10,
          filter.sortOrder,
          filter.legalCaseId,
          filter.users,
          filter.auditTypes || [],
          filter.loggedUser
        );

        dispatch({
          type: 'AUDIT_LOGS_ACTIVITY_LIST_FETCH_SUCCESS',
          payload: {
            activityRows: response.auditLogs,
            count: response.totalCount
          }
        });
      } catch (error) {
        Errors.handle(error);
        dispatch({
          type: 'AUDIT_LOGS_ACTIVITY_LIST_FETCH_ERROR'
        });
      }
    },
  doGetAuditGroups:
    (filter, keepPagination = false) =>
    async (dispatch) => {
      try {
        dispatch({
          type: 'AUDIT_GROUPS__FETCH_STARTED',
          payload: { filter, keepPagination }
        });

        const response = await getAuditGroups();

        dispatch({
          type: 'AUDIT_GROUPS_FETCH_SUCCESS',
          payload: {
            auditGroups: response
          }
        });
      } catch (error) {
        Errors.handle(error);
        dispatch({
          type: 'AUDIT_GROUPS_FETCH_ERROR'
        });
      }
    },
  doGetAuditRecords: () => async (dispatch) => {
    try {
      dispatch({
        type: 'AUDIT_RECORDS_FETCH_STARTED'
      });

      const response = await getAuditRecords();

      dispatch({
        type: 'AUDIT_RECORDS_FETCH_SUCCESS',
        payload: {
          auditRecords: response
        }
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'AUDIT_RECORDS_FETCH_ERROR'
      });
    }
  },
  doFetchAuditEntry: (auditEntryId) => async (dispatch) => {
    try {
      dispatch({
        type: 'AUDIT_ENTRY_FETCH_STARTED'
      });

      const response = await getAuditEntry(auditEntryId);

      dispatch({
        type: 'AUDIT_ENTRY_FETCH_SUCCESS',
        payload: {
          auditEntryDetails: response
        }
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'AUDIT_ENTRY_FETCH_ERROR'
      });
    }
  },
  doGetReportTemplate: (loggedUser) => async (dispatch) => {
    try {
      dispatch({
        type: 'REPORT_TEMPLATE_FETCH_STARTED'
      });

      const response = await getReportTemplate(loggedUser);

      dispatch({
        type: 'REPORT_TEMPLATE_FETCH_SUCCESS',
        payload: {
          recordTemplates: response
        }
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'REPORT_TEMPLATE_FETCH_ERROR'
      });
    }
  },
  doSaveReportTemplate: (data) => async (dispatch) => {
    try {
      dispatch({
        type: 'SAVE_REPORT_TEMPLATE_STARTED'
      });
      await saveReportTemplate(data);
      dispatch({
        type: 'SAVE_REPORT_TEMPLATE_SUCCESS'
      });
      toast.success('Report template is saved successfully');
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'SAVE_REPORT_TEMPLATE_ERROR'
      });
    }
  },
  handleExportToCSV:
    (downloadUrl, loggedUser, legalCaseId, users, auditTypes, columnHeaders) =>
    async (dispatch) => {
      try {
        dispatch({
          type: 'EXPORT_TO_CSV_STARTED'
        });
        exportToCSV(downloadUrl, loggedUser, legalCaseId, users, auditTypes, columnHeaders).then(
          () => {
            dispatch({
              type: 'EXPORT_TO_CSV_SUCCESS'
            });
          }
        );
      } catch (error) {
        Errors.handle(error);
        dispatch({
          type: 'EXPORT_TO_CSV_ERROR'
        });
      }
    },
  handleExportToPDF:
    (downloadUrl, loggedUser, legalCaseId, users, auditTypes, columnHeaders) =>
    async (dispatch) => {
      try {
        dispatch({
          type: 'EXPORT_TO_PDF_STARTED'
        });
        exportToPDF(downloadUrl, loggedUser, legalCaseId, users, auditTypes, columnHeaders).then(
          () => {
            dispatch({
              type: 'EXPORT_TO_PDF_SUCCESS'
            });
          }
        );
      } catch (error) {
        Errors.handle(error);
        dispatch({
          type: 'EXPORT_TO_PDF_ERROR'
        });
      }
    }
};

export default { ...actions, list };
