import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import { useHistory } from "react-router-dom";
import { store } from "index";
import "components/Layout/layoutStyles.css";
import { getTheme } from "services/themeService";

function NoAccessPage() {
  const history = useHistory();
  const currentUser = store.getState().auth.currentUser;
  const permissions = store.getState().auth.permissions;
  const [theme, setTheme] = useState("#333964");

  const handleGetTheme = async () => {
    const selectedTheme = await getTheme(currentUser?.userName);
    setTheme(selectedTheme?.settingValue);
  };

  useEffect(() => {
    if (!permissions["LegalUI.Credential.KeyManagement"]) {
      handleGetTheme();
    }
  }, []);

  const bodyStyle = {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  };
  return (
    <>
      <Modal
        size="lg"
        isOpen={true}
        className={`themeStyle${theme?.replace("#", "")}`}
      >
        <ModalBody style={{ height: "400px" }}>
          <div style={bodyStyle}>
            <h1>Permission Denied!</h1>
            <h1>You can not access this page!</h1>
            <div style={{ marginTop: "20px" }}>
              <Button color="danger" size="md" onClick={() => history.goBack()}>
                Go Back
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}

export default NoAccessPage;
