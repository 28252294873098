import React, { Component } from 'react';
import SearchListTable from 'components/CRUD/Search/list/SearchListTable';
import SearchTable from './SearchTable';
import Widget from 'components/Widget/Widget';
import { connect } from 'react-redux';

class SearchListPage extends Component {
  render() {
    return (
      <Widget style={{ marginTop: '20px' }}>
        {this.props.showTableView ? (
          <SearchTable
            hideTableToggle={this.props.hideTableToggle}
            legalCaseId={this.props.legalCaseId}
            caseStatus={this.props.caseStatus}
          />
        ) : (
          <SearchListTable
            hideTableToggle={this.props.hideTableToggle}
            legalCaseId={this.props.legalCaseId}
          />
        )}
      </Widget>
    );
  }
}
function mapStateToProps(store) {
  return {
    showTableView: store.search.list.showTableView
  };
}
export default connect(mapStateToProps)(SearchListPage);
