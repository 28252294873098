import React, { Component } from 'react';
import LegalCaseListTable from 'components/CRUD/LegalCase/list/LegalCaseListTable';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

class LegalCaseListPage extends Component {
  render() {
    return (
      <React.Fragment>
        {this.props.permissions['LegalUI.Cases'] ? (
          <LegalCaseListTable />
        ) : (
          <Redirect to={'/no-access'} />
        )}
      </React.Fragment>
    );
  }
}
function mapStateToProps(store) {
  return {
    permissions: store.auth.permissions
  };
}
export default connect(mapStateToProps)(LegalCaseListPage);
