import Errors from 'components/FormItems/error/errors';
import config from '../../config';
import { Client } from '../../legal-api';
import { store } from '../../index';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import caseViewerFormActions from '../viewers/LegalCaseViewerFormActions';
import { defaultOptions } from '../../services/userService';
import { isLegalAdmin } from 'utils/isLegalAdmin';

async function list(searchString, pageNumber, pageSize) {
  const currentUser = store.getState().auth.currentUser;
  const legalAdmin = isLegalAdmin(currentUser);
  const response = await new Client(config.externalApi).legalCaseAll(
    undefined,
    undefined,
    searchString,
    currentUser.userName,
    pageNumber,
    pageSize,
    legalAdmin
  );
  return response;
}

async function closedList(searchString, pageNumber, pageSize, sortOrder) {
  const currentUser = store.getState().auth.currentUser;
  const legalAdmin = isLegalAdmin(currentUser);
  const response = await new Client(config.externalApi).legalCaseClosedAll(
    sortOrder,
    undefined,
    searchString,
    currentUser.userName,
    pageNumber,
    pageSize,
    legalAdmin
  );
  return response;
}

async function closeCase(caseId, expireonUser, closureReason) {
  const response = await fetch(
    `${config.externalApi}/legal/api/LegalCase/caseId/CloseCase?caseId=${caseId}&closureReason=${closureReason}`,
    {
      ...defaultOptions,
      method: 'PUT',
      body: JSON.stringify(expireonUser)
    }
  );

  const data = await response.json();
  return data;
}

async function caseUsersList(legalCaseId, sortOrder, searchString, pageNumber, pageSize) {
  let baseUrl = `${config.externalApi}/legal/api/LegalCase/${legalCaseId}/GetExpireonLegalCaseUsers?`;
  if (pageNumber !== undefined) {
    baseUrl += 'pageNumber=' + encodeURIComponent('' + pageNumber) + '&';
  }
  if (pageSize !== undefined) {
    baseUrl += 'pageSize=' + encodeURIComponent('' + pageSize) + '&';
  }
  if (searchString !== undefined && searchString !== '') {
    baseUrl += 'searchString=' + encodeURIComponent('' + searchString) + '&';
  }
  if (sortOrder !== undefined && sortOrder !== '') {
    baseUrl += 'sortOrder=' + encodeURIComponent('' + sortOrder) + '&';
  }
  baseUrl = baseUrl.replace(/[?&]$/, '');

  const response = await fetch(baseUrl, {
    ...defaultOptions,
    method: 'GET'
  });

  const data = await response.json();
  return data;
}

async function reOpenCase(legalCaseId, expireonUser) {
  await fetch(`${config.externalApi}/legal/api/LegalCase/caseId/ReOpen?caseId=${legalCaseId}`, {
    ...defaultOptions,
    method: 'PUT',
    body: JSON.stringify(expireonUser)
  });
}

async function addLogForCaseView(legalCaseId, currentUser) {
  const { name: firstName, surName: lastName, email, userName } = currentUser || {};
  const expireonUser = { firstName, lastName, email, userName };

  await fetch(`${config.externalApi}/legal/api/LegalCase/${legalCaseId}/AddLogForCaseView`, {
    ...defaultOptions,
    method: 'PUT',
    body: JSON.stringify(expireonUser)
  });
}

async function getSimplifiedCases() {
  let simplifiedCases = null;
  const currentUser = store.getState().auth.currentUser;
  const legalAdmin = isLegalAdmin(currentUser);

  const response = await fetch(
    `${config.externalApi}/legal/api/LegalCase/GetSimplifiedCases?loggedUser=${currentUser?.userName}&isLegalAdmin=${legalAdmin}`,
    {
      ...defaultOptions,
      method: 'GET'
    }
  );

  simplifiedCases = await response.json();
  return simplifiedCases;
}

const actions = {
  doFetch:
    (filter, keepPagination = false) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: 'LEGALCASE_LIST_FETCH_STARTED',
          payload: { filter, keepPagination }
        });

        const response = await list(filter.searchString, filter.pageNumber, filter.pageSize);

        dispatch({
          type: 'LEGALCASE_LIST_FETCH_SUCCESS',
          payload: {
            rows: response.legalCases,
            count: response.totalCount
          }
        });
      } catch (error) {
        Errors.handle(error);
        dispatch({
          type: 'LEGALCASE_LIST_FETCH_ERROR'
        });
      }
    },

  doGetSimplifiedCases: () => async (dispatch) => {
    try {
      dispatch({
        type: 'SIMPLIFIED_CASES_LIST_FETCH_STARTED'
      });

      const response = await getSimplifiedCases();
      dispatch({
        type: 'SIMPLIFIED_CASES_LIST_FETCH_SUCCESS',
        payload: {
          simplifiedCases: response
        }
      });
    } catch (error) {
      Errors.handle(error);
      dispatch(caseViewerFormActions.doCloseConfirm());
      dispatch({
        type: 'SIMPLIFIED_CASES_LIST_FETCH_ERROR'
      });
    }
  },

  doFetchClosed:
    (filter, keepPagination = false) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: 'LEGALCASE_LIST_FETCH_STARTED',
          payload: { filter, keepPagination }
        });

        const response = await closedList(
          filter.searchString,
          filter.pageNumber,
          filter.pageSize || 10,
          filter.sortOrder
        );

        dispatch({
          type: 'LEGALCASE_CLOSED_LIST_FETCH_SUCCESS',
          payload: {
            closedLegalCases: response.legalCases,
            closedLegalCasesCount: response.totalCount
          }
        });
      } catch (error) {
        Errors.handle(error);
        dispatch({
          type: 'LEGALCASE_LIST_FETCH_ERROR'
        });
      }
    },
  doFetchCaseUsers:
    (filter, keepPagination = false) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: 'LEGALCASE_USERS_LIST_FETCH_STARTED',
          payload: { filter, keepPagination }
        });

        const response = await caseUsersList(
          filter.legalCaseId,
          filter.sortOrder,
          filter.searchString,
          filter.pageNumber,
          filter.pageSize
        );
        dispatch({
          type: 'LEGALCASE_USERS_LIST_FETCH_SUCCESS',
          payload: {
            caseUsers: response.expireonUser,
            caseUsersCount: response.totalCount
          }
        });
      } catch (error) {
        Errors.handle(error);
        dispatch({
          type: 'LEGALCASE_USERS_LIST_FETCH_ERROR'
        });
      }
    },
  doCloseCase: (caseId, expireonUser, closureReason) => async (dispatch) => {
    try {
      dispatch({
        type: 'LEGALCASE_CLOSE_CASE_STARTED'
      });
      const res = await closeCase(caseId, expireonUser, closureReason);
      const legalHoldsList = res;
      if (legalHoldsList?.length === 0) {
        dispatch({
          type: 'LEGALCASE_CLOSE_CASE_SUCCESS'
        });
        dispatch(push('/app/LegalCase'));
      } else {
        dispatch({
          type: 'LEGALCASE_CLOSE_CASE_FAILED',
          payload: {
            legalHoldsList
          }
        });
      }
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'LEGALCASE_CLOSE_CASE_ERROR'
      });
    }
  },
  doOpenConfirm: (id) => async (dispatch) => {
    dispatch({
      type: 'LEGALCASE_LIST_OPEN_CONFIRM',
      payload: {
        id: id
      }
    });
  },
  doCloseConfirm: () => async (dispatch) => {
    dispatch({
      type: 'LEGALCASE_LIST_CLOSE_CONFIRM'
    });
  },
  doReopenCase: (legalCaseId, expireonUser) => async (dispatch) => {
    try {
      dispatch({
        type: 'LEGALCASE_REOPEN_CASE_STARTED'
      });
      toast.success('Re-Open process have started');
      await reOpenCase(legalCaseId, expireonUser);
      dispatch({
        type: 'LEGALCASE_REOPEN_CASE_SUCCESS'
      });
      dispatch(push('/app/CaseManager'));
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'LEGALCASE_REOPEN_CASE_ERROR'
      });
    }
  },
  doAddLogForCaseView: (legalCaseId, expireonUser) => async (dispatch) => {
    try {
      dispatch({
        type: 'LEGALCASE_ADD_LOG_STARTED'
      });
      await addLogForCaseView(legalCaseId, expireonUser);
      dispatch({
        type: 'LEGALCASE_ADD_LOG_SUCCESS'
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'LEGALCASE_ADD_LOG_ERROR'
      });
    }
  }
};

export default { ...actions, list };
