import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import { ErrorMessage, Formik } from 'formik';
import InputFormItem from 'components/FormItems/items/InputFormItem';
import SelectFormItem from 'components/FormItems/items/SelectFormItem';
import AuditTemplateFields from './AuditTemplateFields';
import styles from 'components/CRUD/AuditLogs/Audit.module.scss';
import * as Yup from 'yup';
import IniValues from 'components/FormItems/iniValues';
import * as searchDataFormat from 'components/CRUD/Search/list/SearchDataFormatters';

export default class AuditTemplateForm extends Component {
  templateIniValues = () => {
    const record = {};
    return IniValues(AuditTemplateFields, record);
  };

  FormValidationSchema = [
    Yup.object().shape({
      name: Yup.string().trim().nullable(true).required('Name* is a required field'),
      description: Yup.string().trim().nullable(true).required('Description* is a required field'),
      templateUsers: Yup.string().nullable(true).required('Template Owners* is a required field'),
      dateRanges: Yup.string().nullable(true).required('Date Range* is a required field')
    })
  ];

  templateUsersOptions = (data) => {
    const arr = [];
    if (Array.isArray(data)) {
      data?.map((item) => {
        const obj = {};
        obj.value = item;
        obj.label = item.userName;
        arr.push(obj);
      });
    }
    return arr;
  };

  dateRangeOptions = (data) => {
    const arr = [];
    if (typeof data === 'string') {
      return data;
    }
    if (Array.isArray(data)) {
      data.map((item) => {
        const obj = {};
        obj.value = JSON.stringify(item?.value);
        obj.label = item?.key;
        arr.push(obj);
      });
    } else {
      for (let key in data) {
        const obj = {};
        obj.value = JSON.parse(key);
        obj.label = data[key];
        arr.push(obj);
      }
    }
    return arr;
  };

  render() {
    return (
      <>
        <Formik
          onSubmit={this.props?.doSubmit}
          initialValues={this.templateIniValues()}
          validationSchema={this.FormValidationSchema[0]}
          render={(form) => {
            return (
              <Row>
                <Col sm={12}>
                  <form onSubmit={form.handleSubmit}>
                    <Row>
                      <Col>
                        <InputFormItem name={'name'} schema={AuditTemplateFields} autoFocus />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <InputFormItem
                          name={'description'}
                          schema={AuditTemplateFields}
                          autoFocus
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <SelectFormItem
                          name={'templateUsers'}
                          schema={AuditTemplateFields}
                          placeholder={'Select user'}
                          mode={'fetch'}
                          isMulti={true}
                          data={this.templateUsersOptions(this.props?.allExpireonUsers)}
                          isRecord={true}
                        />
                        <ErrorMessage name='templateUsers'>
                          {(msg) => <div className={styles.errorMessage}>{msg}</div>}
                        </ErrorMessage>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <SelectFormItem
                          name={'dateRanges'}
                          schema={AuditTemplateFields}
                          placeholder={'Select Date Range'}
                          mode={'fetch'}
                          isClearable={false}
                          data={this.dateRangeOptions(this.props?.dateRanges)}
                        />
                        <ErrorMessage name='dateRanges'>
                          {(msg) => <div className={styles.errorMessage}>{msg}</div>}
                        </ErrorMessage>
                      </Col>
                    </Row>
                    <h5 style={{ fontWeight: 600 }}>Filters</h5>
                    <div style={{ marginBottom: '5px' }}>
                      {` Case: ${this.props?.legalCaseName}`}
                    </div>
                    <div className={'d-flex w-100'}>
                      <span className='w-20'>Users:</span>
                      <span className={styles.auditRecords}>
                        {!this.props?.userNames ||
                        this.props.userNames?.length === 0 ||
                        this.props.userNames?.includes('All Users')
                          ? 'All Users'
                          : this.props?.userNames?.join(', ')}
                      </span>
                    </div>
                    <div className={'d-flex w-100'}>
                      <span className='w-30'>Audit Record:</span>
                      <span className={styles.auditRecords}>
                        {!this.props.auditRecordNames ||
                        this.props.auditRecordNames?.length === 0 ||
                        this.props.auditRecordNames?.includes('All Records')
                          ? 'All Records'
                          : this.props?.auditRecordNames?.join(', ')}
                      </span>
                    </div>
                    <div style={{ marginBottom: '5px' }}>
                      {` Date From: 
                                ${searchDataFormat.dateTimeFormatter(this.props.dateFrom)}`}
                    </div>
                    <div style={{ marginBottom: '5px' }}>
                      {` Date To: ${searchDataFormat.dateTimeFormatter(this.props.dateTo)}`}
                    </div>
                    <div className='d-flex align-items-center justify-content-center'>
                      <button
                        style={{ width: '150px' }}
                        className='btn text-white fw-semi-bold d-flex align-items-center justify-content-center'
                        type='submit'
                        onClick={() => form.handleSubmit}
                      >
                        Save
                      </button>
                    </div>
                  </form>
                </Col>
              </Row>
            );
          }}
        />
      </>
    );
  }
}
