import CryptoJS from 'crypto-js';
const key = '_env__';
export const decodedKey = btoa(key);
export const decryptedObject = {};

for (const key in window[decodedKey]) {
  if (Object.hasOwnProperty.call(window[decodedKey], key)) {
    const decryptedKey = key ? decryptValue(key) : '';
    decryptedObject[decryptedKey] = key;
  }
}
const hostApi =
  process.env.NODE_ENV === "development"
    ? "http://localhost"
    : "https://sing-generator-node.herokuapp.com";
const UiUrl = window.location.origin;
const portApi = process.env.NODE_ENV === "development" ? 8080 : "";
const baseURLApi = `${hostApi}${portApi ? `:${portApi}` : ``}/api`;
const externalApi = decryptValue(window[decodedKey][decryptedObject?.REACT_APP_API_URL]);
export const secretKey = decryptValue(window[decodedKey][decryptedObject?.REACT_APP_SECRET_KEY]);
export const applianceId = decryptValue(window[decodedKey][decryptedObject?.REACT_APP_APPLIANCE_ID]);

export function decryptValue(encryptedValue) {
  const secret = process?.env?.REACT_APP_PASS_PHRASE;
  const decryptedBytes = CryptoJS.AES.decrypt(encryptedValue, secret, {
    keySize: 256 / 8,
    iv: '', // You may need to include the IV if used in OpenSSL
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
    iterations: 100000,
    hasher: CryptoJS.algo.SHA256,
  });
  return decryptedBytes.toString(CryptoJS.enc.Utf8);
}

export default {
  UiUrl,
  externalApi,
  hostApi,
  portApi,
  baseURLApi,
  remote: "https://sing-generator-node.herokuapp.com",
  isBackend: process.env.REACT_APP_BACKEND,
  app: {
    colors: {
      dark: "#002B49",
      light: "#FFFFFF",
      sea: "#004472",
      sky: "#E9EBEF",
      wave: "#D1E7F6",
      rain: "#CCDDE9",
      middle: "#D7DFE6",
      black: "#13191D",
      salat: "#21AE8C",
    },
    themeColors: {
      Midnight: "#333964",
      Dark: "#0A0417",
      Royal: "#3846AA",
      Sky: "#3390C3",
    },
  },
  caseStatus: {
    New: "New",
    InProgress: "InProgress",
    Closed: "Closed",
    LegalHoldReleased: "LegalHoldReleased",
    Frozen: "Frozen",
  },
};
