import React, { useEffect } from 'react'
import { signoutRedirectCallback } from '../../../services/userService'
import { useHistory } from 'react-router-dom';
import Loader from 'components/Loader/Loader';

function SignoutOidc() {
  const history = useHistory()
  useEffect(() => {
    async function signoutAsync() {
      await signoutRedirectCallback()
      history.push('/')
    }
    signoutAsync()
  }, [history])

  return  <Loader/>
}

export default SignoutOidc
