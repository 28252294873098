import React, { Component } from 'react';
import ClosedListTable from './ClosedListTable';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
class ClosedPage extends Component {
  render() {
    return (
      <React.Fragment>
        {this.props.permissions['LegalUI.CaseManager'] ? (
          <ClosedListTable />
        ) : (
          <Redirect to={'/no-access'} />
        )}
      </React.Fragment>
    );
  }
}
function mapStateToProps(store) {
  return {
    permissions: store.auth.permissions
  };
}
export default connect(mapStateToProps)(ClosedPage);
