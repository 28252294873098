import React, { Component } from "react";
import AuditLogsTable from "../AuditLogs/AuditLogsTable";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

class ActivityPage extends Component {
  render() {
    return (
      <React.Fragment>
        {this.props.permissions["LegalUI.Activity"] ? (
          <AuditLogsTable />
        ) : (
          <Redirect to={"/no-access"} />
        )}
      </React.Fragment>
    );
  }
}
function mapStateToProps(store) {
  return {
    permissions: store.auth.permissions,
  };
}
export default connect(mapStateToProps)(ActivityPage);
