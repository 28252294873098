import { Formik, ErrorMessage } from 'formik';
import React, { Component } from 'react';
import InputFormItem from 'components/FormItems/items/InputFormItem';
import IniValues from 'components/FormItems/iniValues';
import PreparedValues from 'components/FormItems/preparedValues';
import * as Yup from 'yup';
import CertificateFields from '../CertificateFields';
import moment from 'moment';
import SelectFormItem from 'components/FormItems/items/SelectFormItem';
import styles from '../list/CertificateList.module.scss';
class CertificateForm extends Component {
  iniValues = () => {
    const record = {
      ...this.props.record,
      keySize: 4096
    };
    return IniValues(CertificateFields, record || {});
  };

  handleSubmit = (values) => {
    const { ...data } = PreparedValues(CertificateFields, values || {});
    this.props.onSubmit(data);
  };

  FormValidationSchema = Yup.object().shape({
    uniqueName: Yup.string().trim().required('Unique Name* is a required field'),
    keySize: Yup.number().required('Key Size* is a required field'),
    label: Yup.string()
      .required('Label* is a required field')
      .test(
        'noConsecutiveUnderscores',
        'Invalid: Two consecutive underscores are not allowed',
        (value) => {
          if (!value) return true;
          for (let i = 0; i < value.length - 1; i++) {
            if (value[i] === '_' && value[i + 1] === '_') {
              return false;
            }
          }
          return true;
        }
      )
      .matches(/^[a-zA-Z][a-zA-Z0-9_]*[a-zA-Z0-9]?$/, {
        message: 'Label provided is invalid'
      })
  });

  dateTimeFormatter() {
    const currentDate = new Date();
    return moment(currentDate).format('YYYYMMDDHHmm');
  }

  keySizeOptions = [
    { value: 2048, label: '2048' },
    { value: 3072, label: '3072' },
    { value: 4096, label: '4096' },
    { value: 8192, label: '8192' }
  ];

  renderForm() {
    const { saveLoading } = this.props;
    return (
      <>
        <Formik
          onSubmit={this.handleSubmit}
          initialValues={this.iniValues()}
          validationSchema={this.FormValidationSchema}
          render={(form) => {
            return (
              <form onSubmit={form.handleSubmit}>
                <InputFormItem
                  name={'label'}
                  schema={CertificateFields}
                  autoFocus
                  currentDate={this.dateTimeFormatter()}
                />
                <InputFormItem name={'uniqueName'} schema={CertificateFields} isDisabled={true} />
                <SelectFormItem
                  name={'keySize'}
                  schema={CertificateFields}
                  mode={'fetch'}
                  data={this.keySizeOptions}
                  placeholder={'Select...'}
                />
                {form.errors?.keySize && form.dirty && (
                  <div className={styles.errorMessage}>{form?.errors?.keySize}</div>
                )}
                <div
                  className='form-buttons d-flex justify-content-center'
                  style={{
                    marginTop: 25
                  }}
                >
                  <span style={{ marginRight: '30px' }}>
                    <button
                      className='btn w-100 fw-bold'
                      disabled={saveLoading || !form.dirty}
                      type='button'
                      onClick={form.handleSubmit}
                    >
                      Add Certificate
                    </button>
                  </span>
                </div>
              </form>
            );
          }}
        />
      </>
    );
  }
  render() {
    return this.renderForm();
  }
}
export default CertificateForm;
