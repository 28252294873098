import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Nav,
  NavLink,
  NavItem,
  Progress,
  ModalHeader,
  ModalBody,
  Modal,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from 'reactstrap';
import s from './Wizard.module.scss';
import { toast } from 'react-toastify';
import InputFormItem from 'components/FormItems/items/InputFormItem';
import SelectFormItem from 'components/FormItems/items/SelectFormItem';
import DatePickerFormItem from 'components/FormItems/items/DatePickerFormItem';
import TextAreaFormItem from 'components/FormItems/items/TextAreaFormItem';
import LegalHoldFields from 'components/CRUD/LegalHold/LegalHoldFields';
import IniValues from 'components/FormItems/iniValues';
import PreparedValues from 'components/FormItems/preparedValues';
import Dropzone from 'react-dropzone';
import config from 'config';
import Loader from 'components/Loader';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import classnames from 'classnames';
import SelectByAddingFormItem from '../../../FormItems/items/SelectByAddingFormItem';
import { Client } from '../../../../legal-api';
import { Builder, Query, Utils as QbUtils } from 'react-awesome-query-builder';
import BasicConfig from 'react-awesome-query-builder/lib/config/basic';
import closeIcon from '../../../../images/icons/close.svg';
import { ImInfo } from 'react-icons/im';
import LegalHoldFormTooltip from './LegalHoldFormTooltip';
import formActions from 'actions/LegalHold/LegalHoldFormActions';
import * as dataFormat from 'components/CRUD/LegalHold/list/LegalHoldDataFormatters';
import { FormattingService } from 'utils/sizeFormatting';
import listActions from 'actions/LegalHold/LegalHoldListActions';
import LegalHoldTooltip from './LegalHoldTooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { emptyDataMessage } from 'actions/common';
import styles from '../list/LegalHoldList.module.scss';
import CustodianSelectionList from './CustodianSelectionList';
import ExternalCustodiansForm from './ExternalCustodiansForm';
import { BiCopyright } from 'react-icons/bi';
import LongNameTooltip from './LongNameTooltip';
import UserIdentitiesTooltip from './UserIdentitiesTooltip';
import searchFormStyles from 'components/CRUD/Search/form/SearchForm.module.scss';
import { defaultOptions } from '../../../../services/userService';
import caretDown from 'images/icons/caret-down.svg';

const {
  queryBuilderFormat,
  jsonLogicFormat,
  queryString,
  mongodbFormat,
  sqlFormat,
  getTree,
  checkTree,
  loadTree,
  uuid,
  loadFromJsonLogic
} = QbUtils;

const InitialConfig = BasicConfig;

const config1 = {
  ...InitialConfig,
  ...{
    operators: {
      equal: {
        label: '==',
        reversedOp: 'not_equal',
        labelForFormat: '==',
        sqlOp: '=',
        cardinality: 1,
        formatOp: function formatOp(
          field,
          op,
          value,
          valueSrcs,
          valueTypes,
          opDef,
          operatorOptions,
          isForDisplay,
          fieldDef
        ) {
          if (valueTypes == 'boolean' && isForDisplay)
            return value == 'No' ? ''.concat(field) : 'NOT '.concat(field);
          else return ''.concat(field, ' ').concat(opDef.label, ' ').concat(value);
        },
        mongoFormatOp: (field, op, value) => ({ [field]: { $eq: value } })
      },
      not_equal: {
        isNotOp: true,
        label: '!=',
        labelForFormat: '!=',
        sqlOp: '<>',
        reversedOp: 'equal',
        formatOp: function formatOp(
          field,
          op,
          value,
          valueSrcs,
          valueTypes,
          opDef,
          operatorOptions,
          isForDisplay,
          fieldDef
        ) {
          if (valueTypes == 'boolean' && isForDisplay)
            return value == 'No' ? ''.concat(field) : 'NOT '.concat(field);
          else return ''.concat(field, ' ').concat(opDef.label, ' ').concat(value);
        },
        jsonLogic: '!='
      },
      like: {
        label: 'Contains',
        labelForFormat: 'LIKE',
        reversedOp: 'not_like',
        sqlOp: 'LIKE',
        sqlFormatOp: function sqlFormatOp(
          field,
          op,
          values,
          valueSrc,
          valueType,
          opDef,
          operatorOptions
        ) {
          if (valueSrc == 'value') {
            return ''.concat(field, ' LIKE ').concat(values);
          } else return undefined; // not supported
        },
        //jsonLogic: (field, op, val) => ({ "in": [val, field] }),
        jsonLogic: 'in',
        _jsonLogicIsRevArgs: true,
        valueSources: ['value'],
        elasticSearchQueryType: 'regexp'
      },
      not_like: {
        isNotOp: true,
        label: 'Not contains',
        reversedOp: 'like',
        labelForFormat: 'NOT LIKE',
        sqlOp: 'NOT LIKE',
        sqlFormatOp: function sqlFormatOp(
          field,
          op,
          values,
          valueSrc,
          valueType,
          opDef,
          operatorOptions
        ) {
          if (valueSrc == 'value') {
            return ''.concat(field, ' NOT LIKE ').concat(values);
          } else return undefined; // not supported
        },
        valueSources: ['value']
      },
      starts_with: {
        label: 'Starts with',
        labelForFormat: 'LIKE',
        sqlOp: 'LIKE',
        sqlFormatOp: function sqlFormatOp(
          field,
          op,
          values,
          valueSrc,
          valueType,
          opDef,
          operatorOptions
        ) {
          if (valueSrc == 'value') {
            return ''.concat(field, ' LIKE ').concat(values);
          } else return undefined; // not supported
        },
        jsonLogic: undefined,
        // not supported
        valueSources: ['value']
      },
      ends_with: {
        label: 'Ends with',
        labelForFormat: 'LIKE',
        sqlOp: 'LIKE',
        sqlFormatOp: function sqlFormatOp(
          field,
          op,
          values,
          valueSrc,
          valueType,
          opDef,
          operatorOptions
        ) {
          if (valueSrc == 'value') {
            return ''.concat(field, ' LIKE ').concat(values);
          } else return undefined; // not supported
        },
        jsonLogic: undefined,
        // not supported
        valueSources: ['value']
      },
      is_null: {
        label: 'Is null',
        labelForFormat: 'IS NULL',
        sqlOp: 'IS NULL',
        cardinality: 0,
        reversedOp: 'is_not_null',
        formatOp: function formatOp(
          field,
          op,
          value,
          valueSrc,
          valueType,
          opDef,
          operatorOptions,
          isForDisplay
        ) {
          return isForDisplay || true ? ''.concat(field, ' IS NULL') : '!'.concat(field);
        },
        jsonLogic: '!'
      },
      is_not_null: {
        label: 'Is not null',
        labelForFormat: 'IS NOT NULL',
        sqlOp: 'IS NOT NULL',
        cardinality: 0,
        reversedOp: 'is_null',
        formatOp: function formatOp(
          field,
          op,
          value,
          valueSrc,
          valueType,
          opDef,
          operatorOptions,
          isForDisplay
        ) {
          return isForDisplay || true ? ''.concat(field, ' IS NOT NULL') : '!!'.concat(field);
        },
        jsonLogic: '!!'
      }
    },
    conjunctions: {
      AND: {
        ...InitialConfig.conjunctions.AND,
        label: 'AND',
        formatConj: (children, _conj, not) => (not ? '- ' : '') + '(' + children.join(' AND ') + ')'
      },
      OR: {
        ...InitialConfig.conjunctions.OR,
        label: 'OR',
        formatConj: (children, _conj, not) => (not ? '- ' : '') + '(' + children.join(' OR ') + ')'
      }
    }
  },
  fields: {
    Subject: {
      label: 'Subject',
      type: 'text',
      valueSources: ['value'],
      operators: [
        'equal',
        'not_equal',
        'like',
        'not_like',
        'starts_with',
        'ends_with',
        'is_null',
        'is_not_null'
      ],
      preferWidgets: ['textArea']
    }
  }
};

const queryValue = { id: QbUtils.uuid(), type: 'group' };
const count = 5;
const toReadableSizeFormat = new FormattingService();

const FLOW_OPTIONS = {
  ALL_EMAILS: 'All Emails',
  INTERNAL_EMAILS_ONLY: 'Internal Emails Only',
  INCOMING_EMAILS_ONLY: 'Incoming Emails Only',
  OUTGOING_EMAILS_ONLY: 'Outgoing Emails Only',
  INCOMING_AND_OUTGOING: 'Incoming & Outgoing'
};

const FLOW_OPTIONS_MAP = {
  ALL_EMAILS: 'ALL_EMAILS',
  INTERNAL_EMAILS_ONLY: 'INTERNAL_EMAILS_ONLY',
  INCOMING_EMAILS_ONLY: 'INCOMING_EMAILS_ONLY',
  OUTGOING_EMAILS_ONLY: 'OUTGOING_EMAILS_ONLY',
  INCOMING_AND_OUTGOING: 'INCOMING_AND_OUTGOING'
};

const DATE_RANGE_OPTIONS = {
  lAST_7_DAYS: 'Last 7 days',
  lAST_30_DAYS: 'Last 30 days',
  lAST_90_DAYS: 'Last 90 days',
  lAST_1_YEAR: 'Last 1 year',
  lAST_3_YEARS: 'Last 3 years',
  lAST_7_YEARS: 'Last 7 years',
  lAST_10_YEARS: 'Last 10 years'
};

const DATE_RANGE_OPTIONS_MAP = {
  lAST_7_DAYS: 'lAST_7_DAYS',
  lAST_30_DAYS: 'lAST_30_DAYS',
  lAST_90_DAYS: 'lAST_90_DAYS',
  lAST_1_YEAR: 'lAST_1_YEAR',
  lAST_3_YEARS: 'lAST_3_YEARS',
  lAST_7_YEARS: 'lAST_7_YEARS',
  lAST_10_YEARS: 'lAST_10_YEARS'
};

class LegalHoldForm extends Component {
  constructor(prop) {
    super(prop);
    this.inputRef = React.createRef();
    this.state = {
      currentStep: 1,
      progress: 20,
      externalCustodians: '',
      externalCustodianValue: '',
      externalCustodiansList: [],
      isDisabled: true,
      custodians: [],
      fileName: [],
      importedExternal: [],
      isExternal: false,
      importedCustodians: [],
      isBuilderModalOpen: false,
      tree: QbUtils.checkTree(QbUtils.loadTree(queryValue), config1),
      config: config1,
      builderVisible: false,
      attachmentExtensions: null,
      disableAttachmentExtensions: true,
      disableAttachmentNames: true,
      disableAttachmentNamesIndex: 1,
      disableFlows: true,
      disableSubject: true,
      disableSubjectIndex: 1,
      disableMessageClass: true,
      openCard: false,
      content: null,
      position: {
        top: 0,
        left: 0
      },
      messageClassList: [],
      allAttachments: [],
      containerNames: [],
      showTooltip: false,
      person: {},
      lastHoveredId: null,
      totalCount: 0,
      paginationModalOpen: false,
      pageNumber: 1,
      pageSize: 5,
      newRows: [],
      selectedCustodians: [],
      userIdentities: [],
      defaultTabsTabId: '1',
      openExternalModal: false,
      externalCustodianInfo: [],
      internalCustodiansList: [],
      currentPage: 1,
      itemsPerPage: 5,
      itemsToDisplay: [],
      totalPages: 0,
      pageNumbers: [],
      showIdentitiesTooltip: false,
      currentIdentities: [],
      identityType: '',
      count: 0,
      longName: '',
      showNameTooltip: false,
      namePosition: {
        top: 0,
        left: 0
      }
    };
    this.nextStep = this.nextStep.bind(this);
    this.previousStep = this.previousStep.bind(this);
    this.portal = React.createRef();
  }
  interval = 0;

  componentDidMount() {
    const { dispatch } = this.props;
    new Client(config.externalApi).contentSearchAttachmentsGET().then((res) => {
      this.setState({
        attachmentExtensions: res
      });
    });
    this.setState({
      currentStep: this.props?.currentStep
    });
    if (this.props?.currentStep === 5) {
      this.setState({
        progress: 100
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { dispatch } = this.props;
    if (prevProps.messages !== this.props.messages)
      this.setState({
        messageClassList: this.props.messages
      });
    if (
      prevProps.record !== this.props?.record &&
      (this.props.isEditing || this.props.isNotConfirmed)
    ) {
      let allCustodiansList = this.getCustodianIds(this.props?.record?.custodians);
      const containerIDS = this.getContainerIds(this.props?.record?.containers);
      const selectedContainers = this.getContainerNames(containerIDS);
      let initialCustodians = [];
      let initialExternalCustodians = [];
      let allCustodians = [];
      dispatch(formActions.doGetAllCustodiansData(allCustodiansList)).then(() => {
        initialCustodians = this.props?.allCustodiansList;
        initialExternalCustodians = this.props.record?.externalCustodianInfo
          ? this.getInitialExternalCustodians(this.props.record?.externalCustodianInfo)
          : undefined;
        allCustodians = initialCustodians?.concat(initialExternalCustodians);
        dispatch(formActions.doSetSelectedCustodians(allCustodians));
        dispatch(
          formActions.doSetSelectedExternalCustodians(this.props.record?.externalCustodianInfo)
        );
      });
      this.setState({
        disableAttachmentExtensions:
          this.props?.record?.attachmentExtensions.length !== 0 ? false : true,
        disableAttachmentNames: this.props?.record?.attachmentNames.length !== 0 ? false : true,
        disableMessageClass: this.props?.record?.messageClass !== '' ? false : true,
        disableSubject: this.props?.record?.subject !== null ? false : true,
        externalCustodiansList: this.props?.record?.externalCustodians,
        disableFlows:
          this.props?.record?.internalOnly !== null ||
          this.props?.record?.incoming !== null ||
          this.props?.record?.outgoing !== null
            ? false
            : true,
        containerNames: this.props?.record?.containers ? selectedContainers : undefined,
        internalCustodiansList:
          this.props.isEditing || this.props.isNotConfirmed ? initialCustodians : undefined,
        externalCustodianInfo:
          this.props.isEditing || this.props.isNotConfirmed ? initialExternalCustodians : undefined,
        newRows: this.props.isEditing || this.props.isNotConfirmed ? allCustodians : undefined
      });
    }
    if (prevState.currentStep !== this.state.currentStep) {
      dispatch(formActions.doSetCurrentStep(this.state.currentStep));
    }
    if (
      prevProps.legalHoldStats !== this.props.legalHoldStats &&
      this.props.legalHoldStats?.isResultValid
    ) {
      clearInterval(this.interval);
    }
    if (
      prevProps.openFormModal !== this.props.openFormModal &&
      this.props.openFormModal === false
    ) {
      clearInterval(this.interval);
    }
    if (prevProps.currentStep === 5 && this.props.currentStep < 5) {
      clearInterval(this.interval);
    }
    if (prevState.person !== this.state.person && this.state.person?.value) {
      this.setState({
        showTooltip: true
      });
    }
    if (prevState.identityType !== this.state.identityType && this.state.identityType !== '') {
      this.setState({
        showIdentitiesTooltip: true
      });
    }

    if (
      prevState.currentIdentities !== this.state.currentIdentities &&
      this.state.currentIdentities.length > 0
    ) {
      this.setState({
        showIdentitiesTooltip: true
      });
    }

    if (
      prevProps.selectedCustodiansList !== this.props?.selectedCustodiansList ||
      prevProps.selectedExternalCustodians !== this.props?.selectedExternalCustodians
    ) {
      this.setState(
        {
          newRows: this.props?.selectedCustodiansList,
          selectedCustodians: this.props?.selectedCustodiansList,
          externalCustodianInfo: this.props?.selectedExternalCustodians
        },
        () => {
          this.setState({
            externalCustodiansList: this.externalCustodiansFormatter(
              this.props?.selectedExternalCustodians
            ),
            internalCustodiansList: this.getInternalCustodians(this.props.selectedCustodiansList)
          });
        }
      );
    }
    if (
      prevState.newRows !== this.state.newRows ||
      prevState.itemsPerPage !== this.state.itemsPerPage ||
      prevState.currentPage !== this.state.currentPage
    ) {
      const { currentPage, itemsPerPage } = this.state;
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const itemsToDisplay = this.state.newRows?.slice(startIndex, endIndex);
      const totalPages = Math.ceil(this.state.newRows?.length / itemsPerPage);
      const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);
      this.setState({
        itemsToDisplay,
        totalPages,
        pageNumbers
      });
    }
  }

  getContainerIds = (data) => {
    const containerIds = [];
    data?.map((item) => {
      containerIds.push(item.containerId);
    });
    return containerIds;
  };

  getInitialExternalCustodians(data) {
    let externalList = [];
    data?.map((item, index) => {
      externalList.push({
        custodianId: index,
        isExternal: true,
        companyName: item?.companyName,
        smtpAddress: item?.emailAddress,
        displayName: item?.displayName
      });
    });

    return externalList;
  }

  externalCustodiansFormatter(array) {
    let formattedArray = [];
    array?.map((item) => {
      formattedArray.push(item?.emailAddress);
    });
    return formattedArray;
  }

  getInternalCustodians(array) {
    let internalCustodians = [];
    array?.map((item) => {
      if (!item.isExternal) {
        internalCustodians.push(item);
      }
    });
    return internalCustodians;
  }

  renderBuilder = (props) => (
    <div className='query-builder-container mb-3'>
      <div className='query-builder qb-lite'>
        <Builder {...props} />
      </div>
    </div>
  );

  renderResult = ({ tree: immutableTree, config }) => {
    return (
      <div className='query-builder-result w-100'>
        <div className={'form-group mt-3'}>
          <label htmlFor='subjectLine' className={searchFormStyles.container}>
            Email Subject Line
            <input name='subjectLine' checked={true} type='checkbox' />
            <span className={searchFormStyles.checkboxInput}></span>
          </label>
          <pre className={'form-control'}>{QbUtils.sqlFormat(immutableTree, config)}</pre>
        </div>
      </div>
    );
  };

  onChange = (immutableTree, config) => {
    this.setState({
      tree: immutableTree,
      config: config,
      queryStr: QbUtils.sqlFormat(immutableTree, this.state.config)
    });
  };

  nextStep() {
    let currentStep = this.state.currentStep;
    if (currentStep >= count) {
      currentStep = count;
    } else {
      currentStep += 1;
    }
    this.setState({
      currentStep,
      progress: (100 / count) * currentStep
    });
  }

  previousStep() {
    let currentStep = this.state.currentStep;
    if (currentStep <= 1) {
      currentStep = 1;
    } else {
      currentStep -= 1;
    }
    this.setState({
      currentStep,
      progress: (100 / count) * currentStep
    });
  }

  getInitialFlowSelect(record) {
    let initialFlow = 'ALL_EMAILS';
    if (record.internalOnly === null && record.outgoing === null && record.incoming === null) {
      initialFlow = 'ALL_EMAILS';
    }
    if (record.internalOnly === true && record.outgoing === null && record.incoming === null) {
      initialFlow = 'INTERNAL_EMAILS_ONLY';
    }
    if (record.internalOnly === null && record.outgoing === null && record.incoming === true) {
      initialFlow = 'INCOMING_EMAILS_ONLY';
    }
    if (record.internalOnly === null && record.outgoing === true && record.incoming === null) {
      initialFlow = 'OUTGOING_EMAILS_ONLY';
    }
    if (record.internalOnly === false && record.outgoing === null && record.incoming === null) {
      initialFlow = 'INCOMING_AND_OUTGOING';
    }
    return initialFlow;
  }

  iniValues = () => {
    let initialFlow = this.getInitialFlowSelect(this.props?.record);
    const record = {
      ...this.props.record,
      messageClass:
        (this.props.isEditing || this.props.isNotConfirmed) &&
        this.props?.record?.messageClass !== ''
          ? this.props?.record?.messageClass?.split(',')
          : undefined,
      externalCustodians: this.props.isEditing || this.props.isNotConfirmed ? '' : undefined,
      attachmentNames:
        this.props.isEditing || this.props.isNotConfirmed
          ? this.props.record?.attachmentNames.join(' ')
          : undefined,
      flows: this.props.isEditing || this.props.isNotConfirmed ? initialFlow : undefined,
      containerIds:
        (this.props.isEditing || this.props.isNotConfirmed) &&
        this.props?.record?.containers?.length !== 0
          ? this.getContainerIds(this.props?.record?.containers)
          : undefined
    };
    return IniValues(LegalHoldFields, Object.assign({}, record));
  };

  toData = (data) => {
    const arr = [];
    if (typeof data === 'string') {
      return data;
    }
    if (Array.isArray(data)) {
      for (let key in data) {
        const obj = {};
        obj.value = data[key];
        obj.label = data[key];
        arr.push(obj);
      }
    } else {
      for (let key in data) {
        const obj = {};
        obj.value = key;
        obj.label = data[key];
        arr.push(obj);
      }
    }
    return arr;
  };

  containerFormatter = (data) => {
    const arr = [];
    if (Array.isArray(data)) {
      for (let key of data) {
        const obj = {};
        obj.value = key?.containerId;
        obj.label = `${key?.name}` + `*` + `${key?.description}`;
        arr.push(obj);
      }
    }
    return arr;
  };

  FormValidationSchema = [
    Yup.object().shape({
      legalHoldName: Yup.string().trim().required('Name* is a required field'),
      legalHoldDescription: Yup.string().trim().required('Description* is a required field')
    }),
    Yup.object().shape({
      containerIds: Yup.mixed().required('Containers* is a required field'),
      dateFrom: Yup.mixed().when('dateTo', (dateTo, schema) => {
        return (
          dateTo &&
          schema.test({
            test: function (dateFrom) {
              return Date.parse(dateTo) > Date.parse(dateFrom);
            },
            message: 'Start date must be earlier than end date '
          })
        );
      }),
      dateTo: Yup.mixed().required('End* date is a required field')
    })
  ];

  handleSubmit = (values, actions) => {
    const { name: firstName, surName: lastName, email, userName } = this.props.currentUser || {};

    const expireonUser = {
      firstName,
      lastName,
      email,
      userName
    };
    const notConfirmed = this.props.isEditing || this.props.isNotConfirmed;
    let { id, ...data } = PreparedValues(LegalHoldFields, values || {}, notConfirmed);
    data = Object.entries(data).reduce((acc, [key, value]) => {
      if (value !== undefined && value !== null) {
        acc[key] = value;
      }
      return acc;
    }, {});
    if (this.props.isEditing || this.props.isNotConfirmed) {
      data.legalCaseId = this.props.record.legalCaseId;
    }
    let custodiansArr = [],
      attachmentNames = [],
      attachmentExtensions = [];

    if (
      values.attachmentNames &&
      values.attachmentNames.length &&
      (!this.props.isEditing || !this.props.isNotConfirmed)
    ) {
      attachmentNames = values.attachmentNames.trim().split(/\s*[\s,]\s*/);
    }
    if (
      values.attachmentExtensions &&
      values.attachmentExtensions.length >= 1 &&
      (!this.props.isEditing || !this.props.isNotConfirmed)
    ) {
      const selected = values.attachmentExtensions.find((item) => item === '.All');
      if (selected) {
        attachmentExtensions.push('.All');
      } else {
        attachmentExtensions = values.attachmentExtensions;
      }
    }
    if (this.state.internalCustodiansList) {
      this.state.internalCustodiansList?.forEach((item) => {
        custodiansArr.push(item?.custodianId ? item?.custodianId : item?.id);
      });
    }
    const allAttachments = attachmentExtensions?.concat(attachmentNames);
    const containerNames = this.getContainerNames(values?.containerIds);
    this.setState({
      allAttachments: allAttachments,
      containerNames: containerNames
    });
    const filters = {
      ...data,
      messageClass: values.messageClass || [],
      containerIds: values.containerIds,
      attachmentExtensions,
      externalCustodians: this.state.externalCustodiansList,
      custodians: custodiansArr,
      attachmentNames,
      legalCaseId: this.props.legalCaseId,
      externalCustodianInfo: this.state.externalCustodianInfo
    };

    if (this.state.currentStep === 5) {
      this.props.onSubmit(id, {
        ...data,
        messageClass: values.messageClass || [],
        containerIds: values.containerIds,
        attachmentExtensions,
        externalCustodians: this.state.externalCustodiansList,
        custodians: custodiansArr,
        attachmentNames,
        externalCustodianInfo: this.state.externalCustodianInfo,
        legalHoldId: this.props?.createdLegalHoldId
      });
      this.props.dispatch(formActions.doCloseConfirm());
    } else if (this.state.currentStep === 4) {
      if (this.props.isEditing) {
        this.props.onSubmit(id, {
          ...data,
          messageClass: values.messageClass || [],
          containerIds: values.containerIds,
          custodians: custodiansArr,
          attachmentNames: Array.isArray(values?.attachmentNames)
            ? values?.attachmentNames || []
            : values?.attachmentNames?.split(',') || [],
          attachmentExtensions: values.attachmentExtensions || [],
          externalCustodians: this.state.externalCustodiansList
        });
      } else {
        this.props
          .dispatch(
            formActions.doCalculateStatsForLH({
              ...filters,
              expireonUser: expireonUser
            })
          )
          .then(() => {
            this.props.dispatch(
              formActions.doGetDataToSubmit({
                filter: filters,
                expireonUser: expireonUser
              })
            );
            this.props.dispatch(
              listActions.doFetch({
                legalCaseId: this.props.legalCaseId,
                pageNumber: this.props.pageNumber,
                pageSize: this.props.pageSize
              })
            );
            this.interval = setInterval(() => {
              this.props.dispatch(
                formActions.doGetLegalHoldStatistics(this.props?.createdLegalHoldId)
              );
            }, 20000);
          });
        this.nextStep();
      }
    } else {
      this.nextStep();
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  toggleExternalModal = () => {
    this.setState({
      isExternal: !this.state.isExternal,
      fileName: [],
      importedExternal: []
    });
  };

  validateLHName = (value) => {
    if (!value) {
      return undefined;
    }
    let error;
    this.props?.legalHoldNames?.forEach((item) => {
      const createdLegalHold = item?.toLowerCase().trim();
      const newLegalHold = value?.toLowerCase().trim();
      if (createdLegalHold === newLegalHold) error = 'This legal hold name already exists';
    });
    return error;
  };

  handleFlowSelect(event, form) {
    if (form.values.flows === FLOW_OPTIONS_MAP.ALL_EMAILS) {
      form.values.internalOnly = null;
      form.values.outgoing = null;
      form.values.incoming = null;
    }
    if (form.values.flows === FLOW_OPTIONS_MAP.INTERNAL_EMAILS_ONLY) {
      form.values.internalOnly = true;
      form.values.outgoing = null;
      form.values.incoming = null;
    }
    if (form.values.flows === FLOW_OPTIONS_MAP.INCOMING_EMAILS_ONLY) {
      form.values.internalOnly = null;
      form.values.outgoing = null;
      form.values.incoming = true;
    }
    if (form.values.flows === FLOW_OPTIONS_MAP.OUTGOING_EMAILS_ONLY) {
      form.values.internalOnly = null;
      form.values.outgoing = true;
      form.values.incoming = null;
    }
    if (form.values.flows === FLOW_OPTIONS_MAP.INCOMING_AND_OUTGOING) {
      form.values.internalOnly = false;
      form.values.outgoing = null;
      form.values.incoming = null;
    }
  }

  handleDateRangeSelect(form) {
    if (form.values.dateRange) {
      form.values.dateTo = new Date();
      form.values.dateFrom = new Date();
      form.values.dateFrom.setHours(0, 0, 0);
      form.values.dateTo.setHours(23, 59, 59, 999);
    }
    if (form.values.dateRange === DATE_RANGE_OPTIONS_MAP.lAST_7_DAYS) {
      form.values.dateFrom.setDate(form.values.dateFrom.getDate() - 7);
    }
    if (form.values.dateRange === DATE_RANGE_OPTIONS_MAP.lAST_30_DAYS) {
      form.values.dateFrom.setDate(form.values.dateFrom.getDate() - 30);
    }
    if (form.values.dateRange === DATE_RANGE_OPTIONS_MAP.lAST_90_DAYS) {
      form.values.dateFrom.setMonth(form.values.dateFrom.getMonth() - 3);
    }
    if (form.values.dateRange === DATE_RANGE_OPTIONS_MAP.lAST_1_YEAR) {
      form.values.dateFrom.setFullYear(form.values.dateFrom.getFullYear() - 1);
    }
    if (form.values.dateRange === DATE_RANGE_OPTIONS_MAP.lAST_3_YEARS) {
      form.values.dateFrom.setFullYear(form.values.dateFrom.getFullYear() - 3);
    }
    if (form.values.dateRange === DATE_RANGE_OPTIONS_MAP.lAST_7_YEARS) {
      form.values.dateFrom.setFullYear(form.values.dateFrom.getFullYear() - 7);
    }
    if (form.values.dateRange === DATE_RANGE_OPTIONS_MAP.lAST_10_YEARS) {
      form.values.dateFrom.setFullYear(form.values.dateFrom.getFullYear() - 10);
    }
  }

  handleStartDateChange = (form) => {
    if (!form.values.dateFrom) {
      form.values.dateFrom = new Date();
      form.values.dateFrom.setHours(0, 0, 0);
      form.values.dateFrom.setFullYear(form.values.dateFrom.getFullYear() - 30);
    }
  };

  handleEndDateChange = (form) => {
    if (!form.values.dateTo) {
      form.values.dateTo = new Date();
      form.values.dateTo.setHours(23, 59, 59, 999);
    }
  };

  getCustodianIds = (custodiansList) => {
    let custodianIds = [];
    custodiansList?.map((item) => {
      custodianIds.push(item?.custodianId);
    });
    return custodianIds;
  };

  handleDiscard = async (form) => {
    const { dispatch } = this.props;
    const legalCaseId = this.props?.legalCaseId;
    const legalHoldId = this.props?.createdLegalHoldId;
    await dispatch(
      formActions.doDiscardLegalHold(legalCaseId, this.props?.currentUser, legalHoldId)
    ).then(() => {
      toast.success('Legal Hold discarded');
      dispatch(formActions.doFindLegalHoldNames(legalCaseId));
      this.previousStep();
      dispatch(
        listActions.doFetch({
          legalCaseId: legalCaseId,
          pageNumber: this.props.pageNumber,
          pageSize: this.props.pageSize
        })
      );
    });
  };

  getContainerNames = (containerIds) => {
    let selectedContainers = [];
    this.props?.containers?.map((item) => {
      let selected = containerIds?.find((el) => el === item?.containerId);
      if (selected) {
        selectedContainers.push(item.name);
      }
    });
    return selectedContainers;
  };

  validateContainer = (value) => {
    if (!value) {
      return undefined;
    }
    let error;
    if (value?.length === 0) {
      error = 'Please choose a containers to continue';
    }
    return error;
  };

  openCustodianModal() {
    const { dispatch } = this.props;
    dispatch(formActions.doOpenInternalSelection()).then(() => {
      if (this.inputRef.current) {
        this.inputRef.current.focus();
      }
    });
  }

  closeCustodianModal() {
    const { dispatch } = this.props;
    if (this.props.temporaryCustodiansCount > 0) {
      dispatch(formActions.doOpenCustodianConfirmation());
    } else {
      dispatch(formActions.doCloseInternalSelection());
    }
  }

  openExternalCustodianModal() {
    this.setState({
      openExternalModal: true
    });
  }

  closeExternalCustodianModal() {
    this.setState({
      openExternalModal: false
    });
  }
  actionFormatter = (cell, row) => {
    return (
      <div className={s.icon}>
        <span onClick={() => this.handleRemoveSelected(row)}>
          <i
            className='fa fa-trash'
            style={{
              width: '14px',
              height: '14px'
            }}
          />
        </span>
      </div>
    );
  };

  rowClasses = (row, rowIndex) => {
    let isSelected = this.isCustodianSelected(row);
    if (!isSelected) {
      return `${s.customRowCursor} ${s.customCheckCursor}`;
    } else {
      return `${s.customRowCursor} ${s.customRemoveCursor}`;
    }
  };

  handleRemoveSelected = async (row) => {
    const { dispatch } = this.props;
    const custodianId = row?.custodianId;
    let updatedArray = this.props?.selectedCustodiansList?.filter(
      (item) => item?.custodianId !== custodianId
    );
    await dispatch(formActions.doSetSelectedCustodians(updatedArray));
    if (row?.isExternal) {
      let updatedExternalInfo = this.state.externalCustodianInfo?.filter(
        (item) => item?.emailAddress !== row?.smtpAddress
      );
      let updatedExternalList = this.state.externalCustodiansList?.filter(
        (item) => item !== row?.smtpAddress
      );
      dispatch(formActions.doSetSelectedExternalCustodians(updatedExternalInfo));
      this.setState({
        externalCustodianInfo: updatedExternalInfo,
        externalCustodiansList: updatedExternalList
      });
    }
    if (this.state.itemsToDisplay?.length === 0 && this.state.currentPage > 1) {
      this.setState({
        currentPage: this.state.currentPage - 1
      });
    }
  };

  isCustodianSelected = (row) => {
    let isSelected = false;
    const found = this.state.selectedCustodians.find(
      (item) => item.custodianId === row.custodianId
    );
    if (found) {
      isSelected = true;
    }
    return isSelected;
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handlePerPageChange = (item) => {
    this.setState({
      itemsPerPage: item,
      currentPage: 1,
      paginationModalOpen: false
    });
  };

  handleUserIdentities = (custodianId, identityType) => {
    const { dispatch } = this.props;
    dispatch(formActions.doGetCustodianIdentities(custodianId)).then(() => {
      let newList = [];
      this.props.userIdentities?.map((item) => {
        if (item?.identityType === identityType) {
          newList = item?.value;
        }
      });
      this.setState({
        currentIdentities: newList
      });
    });
  };

  firstStep = (form) => (
    <div>
      <InputFormItem
        name={'legalHoldName'}
        schema={LegalHoldFields}
        validate={this.validateLHName}
        autoFocus
      />
      <div>
        <TextAreaFormItem
          name={'legalHoldDescription'}
          textAreaStyle={{ height: '250px' }}
          schema={LegalHoldFields}
        />
      </div>
    </div>
  );

  secondStep = (form) => (
    <Row>
      <Col>
        <SelectByAddingFormItem
          name={'containerIds'}
          schema={LegalHoldFields}
          mode={'fetch'}
          isMulti={true}
          key={'containerIds'}
          widgetHeight={'293px'}
          isEditing={this.props.isEditing || this.props.isNotConfirmed}
          data={this.containerFormatter(this.props.containers)}
          validate={this.validateContainer}
          isContainer={true}
        />
      </Col>
      <Col sm={5}>
        <Col sm={12} className={'mb-3'}>
          <SelectFormItem
            name={'dateRange'}
            schema={LegalHoldFields}
            placeholder={'Between'}
            onChange={this.handleDateRangeSelect(form)}
            mode={'fetch'}
            isClearable={false}
            data={this.toData(DATE_RANGE_OPTIONS)}
          />
        </Col>
        <Col sm={12} className={'mb-3'}>
          <DatePickerFormItem
            name={'dateFrom'}
            schema={LegalHoldFields}
            showTimeInput
            onChange={this.handleStartDateChange(form)}
          />
          <ErrorMessage name='dateFrom'>
            {(msg) => <div className={s.errorMessage}>{msg}</div>}
          </ErrorMessage>
        </Col>
        <Col sm={12} className={'mb-3'}>
          <DatePickerFormItem
            name={'dateTo'}
            schema={LegalHoldFields}
            showTimeInput
            onChange={this.handleEndDateChange(form)}
          />
          <ErrorMessage name='dateTo'>
            {(msg) => <div className={s.errorMessage}>{msg}</div>}
          </ErrorMessage>
        </Col>
      </Col>
    </Row>
  );

  thirdStep = (form, columns) => (
    <>
      {this.state.showTooltip && (
        <LegalHoldTooltip currentCustodian={this.state.person} position={this.state.position} />
      )}
      {this.state.showIdentitiesTooltip && (
        <UserIdentitiesTooltip
          identitiesList={this.state.currentIdentities}
          position={this.state.position}
          identityType={this.state.identityType}
          count={this.state.count}
        />
      )}
      {this.state.showNameTooltip && (
        <LongNameTooltip longName={this.state.longName} position={this.state.namePosition} />
      )}
      <div className='d-flex justify-content-between align-items-center mt-5 mb-2'>
        <p className='fw-semi-bold h5'> Selected Custodians</p>
        <div>
          <button
            className='btn me-3 fw-semi-bold'
            type='button'
            onClick={() => this.openCustodianModal()}
            style={{ height: '37px', width: 'auto' }}
          >
            Add from directory
          </button>
          <button
            className='btn me-3 fw-semi-bold'
            type='button'
            onClick={() => {
              this.openExternalCustodianModal();
            }}
            style={{ height: '37px', width: 'auto' }}
          >
            Add custom address
          </button>
        </div>
      </div>
      <div className='h-100 mb-5'>
        <ToolkitProvider
          bootstrap4
          keyField='custodianId'
          columns={columns}
          data={this.state.itemsToDisplay}
        >
          {(props) => (
            <>
              <div>
                <BootstrapTable
                  bordered={false}
                  classes={`table-striped table-hover  mt-4 fs-sm ${s.internalCustodiansTable}`}
                  remote={{
                    filter: false,
                    pagination: false,
                    sort: true,
                    cellEdit: false
                  }}
                  noDataIndication={emptyDataMessage}
                  rowClasses={this.rowClasses}
                  {...props.baseProps}
                />
              </div>
            </>
          )}
        </ToolkitProvider>
      </div>
      <Modal
        size='lg'
        isOpen={this.props.openInternalSelectionModal}
        className={`themeStyle${this.props.currentTheme?.replace('#', '')}`}
      >
        <ModalHeader toggle={() => this.closeCustodianModal()}>Select Custodians</ModalHeader>
        <ModalBody>
          <CustodianSelectionList inputRef={this.inputRef} />
        </ModalBody>
      </Modal>
      <Modal
        size='md'
        isOpen={this.state.openExternalModal}
        className={`themeStyle${this.props.currentTheme?.replace('#', '')}`}
      >
        <ModalHeader toggle={() => this.closeExternalCustodianModal()}>
          Please enter the following:
        </ModalHeader>
        <ModalBody>
          <ExternalCustodiansForm />
        </ModalBody>
      </Modal>
    </>
  );

  fourthStep = (form) => (
    <div>
      <Row style={{ marginBottom: 32 }}>
        <Col sm={12}>
          <div>
            <div className={'d-flex justify-content-end'} style={{ marginBottom: 0 }}>
              {/*<a*/}
              {/*    className={'text-primary'}*/}
              {/*    onClick={() =>*/}
              {/*        this.setState({*/}
              {/*          builderVisible: !this.state.builderVisible,*/}
              {/*        })*/}
              {/*    }*/}
              {/*>*/}
              {/*  Builder*/}
              {/*</a>*/}
            </div>
            <div
              style={{
                display: this.state.builderVisible ? 'block' : 'none'
              }}
              className={'w-100'}
            >
              {this.renderResult(this.state)}
            </div>
            <div
              style={{
                display: this.state.builderVisible ? 'none' : 'block'
              }}
              className={'w-100'}
            >
              <label htmlFor='enableSubject' className={searchFormStyles.container}>
                <input
                  name='enableSubject'
                  checked={!this.state.disableSubject}
                  style={{ position: 'absolute', marginTop: '12px' }}
                  type='checkbox'
                />
                <span
                  className={searchFormStyles.checkboxInput}
                  onClick={(e) => {
                    if (!this.state.disableSubject) {
                      form.setFieldValue('subject', null);
                    }
                    form.setFieldValue('enableSubject', !this.state.disableSubject);
                    this.setState({
                      disableSubjectIndex: this.state.disableSubjectIndex + 1,
                      disableSubject: !this.state.disableSubject
                    });
                  }}
                ></span>
              </label>

              <InputFormItem
                name={'subject'}
                key={this.state.disableSubjectIndex}
                showCheckbox={true}
                isDisabled={this.state.disableSubject}
                schema={LegalHoldFields}
              />
            </div>
            <div
              sm={12}
              style={{
                display: this.state.builderVisible ? 'block' : 'none'
              }}
              className={'w-100'}
            >
              <Query
                {...config1}
                value={this.state.tree}
                onChange={this.onChange}
                renderBuilder={this.renderBuilder}
              />
            </div>
          </div>
        </Col>
        <Col>
          <label htmlFor='messageClass' className={searchFormStyles.container}>
            <input name='messageClass' checked={!this.state.disableMessageClass} type='checkbox' />
            <span
              className={searchFormStyles.checkboxInput}
              style={{ marginTop: '10px' }}
              onClick={(e) => {
                if (!this.state.disableMessageClass) {
                  form.setFieldValue('messageClass', null);
                }
                this.setState({
                  disableMessageClass: !this.state.disableMessageClass
                });
              }}
            ></span>
          </label>
          <SelectByAddingFormItem
            name={'messageClass'}
            placeholder={form.values.messageClass ? '' : 'All'}
            schema={LegalHoldFields}
            showCheckbox={true}
            isDisabled={this.state.disableMessageClass}
            mode={'fetch'}
            isMulti={true}
            key={'messageClass'}
            widgetHeight={'208px'}
            data={this.toData(this.state.messageClassList)}
          />
        </Col>
        <Col>
          <Col sm={12}>
            <label htmlFor='flows' className={searchFormStyles.container}>
              <input
                name='flows'
                checked={!this.state.disableFlows}
                style={{ position: 'absolute', marginTop: '12px' }}
                type='checkbox'
              />
              <span
                className={searchFormStyles.checkboxInput}
                onClick={(e) => {
                  if (!this.state.disableFlows) {
                    form.setFieldValue('flows', null);
                  }
                  this.setState({
                    disableFlows: !this.state.disableFlows
                  });
                }}
              ></span>
            </label>
            <SelectFormItem
              name={'flows'}
              schema={LegalHoldFields}
              showCheckbox={true}
              isDisabled={this.state.disableFlows}
              onChange={this.handleFlowSelect(event, form)}
              mode={'fetch'}
              isClearable={false}
              data={this.toData(FLOW_OPTIONS)}
            />
          </Col>
          <Col>
            <label htmlFor='attachmentExtensions' className={searchFormStyles.container}>
              <input
                name='attachmentExtensions'
                checked={!this.state.disableAttachmentExtensions}
                type='checkbox'
              />
              <span
                className={searchFormStyles.checkboxInput}
                style={{ marginTop: '10px' }}
                onClick={(e) => {
                  if (!this.state.disableAttachmentExtensions) {
                    form.setFieldValue('attachmentExtensions', null);
                  }
                  this.setState({
                    disableAttachmentExtensions: !this.state.disableAttachmentExtensions
                  });
                }}
              ></span>
            </label>
            <SelectFormItem
              name={'attachmentExtensions'}
              placeholder={form.values.attachmentExtensions ? '' : 'All'}
              schema={LegalHoldFields}
              showCheckbox={true}
              isDisabled={this.state.disableAttachmentExtensions}
              mode={'fetch'}
              isMulti={true}
              data={this.toData(this.state?.attachmentExtensions)}
              isRecord={true}
            />
            {form?.errors?.attachmentExtensions && (
              <p style={{ color: 'red', marginTop: -12 }}>{form?.errors?.attachmentExtensions}</p>
            )}
          </Col>
          <Col>
            <label htmlFor='attachmentNames' className={searchFormStyles.container}>
              <input
                checked={!this.state.disableAttachmentNames}
                name='attachmentNames'
                type='checkbox'
              />
              <span
                className={searchFormStyles.checkboxInput}
                style={{ marginTop: '10px' }}
                onClick={(e) => {
                  if (!this.state.disableAttachmentNames) {
                    form.setFieldValue('attachmentNames', null);
                  }
                  this.setState({
                    disableAttachmentNamesIndex: this.state.disableAttachmentNamesIndex + 1,
                    disableAttachmentNames: !this.state.disableAttachmentNames
                  });
                }}
              ></span>
            </label>
            <InputFormItem
              key={this.state.disableAttachmentNamesIndex}
              showCheckbox={true}
              isDisabled={this.state.disableAttachmentNames}
              name={'attachmentNames'}
              schema={LegalHoldFields}
            />
          </Col>
        </Col>
      </Row>
    </div>
  );

  fifthStep = (form) => (
    <>
      <div className={s.mainContainer}>
        <div
          style={{
            width:
              this.state.internalCustodiansList?.length !== 0 ||
              this.state.containerNames?.length !== 0
                ? '60%'
                : '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'space-between'
          }}
        >
          <div>
            <p className='d-flex mb-1'>
              <span className={s.title}>Hold Name:</span>
              <span className={s.legalHoldName}>{form.values.legalHoldName}</span>
            </p>
            <p className='d-flex mb-1'>
              <span className={s.title}>Description:</span>
              <span className={s.legalHoldDescription}>{form.values.legalHoldDescription}</span>
            </p>
          </div>
          <div className={'mt-3 mb-0'}>
            <p className={`${s.title} mb-3`}>Filter by</p>
            <p className='mb-2'>
              <span className={s.title}>Date :</span>
              <span className={'me-2'}>{dataFormat.dateTimeFormatter(form.values.dateFrom)}</span>
              <span className={s.title}>To:</span>
              <span>{dataFormat.dateTimeFormatter(form.values.dateTo)}</span>
            </p>
            <p className='mb-2'>
              <span className={s.title}>Direction:</span>
              <span>
                {this.props?.record?.internalOnly ? (
                  <span>Internal Emails Only</span>
                ) : form.values?.incoming === null &&
                  form.values?.outgoing === null &&
                  form.values?.internalOnly === false ? (
                  <span>Incoming & Outgoing</span>
                ) : form.values?.outgoing ? (
                  <span>Outgoing Emails Only</span>
                ) : form.values?.incoming ? (
                  <span>Incoming Emails Only</span>
                ) : (
                  <span>All Emails</span>
                )}
              </span>
              <span>
                {form.values?.noOtherInternalRecipient ? (
                  <span className={'ms-2'}>(No other internal recipient)</span>
                ) : null}
              </span>
            </p>
            <p className='mb-2'>
              <span className={s.title}>Subject:</span>
              <span>{form.values.subject ? form.values.subject : <span>(No Filter)</span>}</span>
            </p>
            {form.values?.messageClass && (
              <div className={'d-flex'}>
                <p className={`${s.title} mb-3 `}>Message Class:</p>
                <span className={s.filterContainer}>
                  {form.values?.messageClass?.map((item) => (
                    <span style={{ margin: '3px' }}>{item}</span>
                  ))}
                </span>
              </div>
            )}
            {this.state.allAttachments?.length !== 0 && (
              <div className={'d-flex'}>
                <p className={s.title}>Attachment:</p>
                <span className={s.filterContainer}>
                  {this.state.allAttachments?.map((item) => (
                    <span style={{ margin: '3px' }}>{item}</span>
                  ))}
                </span>
              </div>
            )}
          </div>
        </div>
        {form.values?.custodians ||
        this.state.externalCustodiansList?.length !== 0 ||
        this.state.containerNames?.length !== 0 ? (
          <div className={`${s.custodiansContainer} mb-3`}>
            {this.state.containerNames?.length !== 0 && (
              <div className={s.custodiansList}>
                <p className={`${s.title} mb-1`}>Containers</p>
                <p className={s.custodianNames}>
                  {this.state.containerNames?.map((item) => (
                    <p className='mb-1'>{item}</p>
                  ))}
                </p>
              </div>
            )}
            {this.state.internalCustodiansList?.length !== 0 && (
              <div className={s.custodiansList}>
                <p className={`${s.title} mb-1`}>Custodians</p>
                <p className={s.custodianNames}>
                  {this.state.showTooltip && (
                    <LegalHoldTooltip
                      currentCustodian={this.state.person}
                      position={this.state.position}
                    />
                  )}
                  {this.state.internalCustodiansList?.map((item, index) => (
                    <p
                      className={s.custodianName}
                      onClick={() => {
                        this.setState({
                          showTooltip: false,
                          person: {}
                        });
                      }}
                    >
                      <span>{item?.displayName}</span>
                      <span
                        style={{
                          position: 'relative',
                          cursor: 'pointer',
                          zIndex: 'auto'
                        }}
                        key={index}
                      >
                        <ImInfo
                          onClick={(e) => {
                            this.handleGetCustodianDetails(item?.custodianId);
                            this.setState({
                              position: {
                                top: e.pageY,
                                left: e.pageX
                              },
                              showTooltip: true
                            });
                          }}
                          className='ms-3 me-2'
                        />
                      </span>
                    </p>
                  ))}
                </p>
              </div>
            )}
            {this.state.externalCustodiansList?.length !== 0 && (
              <div className={s.custodiansList}>
                <p className={`${s.title} mb-1`}>External Custodians</p>
                <p className={s.custodianNames}>
                  {this.state.externalCustodiansList?.map((item) => (
                    <p className='mb-1'>{item}</p>
                  ))}
                </p>
              </div>
            )}
          </div>
        ) : null}
      </div>
      <div className={s.results}>
        <div className={s.resultHeader}>Results</div>
        <div className={s.resultStatsContainer}>
          <div>
            <p className={s.statsLabel}>Count:</p>
            {this.props.legalHoldStats?.isResultValid &&
            this.props.legalHoldStats?.itemCount !== null ? (
              <div className={s.stats}>
                {toReadableSizeFormat.numberWithCommas(this.props.legalHoldStats?.itemCount)}
              </div>
            ) : (
              <div className={s.calculatingText}>
                <span>Calculating... </span>
                <span
                  className='spinner-border text-light ms-1'
                  role='status'
                  style={{ width: '16px', height: '16px' }}
                />
              </div>
            )}
          </div>
          <div>
            <p className={s.statsLabel}>Size:</p>
            {this.props.legalHoldStats?.isResultValid &&
            this.props.legalHoldStats?.itemSizeInBytes !== null ? (
              <div className={s.stats}>
                {dataFormat.sizeFormatter(this.props.legalHoldStats?.itemSizeInBytes)}
              </div>
            ) : (
              <div className={s.calculatingText}>
                <span>Calculating... </span>
                <span
                  className='spinner-border text-light ms-1'
                  role='status'
                  style={{ width: '16px', height: '16px' }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );

  renderStepContent(currentStep, form, columns) {
    switch (currentStep) {
      case 1:
        return this.firstStep(form);

      case 2:
        return this.secondStep(form);
      case 3:
        return this.thirdStep(form, columns);
      case 4:
        return this.fourthStep(form);
      case 5:
        return this.fifthStep(form);
      default:
        return this.firstStep(form);
    }
  }

  handleGetCustodianDetails = (id) => {
    const url = `${config.externalApi}/legal/api/Custodian/GetCustodianById?custodianId=${id}`;

    fetch(url, {
      ...defaultOptions
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((details) => {
        this.setState({
          person: details
        });
      })
      .then(() => {
        this.setState({
          showTooltip: true
        });
      })
      .catch((error) => {
        console.error('Error fetching custodian details:', error);
      });
  };

  renderForm() {
    const { saveLoading } = this.props;
    const currentStep = this.state.currentStep;
    const validateStep = this.state.currentStep - 1;

    const columns = [
      {
        dataField: 'displayName',
        text: 'Display Name',
        headerFormatter: (column, colIndex) => {
          return (
            <div>
              <p className='mb-0'>{column.text}</p>
              <p className='mb-0 fw-normal'>Department</p>
            </div>
          );
        },
        formatter: (cell, row) => {
          return (
            <div className='w-100 d-flex align-items-center'>
              {row.isExternal ? (
                <span className='me-3'>
                  <BiCopyright style={{ marginLeft: '8px', width: '18px', height: '18px' }} />
                </span>
              ) : (
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => {
                    this.setState({
                      position: {
                        top: e.pageY,
                        left: e.pageX
                      }
                    });
                  }}
                >
                  <ImInfo
                    onClick={(e) => {
                      this.handleGetCustodianDetails(row?.custodianId);
                      this.setState({
                        showTooltip: true,
                        showIdentitiesTooltip: false,
                        showNameTooltip: false
                      });
                    }}
                    style={{
                      marginRight: '8px',
                      width: '16px',
                      height: '16px',
                      color: '#2d8515'
                    }}
                  />
                </span>
              )}

              <div className='w-100'>
                {row?.isExternal ? (
                  <p
                    className={`mb-0 fw-semi-bold`}
                    onMouseOver={(e) => {
                      if (row?.displayName?.length > 10) {
                        this.setState({
                          showNameTooltip: true,
                          longName: row?.displayName,
                          namePosition: {
                            top: e.pageY,
                            left: e.pageX
                          }
                        });
                      }
                    }}
                    onMouseLeave={() => {
                      if (row?.displayName?.length > 10) {
                        this.setState({
                          showNameTooltip: false,
                          longName: '',
                          namePosition: {
                            top: 0,
                            left: 0
                          }
                        });
                      }
                    }}
                  >
                    {row?.displayName?.length <= 10
                      ? row?.displayName
                      : `${row?.displayName?.slice(0, 10)}...`}
                  </p>
                ) : (
                  <p
                    className={`mb-0 fw-semi-bold`}
                    onMouseOver={(e) => {
                      if (row?.displayName?.length > 15) {
                        this.setState({
                          showNameTooltip: true,
                          longName: row?.displayName,
                          namePosition: {
                            top: e.pageY,
                            left: e.pageX
                          }
                        });
                      }
                    }}
                    onMouseLeave={() => {
                      if (row?.displayName?.length > 15) {
                        this.setState({
                          showNameTooltip: false,
                          longName: '',
                          namePosition: {
                            top: 0,
                            left: 0
                          }
                        });
                      }
                    }}
                  >
                    {row?.displayName?.length <= 15
                      ? row?.displayName
                      : `${row?.displayName?.slice(0, 15)}...`}
                  </p>
                )}
                <div className='d-flex'>
                  {row?.isExternal && row?.companyName ? (
                    <p
                      className='mb-0'
                      onMouseOver={(e) => {
                        if (row?.companyName?.length > 10) {
                          this.setState({
                            showNameTooltip: true,
                            longName: row?.companyName,
                            namePosition: {
                              top: e.pageY,
                              left: e.pageX
                            }
                          });
                        }
                      }}
                      onMouseLeave={() => {
                        if (row?.companyName?.length > 10) {
                          this.setState({
                            showNameTooltip: false,
                            longName: '',
                            namePosition: {
                              top: 0,
                              left: 0
                            }
                          });
                        }
                      }}
                    >
                      {row?.companyName?.length <= 10
                        ? row?.companyName
                        : `${row?.companyName?.slice(0, 10)}...`}
                    </p>
                  ) : (
                    <>
                      {row?.department && (
                        <p
                          className='mb-0'
                          onMouseOver={(e) => {
                            if (row?.department?.length > 15) {
                              this.setState({
                                showNameTooltip: true,
                                longName: row?.department,
                                namePosition: {
                                  top: e.pageY,
                                  left: e.pageX
                                }
                              });
                            }
                          }}
                          onMouseLeave={() => {
                            if (row?.department?.length > 15) {
                              this.setState({
                                showNameTooltip: false,
                                longName: '',
                                namePosition: {
                                  top: 0,
                                  left: 0
                                }
                              });
                            }
                          }}
                        >
                          {row?.department?.length <= 15
                            ? row?.department
                            : `${row?.department?.slice(0, 15)}...`}
                        </p>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          );
        }
      },
      {
        dataField: 'smtpAddress',
        text: 'Email Address',
        headerFormatter: (column, colIndex) => {
          return (
            <div>
              <p className='mb-0'>{column.text}</p>
              <p className='mb-0 fw-normal'>Sam Account</p>
            </div>
          );
        },
        formatter: (cell, row) => (
          <div className='d-flex justify-content-between'>
            <div>
              {row?.smtpAddress && (
                <p
                  className={`mb-0 fw-semi-bold`}
                  onMouseOver={(e) => {
                    if (row?.smtpAddress?.length > 30) {
                      this.setState({
                        showNameTooltip: true,
                        longName: row?.smtpAddress,
                        namePosition: {
                          top: e.pageY,
                          left: e.pageX
                        }
                      });
                    }
                  }}
                  onMouseLeave={() => {
                    if (row?.smtpAddress?.length > 30) {
                      this.setState({
                        showNameTooltip: false,
                        longName: '',
                        namePosition: {
                          top: 0,
                          left: 0
                        }
                      });
                    }
                  }}
                >
                  {row?.smtpAddress?.length <= 30
                    ? row?.smtpAddress
                    : `${row?.smtpAddress?.slice(0, 30)}...`}
                </p>
              )}
              {!row?.isExternal ? (
                <p
                  className='mb-0'
                  onMouseOver={(e) => {
                    if (row?.samAccountName?.length > 30) {
                      this.setState({
                        showNameTooltip: true,
                        longName: row?.samAccountName,
                        namePosition: {
                          top: e.pageY,
                          left: e.pageX
                        }
                      });
                    }
                  }}
                  onMouseLeave={() => {
                    if (row?.samAccountName?.length > 30) {
                      this.setState({
                        showNameTooltip: false,
                        longName: '',
                        namePosition: {
                          top: 0,
                          left: 0
                        }
                      });
                    }
                  }}
                >
                  {row?.samAccountName?.length <= 30
                    ? row?.samAccountName
                    : `${row?.samAccountName?.slice(0, 30)}...`}{' '}
                </p>
              ) : (
                <p className='mb-0'>
                  added by: {this.props?.currentUser?.name} {this.props?.currentUser?.surName}{' '}
                </p>
              )}
            </div>
            <div>
              {row?.custodianIdentitiesCount?.map((item) => {
                return (
                  <>
                    <p className='d-flex justify-content-between'>
                      <span className='me-2'>{item.count}</span>
                      <span
                        className={`${
                          item.identityType === 'SMTP' ? s.identitySmtpBox : s.identityX500Box
                        }`}
                        onClick={(e) => {
                          this.handleUserIdentities(row?.custodianId, item?.identityType);
                          this.setState({
                            showIdentitiesTooltip: true,
                            position: {
                              top: e.pageY,
                              left: e.pageX
                            },
                            identityType: item?.identityType,
                            showTooltip: false,
                            showNameTooltip: false,
                            count: item?.count
                          });
                        }}
                      >
                        {item.identityType}
                      </span>
                    </p>
                  </>
                );
              })}
            </div>
          </div>
        )
      },
      {
        dataField: 'upn',
        text: 'Source',
        headerFormatter: (column, colIndex) => {
          return (
            <div>
              <p className='mb-0'>{column.text}</p>
              <p className='mb-0 fw-normal'>User principal name</p>
            </div>
          );
        },
        formatter: (cell, row) => (
          <>
            {row.isExternal ? (
              <p className={s.customAddress}>Custom Address</p>
            ) : (
              <div className='w-100'>
                <p className={`mb-0 fw-semi-bold`}>{row?.hasAdRecord ? 'AD' : 'N/A'} </p>
                {row?.upn && (
                  <p
                    className='mb-0'
                    onMouseOver={(e) => {
                      if (row?.upn?.length > 20) {
                        this.setState({
                          showNameTooltip: true,
                          longName: row?.upn,
                          namePosition: {
                            top: e.pageY,
                            left: e.pageX
                          }
                        });
                      }
                    }}
                    onMouseLeave={() => {
                      if (row?.upn?.length > 20) {
                        this.setState({
                          showNameTooltip: false,
                          longName: '',
                          namePosition: {
                            top: 0,
                            left: 0
                          }
                        });
                      }
                    }}
                  >
                    {row?.upn?.length <= 20 ? row?.upn : `${row?.upn?.slice(0, 20)}...`}
                  </p>
                )}
              </div>
            )}
          </>
        )
      },
      {
        dataField: 'custodianId',
        text: 'Actions',
        formatter: (cell, row) => {
          return this.actionFormatter.bind(this)(cell, row);
        }
      }
    ];

    return (
      <div className={s.root}>
        <Row>
          <Col sm={12}>
            <Nav className={s.wizardNavigation}>
              <NavItem>
                <NavLink
                  className={classnames({
                    wizardActiveItem: currentStep === 1
                  })}
                >
                  <small>1.</small>
                  &nbsp; General
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    wizardActiveItem: currentStep === 2
                  })}
                >
                  <small>2.</small>
                  &nbsp; Scope
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    wizardActiveItem: currentStep === 3
                  })}
                >
                  <small>3.</small>
                  &nbsp; Custodians
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    wizardActiveItem: currentStep === 4
                  })}
                >
                  <small>4.</small>
                  &nbsp; Optional Filters
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    wizardActiveItem: currentStep === 5
                  })}
                >
                  <small>5.</small>
                  &nbsp; Statistics
                </NavLink>
              </NavItem>
            </Nav>
            <Progress value={this.state.progress} className='progress-xs-wizard' />
            <div>
              <div className={s.stepBody}>
                <Formik
                  onSubmit={this.handleSubmit}
                  initialValues={this.iniValues()}
                  validationSchema={this.FormValidationSchema[validateStep]}
                  render={(form) => {
                    return (
                      <form onSubmit={form.handleSubmit}>
                        <div
                          style={{
                            minHeight: '400px'
                          }}
                          onClick={() => {
                            this.setState({
                              showTooltip: false,
                              person: {}
                            });
                            if (this.state.showIdentitiesTooltip) {
                              this.setState({
                                showIdentitiesTooltip: false,
                                currentIdentities: []
                              });
                            }
                            if (this.state.showNameTooltip) {
                              this.setState({
                                showNameTooltip: false
                              });
                            }
                          }}
                        >
                          {this.renderStepContent(this.state.currentStep, form, columns)}
                        </div>
                        <div>
                          <ul
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              width: '100%'
                            }}
                          >
                            <li className='previous'>
                              <Button
                                hidden={currentStep === 1 || currentStep === 5}
                                color='primary'
                                onClick={this.previousStep}
                              >
                                <i className='fa fa-caret-left' />
                                &nbsp;Previous
                              </Button>
                            </li>
                            {currentStep === 3 && this.state.itemsToDisplay?.length !== 0 && (
                              <div className='d-flex mt-1'>
                                {this.state.newRows?.length !== 0 && (
                                  <p className='me-4 mt-2'>Total: {this.state.newRows?.length}</p>
                                )}
                                <div className='d-flex'>
                                  <button
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.handlePageChange(1);
                                    }}
                                    className={s.paginationNext}
                                  >
                                    {'<<'}
                                  </button>
                                  <button
                                    onClick={(e) => {
                                      e.preventDefault();
                                      if (this.state.currentPage !== 1) {
                                        this.handlePageChange(this.state.currentPage - 1);
                                      }
                                    }}
                                    className={s.paginationNext}
                                  >
                                    {'<'}
                                  </button>
                                  {this.state.pageNumbers?.map((page) => (
                                    <button
                                      key={page}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.handlePageChange(page);
                                      }}
                                      className={
                                        page === this.state.currentPage
                                          ? `${s.activeLinkClass}`
                                          : `${styles.paginationNext}`
                                      }
                                    >
                                      {page}
                                    </button>
                                  ))}
                                  <button
                                    onClick={(e) => {
                                      e.preventDefault();
                                      if (this.state.currentPage + 1 <= this.state.totalPages) {
                                        this.handlePageChange(this.state.currentPage + 1);
                                      }
                                    }}
                                    className={s.paginationNext}
                                  >
                                    {'>'}
                                  </button>
                                  <button
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.handlePageChange(this.state.totalPages);
                                    }}
                                    className={s.paginationNext}
                                  >
                                    {'>>'}
                                  </button>
                                </div>

                                <div>
                                  <Dropdown
                                    isOpen={this.state.paginationModalOpen}
                                    toggle={() =>
                                      this.setState({
                                        paginationModalOpen: !this.state.paginationModalOpen
                                      })
                                    }
                                    className={styles.pageSizeDropdown}
                                  >
                                    <DropdownToggle className='btn btn-secondary bg-transparent text-white d-flex'>
                                      <p style={{ marginBottom: '3px', marginRight: '2px' }}>
                                        {this.state.itemsPerPage}
                                      </p>
                                      <img
                                        src={caretDown}
                                        alt={'uncollapsed'}
                                        width='20px'
                                        height='20px'
                                        className='ms-2'
                                      />
                                    </DropdownToggle>
                                    <DropdownMenu positionFixed={true}>
                                      {[5, 10, 25].map((item) => (
                                        <DropdownItem
                                          className={styles.dropdownItem}
                                          key={item}
                                          onClick={() => this.handlePerPageChange(item)}
                                        >
                                          {item}
                                        </DropdownItem>
                                      ))}
                                    </DropdownMenu>
                                  </Dropdown>
                                </div>
                              </div>
                            )}
                            <li className='next'>
                              <Button
                                hidden={currentStep === 5}
                                color='primary'
                                onClick={form.handleSubmit}
                                disabled={saveLoading}
                              >
                                {currentStep === 4 && (
                                  <span>
                                    Run <i className='fa fa-check' />
                                  </span>
                                )}
                                {currentStep < 4 && (
                                  <span>
                                    Next <i className='fa fa-caret-right' />
                                  </span>
                                )}
                              </Button>
                            </li>
                            {this.state.currentStep === 5 && (
                              <div className={s.confirmationButtons}>
                                <button
                                  type='button'
                                  hidden={currentStep !== 5}
                                  className='btn d-flex align-items-center'
                                  onClick={() => this.handleDiscard(form)}
                                  disabled={!this.props.legalHoldStats?.isResultValid}
                                >
                                  Discard
                                </button>
                                <button
                                  hidden={currentStep !== 5}
                                  className='btn d-flex align-items-center'
                                  onClick={form.handleSubmit}
                                  disabled={!this.props.legalHoldStats?.isResultValid}
                                  type='button'
                                >
                                  Confirm
                                </button>
                              </div>
                            )}
                          </ul>
                        </div>
                      </form>
                    );
                  }}
                />
              </div>
            </div>
            <Modal
              size='lg'
              isOpen={this.state.isExternal}
              className={`themeStyle${this.props.currentTheme?.replace('#', '')}`}
            >
              <ModalHeader toggle={() => this.toggleExternalModal()}>Upload CSV</ModalHeader>
              <ModalBody>
                <Dropzone
                  onDrop={(acceptedFiles) => {
                    const file = acceptedFiles[0];
                    const reader = new FileReader();
                    reader.onload = (e) => {
                      const text = e.target.result;
                      const res = text.match(
                        /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/g
                      );
                      if (!this.state.fileName.length && res) {
                        toast.success('CSV verified and uploaded');
                      } else if (!res) {
                        toast.error("Please check your email's format");
                        return;
                      } else {
                        toast.error("Can't attach more than 1 CSV file");
                        return;
                      }
                      this.setState({
                        isDisabled: false,
                        importedExternal: res ? res : [],
                        fileName: [...this.state.fileName, file.name]
                      });
                    };

                    reader.readAsText(file);
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section className={s.dropzone}>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p className={`text-center ${this.state.fileName.length ? '' : 'mb-0'}`}>
                          Drag 'n' drop some file here, or click to select file
                        </p>
                        {this.state.fileName.map((item, index) => (
                          <p key={index} className={'text-center mb-0'}>
                            {item}
                          </p>
                        ))}
                      </div>
                    </section>
                  )}
                </Dropzone>
                <div className={'mt-4 d-flex justify-content-end'}>
                  <button
                    className='btn btn-warning fw-bold me-2'
                    type='button'
                    onClick={() => {
                      const downloadUrl = `${config.externalApi}/legal/api/LegalHold/ExampleImportCSV`;
                      this.props.dispatch(formActions.handleDownloadExample(downloadUrl));
                    }}
                  >
                    Download Example CSV
                  </button>
                  <button
                    className={`btn fw-bold ${this.state.isDisabled ? 'disabled' : ''}`}
                    disabled={this.state.isDisabled}
                    type='button'
                    onClick={() => {
                      this.setState({
                        isExternal: false,
                        fileName: [],
                        externalCustodiansList: this.state.externalCustodiansList.concat(
                          this.state.importedExternal
                        ),
                        importedExternal: []
                      });
                    }}
                  >
                    Save
                  </button>
                </div>
              </ModalBody>
            </Modal>
          </Col>
        </Row>
      </div>
    );
  }
  render() {
    const { isEditing, findLoading, record, isNotConfirmed } = this.props;

    if (findLoading) {
      return <Loader />;
    }

    if ((isEditing || isNotConfirmed) && !record) {
      return <Loader />;
    }

    return this.renderForm();
  }
}

export default LegalHoldForm;
