import React, { Component } from 'react';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Widget from 'components/Widget/Widget';
import { connect } from 'react-redux';
import barsIcon from '../../../../images/bars.svg';
import {
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from 'reactstrap';
import LegalCaseViewerFormPage from '../form/LegalCaseViewerFormPage';
import viewerActions from '../../../../actions/viewers/LegalCaseViewerFormActions';
import Pagination from 'react-js-pagination';
import styles from 'components/CRUD/LegalHold/list/LegalHoldList.module.scss';
import caseListActions from 'actions/LegalCase/LegalCaseListActions';
import config from 'config';
import searchIcon from 'images/icons/search.svg';
import { emptyDataMessage } from 'actions/common';
import caretDown from 'images/icons/caret-down.svg';

class AssignedUsersListTable extends Component {
  constructor(props) {
    super(props);
  }
  intervalID = 0;
  state = {
    modalOpen: false,
    paginationModalOpen: false,
    rows: [],
    totalCount: 0,
    searchString: '',
    pageNumber: 1,
    pageSize: 5,
    sortOrder: ''
  };

  refreshTable = () => {
    const { dispatch } = this.props;
    const interval = this.props?.refreshInterval.concat('000');
    const refreshInterval = JSON.parse(interval);
    this.intervalID = setInterval(() => {
      dispatch(
        caseListActions.doFetchCaseUsers({
          legalCaseId: this.props.legalCaseId,
          sortOrder: this.state.sortOrder,
          searchString: this.state.searchString,
          pageNumber: this.state.pageNumber,
          pageSize: this.state.pageSize
        })
      ).then(() => {
        this.setState({
          rows: this.props.caseUsers,
          totalCount: this.props.caseUsersCount
        });
      });
    }, refreshInterval);
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(
      caseListActions.doFetchCaseUsers({
        legalCaseId: this.props.legalCaseId,
        sortOrder: this.state.sortOrder,
        searchString: this.state.searchString,
        pageNumber: this.state.pageNumber,
        pageSize: this.state.pageSize
      })
    ).then(() => {
      this.setState({
        rows: this.props.caseUsers,
        totalCount: this.props.caseUsersCount
      });
    });
    if (this.props?.refreshInterval) {
      this.refreshTable();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { dispatch } = this.props;
    if (
      (prevState.searchString !== this.state.searchString && this.state.searchString === '') ||
      prevState.pageSize !== this.state.pageSize ||
      prevState.pageNumber !== this.state.pageNumber ||
      prevState.sortOrder !== this.state.sortOrder
    ) {
      dispatch(
        caseListActions.doFetchCaseUsers({
          legalCaseId: this.props.legalCaseId,
          sortOrder: this.state.sortOrder,
          searchString: this.state.searchString,
          pageNumber: this.state.pageNumber,
          pageSize: this.state.pageSize
        })
      ).then(() => {
        this.setState({
          rows: this.props.caseUsers,
          totalCount: this.props.caseUsersCount
        });
      });
    }
    if (prevProps.caseUsers !== this.props.caseUsers) {
      this.setState({
        rows: this.props.caseUsers,
        totalCount: this.props.caseUsersCount
      });
    }
    if (prevProps.refreshInterval !== this.props.refreshInterval) {
      this.refreshTable();
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  handleSearch = () => {
    const { dispatch } = this.props;
    this.setState({
      pageNumber: 1
    });
    dispatch(
      caseListActions.doFetchCaseUsers({
        legalCaseId: this.props.legalCaseId,
        sortOrder: this.state.sortOrder,
        searchString: this.state.searchString,
        pageNumber: this.state.pageNumber,
        pageSize: this.state.pageSize
      })
    ).then(() => {
      this.setState({
        rows: this.props.caseUsers,
        totalCount: this.props.caseUsersCount
      });
    });
  };

  sortFormatter(sortField) {
    const SortEnum = {
      firstName: 'firstname',
      lastName: 'lastName',
      userName: 'username',
      email: 'email'
    };
    return SortEnum[sortField];
  }

  openViewModal() {
    const { dispatch } = this.props;
    dispatch(viewerActions.doOpenConfirm());
  }

  closeViewModal() {
    const { dispatch } = this.props;
    dispatch(viewerActions.doCloseConfirm());
  }

  handlePageChange = (pageNumber) => {
    this.setState({ pageNumber: pageNumber });
  };

  handleTableChange = (type, { page, sortField, sortOrder }) => {
    if (type === 'sort') {
      this.setState({
        pageNumber: 1,
        sortOrder: this.sortFormatter(sortField).concat('_').concat(sortOrder)
      });
    }
  };

  render() {
    const columns = [
      {
        dataField: 'firstName',
        text: 'First Name',
        sort: true
      },
      {
        dataField: 'lastName',
        text: 'Last Name',
        sort: true
      },
      {
        dataField: 'userName',
        text: 'Username',
        sort: true
      },
      {
        dataField: 'email',
        text: 'Email',
        sort: true
      }
    ];
    return (
      <>
        <Widget style={{ marginTop: '20px' }}>
          <ToolkitProvider
            bootstrap4
            columns={columns}
            data={this.state.rows}
            keyField='email'
            search
          >
            {(props) => (
              <>
                <Row>
                  <Col style={{ marginTop: '0' }}>
                    <div className={'d-flex align-items-center'}>
                      <h3 className={'fw-semi-bold me-4 mb-0'}>Case Users</h3>
                    </div>
                  </Col>
                </Row>
                <Row key={'table-part'} className='mb-4'>
                  {this.props?.caseStatus !== config.caseStatus.Closed && (
                    <Col style={{ marginTop: '8px' }}>
                      <div className={'d-flex align-items-center'}>
                        <button
                          className='btn  fw-semi-bold d-flex align-items-center'
                          type='button'
                          onClick={() => this.openViewModal()}
                        >
                          Change Users
                        </button>
                      </div>
                    </Col>
                  )}

                  <Col
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      marginTop: '5px'
                    }}
                  >
                    <span>
                      <input
                        type='search'
                        placeholder={'Search'}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            this.handleSearch();
                          }
                        }}
                        style={{
                          border: '0.5px solid #0046b1',
                          borderRadius: '3px'
                        }}
                        className={'form-control search-input me-5 w-200'}
                        value={this.state.searchString}
                        onChange={(e) => {
                          this.setState({
                            searchString: e.target.value
                          });
                        }}
                      />
                    </span>
                    <span>
                      <button
                        className='btn search-button-with-icon me-2 ms-10'
                        type='button'
                        onClick={this.handleSearch}
                        style={{ height: '37px', width: '37px' }}
                      >
                        <img
                          title={'search'}
                          alt={'search'}
                          width={14}
                          height={14}
                          src={searchIcon}
                        />
                      </button>
                    </span>
                  </Col>
                </Row>
                <div className='table-container'>
                  <BootstrapTable
                    bordered={false}
                    classes={`table-striped table-hover fs-sm custom-table`}
                    remote={{
                      filter: false,
                      pagination: false,
                      sort: true,
                      cellEdit: false
                    }}
                    onTableChange={this.handleTableChange}
                    noDataIndication={emptyDataMessage}
                    {...props.baseProps}
                  />
                </div>
                <Row key={'pagination'}>
                  <Col style={{ marginTop: '3px' }}>
                    <p>Total: {this.state.totalCount}</p>
                  </Col>
                  <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {this.state.totalCount ? (
                      <Pagination
                        totalItemsCount={this.state.totalCount}
                        onChange={this.handlePageChange}
                        activePage={this.state.pageNumber}
                        itemsCountPerPage={this.state.pageSize}
                        pageRangeDisplayed={5}
                        prevPageText={'<'}
                        nextPageText={'>'}
                        firstPageText={'<<'}
                        lastPageText={'>>'}
                        linkClassFirst={styles.paginationNext}
                        linkClassPrev={styles.paginationNext}
                        linkClassNext={styles.paginationNext}
                        linkClassLast={styles.paginationNext}
                        linkClass={styles.pageLink}
                        activeLinkClass={styles.activeLinkClass}
                      />
                    ) : null}
                    <Dropdown
                      isOpen={this.state.paginationModalOpen}
                      toggle={() =>
                        this.setState({
                          paginationModalOpen: !this.state.paginationModalOpen
                        })
                      }
                      className={styles.pageSizeDropdown}
                    >
                      <DropdownToggle className='bg-transparent text-white d-flex'>
                        <p style={{ marginBottom: '3px', marginRight: '2px' }}>
                          {this.state.pageSize}
                        </p>
                        <img
                          src={caretDown}
                          alt={'uncollapsed'}
                          width='20px'
                          height='20px'
                          className='ms-2'
                        />
                      </DropdownToggle>
                      <DropdownMenu positionFixed={true}>
                        {[5, 10, 25].map((item) => (
                          <DropdownItem
                            className={styles.dropdownItem}
                            key={item}
                            onClick={() => {
                              this.setState({
                                pageSize: item,
                                paginationModalOpen: false,
                                pageNumber: 1
                              });
                            }}
                          >
                            {item}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </Col>
                </Row>

                <Modal
                  size='md'
                  isOpen={this.props.openViewerModal}
                  className={`themeStyle${this.props.currentTheme?.replace('#', '')}`}
                >
                  <ModalHeader toggle={() => this.closeViewModal()} className={'fw-bold'}>
                    Add Viewers
                  </ModalHeader>
                  <ModalBody>
                    <LegalCaseViewerFormPage
                      dispatch={this.props.dispatch}
                      legalCaseId={this.props.legalCaseId}
                    />
                  </ModalBody>
                </Modal>
              </>
            )}
          </ToolkitProvider>
        </Widget>
      </>
    );
  }
}

function mapStateToProps(store) {
  return {
    rows: store.LegalCase.list.closedLegalCases,
    modalOpen: store.LegalCase.list.modalOpen,
    openViewerModal: store.viewers.form.openModal,
    caseUsers: store.LegalCase.list.caseUsers,
    caseUsersCount: store.LegalCase.list.caseUsersCount,
    refreshInterval: store.LegalHold.list.refreshInterval,
    currentTheme: store.layout.currentTheme
  };
}

export default connect(mapStateToProps)(AssignedUsersListTable);
