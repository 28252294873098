const initialData = {
  findLoading: false,
  findLoadingItemContent: false,
  saveLoading: false,
  record: null,
  itemContentRecord: null,
  items: [],
  rows: [],
  count: null,
  addIconToItems: [],
  itemsBulk: [],
  findStatsLoading: false,
  isSaved: false,
  contentSearch: {},
  findItemsLoading: false,
  fuzzynessTypes: {},
  limitReached: false,
  searchNames: [],
  isBuilderOpen: false,
  allTags: [],
  allTagGroups: [],
  openTagCard: false
};

export default (state = initialData, { type, payload }) => {
  if (type === 'SEARCH_FORM_RESET') {
    return {
      ...initialData
    };
  }

  if (type === 'SEARCH_FORM_FIND_STARTED') {
    return {
      ...state,
      record: null,
      findLoading: true
    };
  }

  if (type === 'SEARCH_FORM_ITEM_CONTENT_FIND_STARTED') {
    return {
      ...state,
      itemContentRecord: null,
      findLoadingItemContent: true
    };
  }

  if (type === 'SEARCH_FORM_ITEM_CONTENT_FIND_SUCCESS') {
    return {
      ...state,
      itemContentRecord: payload,
      findLoadingItemContent: false
    };
  }

  if (type === 'SEARCH_FORM_FIND_SUCCESS') {
    return {
      ...state,
      record: payload,
      findLoading: false
    };
  }

  if (type === 'SEARCH_FORM_FIND_ECLUDED_STATS_STARTED') {
    return {
      ...state,
      findStatsLoading: true
    };
  }

  if (type === 'SEARCH_FORM_FIND_ECLUDED_STATS_SUCCESS') {
    return {
      ...state,
      record: payload,
      findStatsLoading: false
    };
  }

  if (type === 'SEARCH_FORM_ITEMS_FIND_STARTED') {
    return {
      ...state,
      findItemsLoading: true
    };
  }

  if (type === 'SEARCH_FORM_ITEMS_FIND_SUCCESS') {
    return {
      ...state,
      rows: payload.rows,
      count: payload.count,
      findLoading: false,
      findItemsLoading: false,
      limitReached: false
    };
  }

  if (type === 'SEARCH_FORM_ITEMS_FIND_LIMIT_REACHED') {
    return {
      ...state,
      limitReached: true,
      rows: state.rows,
      count: state.count,
      findLoading: false,
      findItemsLoading: false
    };
  }

  if (type === 'SEARCH_FORM_ITEMS_BULK_FIND_SUCCESS') {
    return {
      ...state,
      itemsBulk: payload.rows,
      count: payload.count,
      findLoading: false
    };
  }

  if (type === 'SEARCH_FORM_FIND_ERROR') {
    return {
      ...state,
      record: null,
      findLoading: false
    };
  }

  if (type === 'SEARCH_FORM_CREATE_STARTED') {
    return {
      ...state,
      saveLoading: true
    };
  }

  if (type === 'SEARCH_FORM_CREATE_SUCCESS') {
    return {
      ...state,
      saveLoading: false,
      isSaved: payload.isSaved
    };
  }

  if (type === 'SEARCH_FORM_CREATE_ERROR') {
    return {
      ...state,
      saveLoading: false
    };
  }

  if (type === 'SEARCH_FORM_RESET_ICONS') {
    return {
      ...state,
      addIconToItems: []
    };
  }

  if (type === 'SEARCH_FORM_ADD_ICON') {
    const items = state.addIconToItems;
    if (Array.isArray(payload.itemIds)) {
      for (let aItem of payload.itemIds) {
        if (aItem.isExcluded && payload.status !== true) {
          items.push(aItem.id);
        } else if (!aItem.isExcluded && payload.status === false) {
          items.push(aItem.id);
        } else if (payload.status === true) {
          const item = items.findIndex((item) => item === aItem.id);
          items.splice(item, 1);
        }
      }
    } else {
      if (items.includes(payload.itemIds)) {
        const item = items.findIndex((item) => item === payload.itemIds);
        items.splice(item, 1);
      } else {
        items.push(payload.itemIds);
      }
    }
    return {
      ...state,
      addIconToItems: [...items]
    };
  }
  if (type === 'SEARCH_FETCH_SUCCESS') {
    return {
      ...state,
      contentSearch: payload.contentSearch,
      isSaved: payload.isSaved
    };
  }
  if (type === 'FUZZYNESS_TYPES_FETCH_SUCCESS') {
    return {
      ...state,
      fuzzynessTypes: payload.fuzzynessTypes
    };
  }
  if (type === 'FUZZYNESS_TYPES_FETCH_ERROR') {
    return {
      ...state,
      fuzzynessTypes: {}
    };
  }
  if (type === 'SEARCH_FORM_FIND_SEARCH_NAMES_SUCCESS') {
    return {
      ...state,
      searchNames: payload
    };
  }
  if (type === 'SEARCH_FORM_FIND_SEARCH_NAMES_ERROR') {
    return {
      ...state,
      searchNames: []
    };
  }
  if (type === 'TOGGLE_QUERY_BUILDER') {
    return {
      ...state,
      isBuilderOpen: payload
    };
  }

  if (type === 'TAG_TYPES_FETCH_SUCCESS') {
    return {
      ...state,
      allTags: payload.allTags
    };
  }
  if (type === 'TAG_TYPES_FETCH_ERROR') {
    return {
      ...state,
      allTags: []
    };
  }
  if (type === 'TAG_GROUPS_FETCH_SUCCESS') {
    return {
      ...state,
      allTagGroups: payload.allTagGroups
    };
  }
  if (type === 'TAG_GROUPS_FETCH_ERROR') {
    return {
      ...state,
      allTagGroups: []
    };
  }
  if (type === 'SEARCH_TAG_CARD_OPEN_CONFIRM') {
    return {
      ...state,
      openTagCard: true
    };
  }
  if (type === 'SEARCH_TAG_CARD_CLOSE_CONFIRM') {
    return {
      ...state,
      openTagCard: false
    };
  }

  return state;
};
