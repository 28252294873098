import React, { Component } from 'react';
import makeAnimated from 'react-select/animated';
import MySelect from './MySelect.js';
import { components } from 'react-select';
import { Field } from 'formik';
import FormErrors from '../formErrors.js';

const Option = (props) => {
  return (
    <>
      <components.Option {...props}>
        <div>
          <div
            style={{
              display: 'flex',
              maxHeight: 25,
              overflowY: 'hidden',
              marginLeft: '10px'
            }}
          >
            <label>{props.label.split(' * ')[0]}</label>
          </div>
          <div
            style={{
              fontStyle: 'italic',
              wordBreak: 'break-all',
              fontSize: '13px',
              marginLeft: '10px',
              marginBottom: '5px'
            }}
          >
            {props.label.split(' * ')[1]}
          </div>
        </div>
      </components.Option>
    </>
  );
};

const MultiValue = (props) => (
  <components.MultiValue {...props}>
    <span>{props.data.label}</span>
  </components.MultiValue>
);

const animatedComponents = makeAnimated();

class CustomSelect extends Component {
  handleSelect = (values) => {
    const { form, name } = this.props;
    if (!values) {
      form.setFieldValue(name, []);
      return;
    }
    form.setFieldValue(
      name,
      values.map((value) => value.value)
    );
  };

  handleChange = (selected) => {
    const { form, name } = this.props;
    form.setFieldTouched(name);
    return this.handleSelect(selected);
  };

  value = () => {
    const { form, name, dataOptions } = this.props;
    let options = dataOptions;
    if (name === 'legalHoldIds' && form.values[name]) {
      let holdsArr = [];
      form.values[name].map((item) => {
        const container = options.find((option) => option.value === item);
        holdsArr.push(container);
      });
      return holdsArr;
    }
    if (name === 'contentSearchIds' && form.values[name]) {
      let searchesArr = [];
      form.values[name].map((item) => {
        const container = options.find((option) => option.value === item);
        searchesArr.push(container);
      });
      return searchesArr;
    }
  };

  render() {
    const { errorMessage, hint } = this.props;

    const isInvalid = !!FormErrors.displayableError(this.props.form, this.props.name, errorMessage);

    const controlStyles = isInvalid
      ? {
          control: (provided) => ({
            ...provided,
            borderColor: 'red'
          }),
          option: (provided, state) => ({
            backgroundColor: state.isFocused ? '#474D84' : '',
            paddingLeft: '5px',
            paddingTop: '5px'
          }),
          menuList: (provided) => ({
            ...provided,
            backgroundColor: '#040620'
          }),
          input: (provided) => ({
            ...provided,
            color: 'white'
          })
        }
      : {
          control: (provided) => ({
            ...provided,
            backgroundColor: '#040620',
            border: 'none',
            paddingLeft: '5px',
            overflowX: 'auto',
            maxHeight: 150
          }),
          option: (provided, state) => ({
            backgroundColor: state.isFocused ? '#474D84' : '',
            paddingLeft: '5px',
            paddingTop: '5px'
          }),
          menuList: (provided) => ({
            ...provided,
            backgroundColor: '#040620'
          }),
          input: (provided) => ({
            ...provided,
            color: 'white'
          })
        };
    return (
      <>
        <MySelect
          options={this.props.dataOptions}
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={true}
          components={{ Option, MultiValue, animatedComponents }}
          onChange={this.handleChange}
          allowSelectAll={this.props.isEmpty ? false : true}
          value={this.value()}
          placeholder={this.props.placeholder}
          form={this.props.form}
          name={this.props.name}
          styles={controlStyles}
          isDisabled={this.props.disabled}
        />
        <div className='invalid-feedback'>
          {FormErrors.displayableError(this.props.form, this.props.name, errorMessage)}
        </div>
        {!!hint && <small className='form-text text-muted'>{hint}</small>}
      </>
    );
  }
}

export default class CustomSelectFormItem extends Component {
  render() {
    return (
      <Field
        name={this.props.name}
        validate={this.props.validate}
        render={({ form }) => <CustomSelect {...this.props} form={form} />}
      />
    );
  }
}
