import React from "react";
import s from "../Tooltip/Tooltip.module.scss";

export default function UserProfileCard({ currentUser, position}) {
    const cardStyle={
        position: "absolute",
        zIndex: 1000,
        padding: 16,
        top: position.top + 20,
        left: position.left -180,
        display: position.top ? "block" : "none",
        borderRadius:'20px'
        
    }

    const userDataStyle={
     fontWeight:"bold", 
     marginLeft:'5px'
    }

  return (
    <div className={s.tooltip} style={cardStyle}>
      <p>
        <span>Username: </span> <span style={userDataStyle}>{currentUser?.userName}</span>
      </p>
      <p>
        <span>Name:</span> <span style={userDataStyle}>{currentUser?.name}</span>
      </p>
      <p>
        <span>Surname:</span> <span style={userDataStyle}> {currentUser?.surName}</span>
      </p>
      <p>
        <span>Email address:</span> <span style={userDataStyle}>{currentUser?.email}</span>
      </p>
      <p>
        <span>Phone number:</span> <span style={userDataStyle}> {currentUser?.phoneNumber}</span>
      </p>
    </div>
  );
}
