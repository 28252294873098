import React, { Component } from 'react';
import ExportViewDetails from './ExportViewDetails';
import actions from 'actions/Export/ExportListActions';
import { connect } from 'react-redux';

class ExportDetailsPage extends Component {
  componentDidMount() {
    const { dispatch, match } = this.props;
    const { name: firstName, surName: lastName, email, userName } = this.props?.currentUser || {};
    const expireonUser = {
      firstName,
      lastName,
      email,
      userName
    };
    window.scrollTo(0, 0);

    const exportJobId = match.params?.id;
    dispatch(actions.doFetchExportJob(exportJobId, expireonUser));
  }
  render() {
    return (
      <div>
        <ExportViewDetails history={this.props.history} exportJob={this.props.exportJob} />
      </div>
    );
  }
}
function mapStateToProps(store) {
  return {
    exportJob: store.Export.list.exportJob,
    currentUser: store.auth.currentUser
  };
}
export default connect(mapStateToProps)(ExportDetailsPage);
