import React, { Component } from 'react';
import LegalCaseViewerForm from './LegalCaseViewerForm';
import actions from 'actions/viewers/LegalCaseViewerFormActions';

export default class LegalCaseViewerFormPage extends Component {
  doSubmit = (data) => {
    const { dispatch } = this.props;
    dispatch(actions.doCloseConfirm());
    dispatch(actions.doAddNewViewer(data?.expireonUsers, this.props.legalCaseId));
  };

  render() {
    return (
      <>
        <LegalCaseViewerForm onSubmit={this.doSubmit} legalCaseId={this.props.legalCaseId} />
      </>
    );
  }
}
