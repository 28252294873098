import { UserManager, WebStorageStateStore } from 'oidc-client';
import { storeUserError, storeUser } from '../actions/auth';
import jwt from 'jsonwebtoken';
import { decryptValue, secretKey, applianceId, decodedKey, decryptedObject } from '../config.js';
if (!window[decodedKey]) {
  window[decodedKey] = {};
}
const config = {
  authority: decryptValue(window[decodedKey][decryptedObject?.REACT_APP_CLOUDFICIENT_IDSURL]),
  client_id: 'Expireon_LegalFE',
  redirect_uri: `${decryptValue(
    window[decodedKey][decryptedObject?.REACT_APP_API_URL]
  )}/legal/api/Login/validate-login?source=${decryptValue(
    window[decodedKey][decryptedObject?.REACT_APP_LEGALFE_URL]
  )}`,
  response_type: 'code',
  scope: 'openid Expireon offline_access',
  post_logout_redirect_uri: decryptValue(window[decodedKey][decryptedObject?.REACT_APP_LOGOUT_URL]),
  response_mode: 'form_post',
  accessTokenExpiringNotificationTime: 180
};

const userManager = new UserManager({
  ...config,
  userStore: new WebStorageStateStore({ store: window.localStorage })
});

export async function loadUserFromStorage(store) {
  try {
    let user = await userManager.getUser();
    if (!user) {
      return store.dispatch(storeUserError());
    }
    store.dispatch(storeUser(user));
  } catch (e) {
    console.error(`User not found: ${e}`);
    store.dispatch(storeUserError());
  }
}

const refreshAuthToken = () => {
  try {
    const decoded = Buffer.from(secretKey, 'base64');
    const payload = { ApplianceIdentifier: applianceId };
    const token = jwt.sign(payload, decoded, { expiresIn: '1h' });
    defaultOptions.headers.Authorization = 'Bearer ' + token;
    return token;
  } catch (error) {
    console.error('Error refreshing token:', error);
    throw error;
  }
};

export { refreshAuthToken, defaultOptions };

const defaultOptions = {
  headers: {
    'Content-Type': 'application/json'
  }
};

export function signinRedirect() {
  return userManager.signinRedirect();
}

export function signinRedirectCallback() {
  return userManager.signinRedirectCallback();
}

export function signoutRedirect() {
  userManager.clearStaleState();
  userManager.removeUser();
  return userManager.signoutRedirect();
}

export function signoutRedirectCallback() {
  userManager.clearStaleState();
  userManager.removeUser();
  return userManager.signoutRedirectCallback();
}
export async function refreshAccessToken() {
  userManager
    .signinSilent()
    .then(() => console.log('Silent token renewal successful'))
    .catch((error) => console.error('Silent token renewal error', error));
}

export default userManager;
