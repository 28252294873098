import axios from 'axios';
import config from '../config';
import { toast } from 'react-toastify';
import { push } from 'connected-react-router';
import Errors from 'components/FormItems/error/errors';
import { setAuthHeader } from '../utils/axiosHeaders';
import { getConfigFromApi } from 'services/apiService';
import { store } from 'index';
import { defaultOptions } from '../services/userService';
export const AUTH_FAILURE = 'AUTH_FAILURE';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const RESET_REQUEST = 'RESET_REQUEST';
export const RESET_SUCCESS = 'RESET_SUCCESS';
export const PASSWORD_RESET_EMAIL_REQUEST = 'PASSWORD_RESET_EMAIL_REQUEST';
export const PASSWORD_RESET_EMAIL_SUCCESS = 'PASSWORD_RESET_EMAIL_SUCCESS';
export const AUTH_INIT_SUCCESS = 'AUTH_INIT_SUCCESS';
export const AUTH_INIT_ERROR = 'AUTH_INIT_ERROR';
export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const STORE_USER = 'STORE_USER';
export const STORE_USER_ERROR = 'STORE_USER_ERROR';

export function storeUser(user) {
  setAuthHeader(user.access_token);
  return {
    type: STORE_USER,
    payload: { user }
  };
}
export function getCurrentUser(currentUser, permissions) {
  return {
    type: AUTH_INIT_SUCCESS,
    payload: { currentUser, permissions }
  };
}

export function storeUserError() {
  return {
    type: STORE_USER_ERROR
  };
}

export function authError(payload) {
  return {
    type: AUTH_FAILURE,
    payload
  };
}
export async function loginSuccessfull() {
  const currentUser = store.getState().auth.currentUser;
  axios({
    url: `${config.externalApi}/legal/api/Login/LoginSuccessfull`,
    method: 'POST',
    headers: defaultOptions.headers,
    data: {
      firstName: currentUser?.name,
      lastName: currentUser?.surName,
      email: currentUser?.email,
      userName: currentUser?.userName
    }
  });
}
export async function logoutSuccessfull() {
  const currentUser = store.getState().auth.currentUser;
  axios({
    url: `${config.externalApi}/legal/api/Login/LogoutSuccessfull`,
    method: 'POST',
    headers: defaultOptions.headers,
    data: {
      firstName: currentUser?.name,
      lastName: currentUser?.surName,
      email: currentUser?.email,
      userName: currentUser?.userName
    }
  });
}
export function doInit() {
  return async (dispatch) => {
    try {
      let currentUser = null;
      let permissions = null;
      let dataFromStorage = JSON.parse(
        sessionStorage.getItem(
          'oidc.user:https://dev1.identity-expireon.staging.eu.cloudficient.cloud:Expireon_LegalFE'
        )
      );
      const token = dataFromStorage?.access_token;
      if (token) {
        let configData = await getConfigFromApi();
        currentUser = configData.currentUser;
        permissions = configData.auth.grantedPolicies;
      }
      dispatch({
        type: AUTH_INIT_SUCCESS,
        payload: {
          currentUser,
          permissions
        }
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: AUTH_INIT_ERROR,
        payload: error
      });
    }
  };
}

// export function logoutUser() {
//     return (dispatch) => {
//         window.location.replace(`${config.loginUrl}/Account/LoggedOut?ReturnUrl=%2Fconnect%2Fauthorize%2Fcallback%3Fresponse_type%3Dcode%26client_id%3DExpireon_App%26state%3DRFRjUHBCLWhXOElPZ2xmSHVXVkt3fjdsN3NMTFpCOHZsdDZxWTR0ZFV6eH5I%26redirect_uri%3D${config.UiUrl}%26scope%3Dopenid%2520offline_access%2520Expireon`)
//         dispatch({
//             type: LOGOUT_REQUEST,
//         });
//         localStorage.removeItem('token');
//         localStorage.removeItem('user');
//         axios.defaults.headers.common['Authorization'] = "";
//         dispatch({
//             type: LOGOUT_SUCCESS,
//         });
//         dispatch(push('/login'));
//     };
// }

//export function receiveToken(token) {
//return (dispatch) => {
// if (!process.env.REACT_APP_BACKEND) {
//   localStorage.setItem('token', 'token');
//   dispatch({
//     type: LOGIN_SUCCESS
//   });
//   return;
// }

//let user = jwt.decode(token);

//('token', token);
//localStorage.setItem('user', JSON.stringify(user));
//axios.defaults.headers.common['Authorization'] = "Bearer " + token;
//dispatch({
//    type: LOGIN_SUCCESS
//});
//dispatch(push('/admin/LegalCase'));
//window.location.search = '';
//}
//}

// export function loginUser(creds) {
//     return (dispatch) => {
//         dispatch({
//             type: LOGIN_REQUEST,
//         });
//         if (creds.social) {
//             window.location.href = config.baseURLApi + "/auth/signin/" + creds.social;
//         } else if (creds.email.length > 0 && creds.password.length > 0) {
//             // if (!process.env.REACT_APP_BACKEND) {
//             //   dispatch(receiveToken('token'));
//             //   dispatch(push('/admin/LegalCase'));
//             // } else {
//             axios.post("/auth/signin/local", creds).then(res => {
//                 const token = res.data;
//                 dispatch(receiveToken(token));
//                 dispatch(doInit());
//                 dispatch(push('/admin/LegalCase'));
//             }).catch(err => {
//                 dispatch(authError(err.response.data));
//             })
//             // }
//         } else {
//             dispatch(authError('Something was wrong. Try again'));
//         }
//     };
// }

export function verifyEmail(token) {
  return (dispatch) => {
    axios
      .put('/auth/verify-email', { token })
      .then((verified) => {
        if (verified) {
          toast.success('Your email was verified');
        }
      })
      .catch((err) => {
        toast.error(err.response.data);
      })
      .finally(() => {
        dispatch(push('/login'));
      });
  };
}

export function resetPassword(token, password) {
  return (dispatch) => {
    dispatch({
      type: RESET_REQUEST
    });
    axios
      .put('/auth/password-reset', { token, password })
      .then((res) => {
        dispatch({
          type: RESET_SUCCESS
        });
        toast.success('Password has been updated');
        dispatch(push('/login'));
      })
      .catch((err) => {
        dispatch(authError(err.response.data));
      });
  };
}

export function sendPasswordResetEmail(email) {
  return (dispatch) => {
    dispatch({
      type: PASSWORD_RESET_EMAIL_REQUEST
    });
    axios
      .post('/auth/send-password-reset-email', { email })
      .then((res) => {
        dispatch({
          type: PASSWORD_RESET_EMAIL_SUCCESS
        });
        toast.success('Email with resetting instructions has been sent');
        dispatch(push('/login'));
      })
      .catch((err) => {
        dispatch(authError(err.response.data));
      });
  };
}

export function registerUser(creds) {
  return (dispatch) => {
    dispatch({
      type: REGISTER_REQUEST
    });

    if (creds.email.length > 0 && creds.password.length > 0) {
      axios
        .post('/auth/signup', creds)
        .then((res) => {
          dispatch({
            type: REGISTER_SUCCESS
          });
          toast.success(
            "You've been registered successfully. Please check your email for verification link"
          );
          dispatch(push('/login'));
        })
        .catch((err) => {
          dispatch(authError(err.response.data));
        });
    } else {
      dispatch(authError('Something was wrong. Try again'));
    }
  };
}

export function getWelcomeMessage() {
  return axios.get(`${config.externalApi}/api/Widget/GetWelcomeMessage`, {
    ...defaultOptions
  });
}
