import React from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import { signoutRedirect } from "../services/userService";
import { connect } from "react-redux";

function PermissionDenied() {
  const bodyStyle = {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  };
  return (
    <>
      <Modal
        size="lg"
        isOpen={true}
        className={`themeStyle${this.props.currentTheme?.replace("#", "")}`}
      >
        <ModalBody className="bg-white" style={{ height: "400px" }}>
          <div style={bodyStyle}>
            <h1>User does not have access!</h1>
            <div style={{ marginTop: "20px" }}>
              <Button
                color="danger"
                size="md"
                onClick={() => {
                  signoutRedirect();
                }}
              >
                Go Back
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}
function mapStateToProps(store) {
  return {
    currentTheme: store.layout.currentTheme,
  };
}
export default connect(mapStateToProps)(PermissionDenied);
